import React, { useState, memo } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Tabs } from '../../../layout/tab-view-tabs'
import { useSelector } from '../../../../ducks/root-reducer'
import { elsewhereToast } from '../../../common/toast/toast'
import { ButtonPill } from '../../../common/buttons/button-pill'
import { PADDING_TO_TABS } from '../../../../constants/ui-constants'
import { InterpretationTabContents } from './interpretation-tab-contents'
import { selectAllInterpretationStyleOptions } from '../../../../ducks/ui/ui'
import {
  FastDream,
  InterpretationRequest_AnalysisResult,
} from '../../../../../../api/_openapi'
import { refreshSubscriptionStatus } from '../../../../ducks/subscription/thunks/subscription-thunks'
import {
  FormFieldOption,
  InterpretationBasic,
} from '../../../../../../api/frontend-types'
import {
  selectSubscriptionStatus,
  selectSubscriptionStatusLoading,
  setSubscriptionStatusLoading,
} from '../../../../ducks/subscription/subscription'
import {
  analysisResultToInterpretationBasic,
  generateDreamInterpretationPrivate,
  fetchDreamInterpretations,
} from '../../../../ducks/dream-tag/functions/dream-functions'
import {
  ActionSheetMenu,
  ActionSheetMenuItem,
} from '../../../common/action-sheet/action-sheet-menu'
import { SubscriptionStatusAlert } from '../../subscriptions/subscription-status-alert'
import { PleaseSubscribeDialog } from '../../subscriptions/please-subscribe-dialog'
import { RandomDreamQuote } from '../../random-quote/random-quote'
import { useFocusEffect } from '@react-navigation/native'
import { useIsFocused } from '@react-navigation/native'
import { Row } from '../../../common/row/row'

// When the user has one of these numbers of interpretations remaining,
// we'll show a toast to let them know they're running low
const WARNING_INTERVALS: number[] = [5]

type InterpretationTabProps = {
  dream: FastDream
  isPublicUser: boolean
}

export const InterpretationTab = memo(
  ({ dream, isPublicUser }: InterpretationTabProps) => {
    // STATE
    // For some reason we need to keep track of the number of interpretations remaining
    // locally, because Redux causes a huge rerender when we update the subscription status
    const [interps, setInterps] = useState<InterpretationBasic[]>([])
    const [interpsLoading, setInterpsLoading] = useState<boolean>(true)
    const [optionsMenuIsOpen, setOptionsMenuIsOpen] = useState<boolean>(false)
    const [warningDialogIsOpen, setWarningDialogIsOpen] =
      useState<boolean>(false)

    // HOOKS
    const { t } = useTranslation()
    const dispatch = useDispatch<any>()
    const isFocused = useIsFocused()

    // SELECTORS
    const subscriptionStatus = useSelector(selectSubscriptionStatus)
    const interpStyles = useSelector(selectAllInterpretationStyleOptions(false))
    const subscriptionStatusLoading = useSelector(
      selectSubscriptionStatusLoading,
    )

    // VARS
    const interpsRemaining = subscriptionStatus?.interpretationsRemaining || 0
    const creditsRemaining = subscriptionStatus?.creditsRemaining || 0
    const hasCreditsRemaining = Boolean(creditsRemaining)
    const hasInterpsRemaining = Boolean(interpsRemaining)
    const canGenerateInterpretation = hasCreditsRemaining || hasInterpsRemaining
    const noInterpretationsYet = interps.length === 0
    const dreamIsSaved = Boolean(dream.shareId) || isPublicUser
    const hasInterps = interps.length > 0
    const loading = subscriptionStatusLoading || interpsLoading || !dreamIsSaved
    const buttonChildren = hasInterps || loading ? '' : t('common.interpret')
    const shouldShowQuote =
      (!hasInterps && loading) || !canGenerateInterpretation

    const onDeleteInterpretation = (id: string) => {
      setInterps((prevInterps) =>
        prevInterps.filter((prevInterp) => prevInterp.id !== id),
      )
      elsewhereToast.showToast({
        description: t('common.deleted'),
        status: 'success',
      })
    }

    // EFFECTS
    // Fetch interpretations on load / when dream is saved
    useFocusEffect(
      React.useCallback(() => {
        if ((dream && noInterpretationsYet && dreamIsSaved) || isFocused) {
          fetchDreamInterpretations(dream.id, isPublicUser)
            .then((res: InterpretationBasic[]) => {
              setInterps(res)
            })
            .catch((e) => {
              // Don't show a toast, user may be offline
              console.log('Error fetching interpretations', e)
            })
            .finally(() => {
              setInterpsLoading(false)
            })
        }
      }, [dream, dreamIsSaved, isPublicUser, noInterpretationsYet, isFocused]),
    )

    // FUNCTIONS

    // When the user selects an interpretation style from the dropdown
    const onSelectInterpStyle = (style: FormFieldOption) => {
      setInterpsLoading(true)
      setOptionsMenuIsOpen(false)
      dispatch(setSubscriptionStatusLoading(true))

      if (style.locked) {
        // If the user doesn't have access to this style, take them to the subscription page
        setInterpsLoading(false)
        setWarningDialogIsOpen(true)
      } else {
        // Warn the user if they're running low on interpretations
        warnIfNumInterpsLow(interpsRemaining, t)

        // Generate the interpretation
        const interpRequest = { style: style.value }

        generateDreamInterpretationPrivate(dream.id, interpRequest)
          .then((res: InterpretationRequest_AnalysisResult | null) => {
            if (res) {
              // decrementLocalEntitlements()
              const newInterp = analysisResultToInterpretationBasic(res)
              setInterps((prevInterps) => [newInterp, ...prevInterps])
              dispatch(refreshSubscriptionStatus()) // update interpsRemaining
            } else {
              warnInterpFailed(t)
            }
          })
          .catch(() => {
            warnInterpFailed(t)
          })
          .finally(() => {
            setInterpsLoading(false)
            dispatch(setSubscriptionStatusLoading(false))
          })
      }
    }

    // INTERP STYLE OPTIONS
    const interpStyleOptions: ActionSheetMenuItem[] = interpStyles.map(
      (style) => {
        const item: ActionSheetMenuItem = {
          label: t(style.label),
          iconKey: style.isPremium ? 'gem' : undefined,
          locked: style.locked,
          onPress: () => {
            onSelectInterpStyle(style)
          },
          lockedAction: () => {
            setWarningDialogIsOpen(true)
          },
        }
        return item
      },
    )

    return (
      <>
        <Tabs.ScrollView>
          {/* MESSAGE IF NO INTERPS REMAINING */}
          {!hasInterpsRemaining && (
            <SubscriptionStatusAlert
              statusToShow="interpretationsRemaining"
              tier={subscriptionStatus.tier}
              creditsRemaining={creditsRemaining}
              itemsRemaining={interpsRemaining}
              mt={PADDING_TO_TABS}
              mb={0}
            />
          )}
          {/* BUTTON TO INTERPRET / REINTERPRET DREAM */}
          {/* (SHOW IF INTERPS OR CREDITS REMAINING) */}
          {canGenerateInterpretation && (
            <Row justifyContent="center" mt={PADDING_TO_TABS}>
              <ButtonPill
                width={'auto'}
                type={'primary'}
                variant={'outline'}
                buttonSize={'sm'}
                isLoading={loading}
                endIconKey={hasInterps ? 'plus' : 'wand'}
                onPress={() => {
                  setOptionsMenuIsOpen(true)
                }}
              >
                {buttonChildren}
              </ButtonPill>
            </Row>
          )}
          {/* QUOTE FOR LOADING / AESTHETICS */}
          {shouldShowQuote && (
            <Row justifyContent="center" mt={4}>
              <RandomDreamQuote />
            </Row>
          )}
          {/* LIST OF INTERPRETATIONS */}
          <InterpretationTabContents
            interpretations={interps}
            onDeleteInterpretation={onDeleteInterpretation}
          />
        </Tabs.ScrollView>
        {/* INTERPRETATION OPTIONS */}
        {optionsMenuIsOpen && (
          <ActionSheetMenu
            isOpen={optionsMenuIsOpen}
            onClose={() => {
              setOptionsMenuIsOpen(false)
            }}
            menuItems={[
              ...interpStyleOptions,
              {
                label: t('common.cancel'),
                onPress: () => {
                  setOptionsMenuIsOpen(false)
                },
              },
            ]}
          />
        )}
        {warningDialogIsOpen && (
          <PleaseSubscribeDialog
            isOpen={warningDialogIsOpen}
            onClose={() => {
              setWarningDialogIsOpen(false)
              setInterpsLoading(false)
              dispatch(setSubscriptionStatusLoading(false))
            }}
            // When the user goes to the subscription/credits page
            // Then close the dialog & action sheet
            onConfirmAdditionalAction={() => {
              setWarningDialogIsOpen(false)
              setOptionsMenuIsOpen(false)
            }}
          />
        )}
      </>
    )
  },
  shouldUpdate,
)

// Only update if the dream changes
function shouldUpdate(
  prevProps: InterpretationTabProps,
  nextProps: InterpretationTabProps,
) {
  const dreamsSame = prevProps.dream.id === nextProps.dream.id
  const publicUsersSame = prevProps.isPublicUser === nextProps.isPublicUser
  const dreamSharesSame = prevProps.dream.shareId === nextProps.dream.shareId
  return dreamsSame && publicUsersSame && dreamSharesSame
}

// HELPERS

// Warn the user if they're running low on interpretations
// (Show a toast)
function warnIfNumInterpsLow(numInterpsRemaining: number, t: any) {
  const interpsWillBeRemaining = numInterpsRemaining - 1
  const shouldWarn = WARNING_INTERVALS.includes(interpsWillBeRemaining)
  if (shouldWarn) {
    warnNumInterps(interpsWillBeRemaining, t)
  }
}

// TOASTS
function warnNumInterps(numInterpsRemaining: number, t: any) {
  elsewhereToast.showToast({
    description: t('subscriptions.status.interpretationsRemaining', {
      count: numInterpsRemaining,
    }),
    status: 'info',
    duration: 4000,
  })
}

function warnInterpFailed(t: any) {
  elsewhereToast.showToast({
    description: t('common.errorGeneric'),
    status: 'error',
    duration: 4000,
  })
}
