import React from 'react'
import { useColorModeValue } from 'native-base'
import { BLACK, LIGHT_MID_GRAY, WHITE } from '../../../constants/ui-constants'
import { SansText } from '../copy/text-sans'
import { getContentWidth } from '../../../modules/ui-helpers/ui-helpers'
import { Platform } from 'react-native'
import { ElsewhereIconType } from '../../../modules/ui-helpers/icon-map'
import {
  LINE_WIDTH,
  PADDING_HORIZONTAL,
  PADDING_HORIZONTAL_PIXELS,
} from '../../../constants/constants'
import { ElsewhereIcon } from '../elsewhere-icon/elsewhere-icon'
import { Row } from '../row/row'
import { View } from '../view/view'
import { Actionsheet } from './action-sheet'

export type ActionSheetMenuItem = {
  label: string
  onPress: () => void
  closesMenu?: boolean
  iconKey?: ElsewhereIconType
  locked?: boolean
  lockedAction?: () => void
  content?: React.ReactNode
}

// If searchable, a search input will be shown at the top of the menu
// Something like react-select
type ActionSheetMenu = {
  menuItems: ActionSheetMenuItem[]
  isOpen: boolean
  onClose?: () => void
  heading?: string
  content?: React.ReactNode
}

export const ActionSheetMenu = React.memo(
  ({
    menuItems,
    onClose,
    isOpen,
    heading,
    content = null,
  }: ActionSheetMenu) => {
    // HOOKS
    const color = useColorModeValue(BLACK, WHITE)

    // VARS
    const lockedColor = LIGHT_MID_GRAY
    const contentWidth = getContentWidth(Platform.OS)

    const renderMenuItem = (menuItem: ActionSheetMenuItem) => {
      const textColor = menuItem.locked ? lockedColor : color
      const content = menuItem.content || (
        <SansText
          size={'md'}
          color={menuItem.locked ? lockedColor : textColor}
          style={{
            lineHeight: 22, // Somehow this is needed to make the text vertically centered
          }}
        >
          {menuItem.label}
        </SansText>
      )
      return (
        <Row
          justifyContent="space-between"
          alignItems="center"
          style={{
            width: contentWidth - PADDING_HORIZONTAL_PIXELS * 2,
          }}
          minHeight={10}
        >
          {content}
          {menuItem.iconKey && (
            <ElsewhereIcon
              iconKey={menuItem.iconKey}
              size={'xs'}
              ml={2}
              color={menuItem.locked ? lockedColor : color}
            />
          )}
        </Row>
      )
    }

    return (
      <>
        <Actionsheet
          isOpen={isOpen}
          onClose={() => {
            onClose && onClose()
          }}
        >
          <Actionsheet.Content>
            {heading && (
              <Row
                justifyContent={'center'}
                alignItems={'center'}
                p={PADDING_HORIZONTAL}
                borderBottomWidth={LINE_WIDTH}
              >
                <SansText
                  fontFamily={'sans'}
                  fontSize={'sm'}
                  fontWeight={'bold'}
                  color={color}
                >
                  {heading}
                </SansText>
              </Row>
            )}
            <View width={'100%'}>
              {content
                ? content
                : menuItems.map((menuItem: ActionSheetMenuItem) => {
                    return (
                      <Actionsheet.Item
                        key={menuItem.label}
                        onPress={() => {
                          if (menuItem.locked) {
                            menuItem.lockedAction && menuItem.lockedAction()
                          } else {
                            menuItem.onPress()
                          }
                          if (menuItem.closesMenu) {
                            onClose && onClose()
                          }
                        }}
                        padding={2}
                      >
                        {renderMenuItem(menuItem)}
                      </Actionsheet.Item>
                    )
                  })}
            </View>
          </Actionsheet.Content>
        </Actionsheet>
      </>
    )
  },
)
