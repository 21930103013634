import React, { useState } from 'react'
import { FlatList, Platform, Pressable, Keyboard } from 'react-native'
import { useTranslation } from 'react-i18next'
import { RouteProp } from '@react-navigation/native'
import { Loading } from '../../components/layout/loading'
import { NoContentMessage } from '../../components/layout/no-content-message'
import { InputSearch } from '../../components/common/inputs/input-search'
import { Quote } from '../../components/layout/quote'
import { LINE_WIDTH, WEB_MAX_WIDTH } from '../../constants/constants'
import { DreamSearchService, FastDream } from '../../../../api/_openapi'
import {
  MainStackNavigationProp,
  MainStackParamList,
  MainStackScreenProps,
} from '../../navigation/types'
import FeedItemDream from '../../components/composite/feed/feed-item-dream'
import { useSelector } from '../../ducks/root-reducer'
import {
  selectPrivateGroupId,
  selectUserGroups,
  selectUserPrivateGroup,
} from '../../ducks/groups/groups'
import { selectUser } from '../../ducks/user/user'
import { useIsConnected } from '../../hooks/useIsConnected'
import Fuse from 'fuse.js'
import { Row } from '../../components/common/row/row'

export type SearchComponentNavigationProp =
  MainStackNavigationProp<'PrivateSearch'>
export type SearchComponentRouteProp = RouteProp<
  MainStackParamList,
  'PrivateSearch'
>

type SearchComponentProps = MainStackScreenProps<'PrivateSearch'> & {
  dreams: FastDream[]
}

function SearchComponent({ navigation, route, dreams }: SearchComponentProps) {
  // STATE
  const [searchTerm, setSearchTerm] = useState('')
  const [hasSearched, setHasSearched] = useState(false)
  const [searchRes, setSearchRes] = useState<FastDream[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const isConnected = useIsConnected()

  // HOOKS
  const { t } = useTranslation()

  // SELECTORS
  const appUser = useSelector(selectUser)
  const allGroups = useSelector(selectUserGroups)
  const privateGroupId = useSelector(selectPrivateGroupId)
  const privateGroup = useSelector(selectUserPrivateGroup)

  // VARS
  const isWeb = Platform.OS === 'web'
  const hasDreams = dreams.length > 0
  const cheekyMsg = t('searchPage.cheekyMessage')
  const basicNoResMsg = t('searchPage.noResultsMessage', {
    queryParam: searchTerm,
  })
  const noResMsg = hasDreams ? basicNoResMsg : basicNoResMsg + cheekyMsg
  const externalGroups = allGroups.filter(
    (g) => g.type !== 'user' && g.name !== 'Public',
  )
  const hasGroups = externalGroups.length > 0

  const fuseOptions = {
    keys: [
      { name: 'description', weight: 0.75 },
      { name: 'note', weight: 0.5 },
      'title',
      { name: 'tags.name', weight: 0.5 },
    ],
    threshold: 0.1,
    minMatchCharLength: 2,
    ignoreLocation: true,
  }

  const handleSearch = async () => {
    setIsLoading(true)
    setHasSearched(true)
    try {
      const results = await searchAsync(searchTerm)
      setSearchRes(results)
    } catch (error) {
      console.error('Search error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const searchAsync = async (term: string): Promise<FastDream[]> => {
    if (isConnected) {
      // test search and log results
      const esResults = await DreamSearchService.dreamSearch({
        searchText: [term],
      })
      // @ts-ignore
      const esDreamIds = esResults.dreams?.map((dream) => dream._id)

      const searchResults: FastDream[] = []

      // check every esDreamId and add the dream to the searchResults
      esDreamIds?.forEach((id) => {
        const dream = dreams.find((dream) => dream.id === id)
        if (dream) {
          searchResults.push(dream)
        }
      })

      return searchResults
    } else {
      const fuse = new Fuse(dreams, fuseOptions)
      return fuse.search(term).map((result) => result.item)
    }
  }

  const handleSetSearchTerm = (term: string) => {
    setSearchTerm(term)
    setHasSearched(false)
  }

  const shouldShowQuote = searchTerm === '' || !hasSearched
  const shouldShowResults = hasSearched && !isLoading && searchRes.length > 0

  return (
    <Pressable
      style={{
        flex: 1,
      }}
      onPress={Platform.OS === 'web' ? undefined : () => Keyboard.dismiss()}
    >
      <Row
        paddingLeft={4}
        paddingRight={4}
        justifyContent="center"
        mt={6}
        mx="auto"
      >
        <InputSearch
          value={searchTerm}
          onChangeText={handleSetSearchTerm}
          placeholder={t('searchPage.searchPlaceholder')}
          onSubmit={handleSearch}
          onSubmitEditing={handleSearch}
          onClear={() => {
            setSearchTerm('')
            setHasSearched(false)
          }}
          showClearButton={searchTerm !== ''}
          mb={4}
          autoFocus
          wrapperWidth={isWeb ? WEB_MAX_WIDTH - LINE_WIDTH * 2 : undefined}
          maxWidth={WEB_MAX_WIDTH}
          margin="auto"
          width="100%"
        />
      </Row>

      {isLoading && (
        <Row paddingLeft={4} paddingRight={4} justifyContent="center">
          <Loading />
        </Row>
      )}
      {shouldShowQuote && (
        <Quote
          quote={t('searchPage.quote.text')}
          author={t('searchPage.quote.author')}
          source={t('searchPage.quote.source')}
          mt={32}
        />
      )}
      {!isLoading && searchRes.length === 0 && hasSearched && (
        <Row
          paddingLeft={4}
          paddingRight={4}
          marginTop={12}
          justifyContent="center"
        >
          <NoContentMessage message={noResMsg} />
        </Row>
      )}
      {shouldShowResults && (
        <FlatList
          data={searchRes}
          keyExtractor={(item, i) => (item?.id || '').toString() + i}
          contentContainerStyle={{
            marginTop: LINE_WIDTH * 3,
            flex: !hasDreams ? 1 : undefined,
            height: !hasDreams ? '100%' : undefined,
          }}
          renderItem={({ item, index }) =>
            appUser ? (
              <FeedItemDream
                route={route}
                dream={item}
                index={index}
                privateGroupId={privateGroupId}
                currentGroup={privateGroup}
                appUser={appUser}
                activeGroupId={privateGroupId || ''}
              />
            ) : null
          }
          initialNumToRender={5}
          // @ts-ignore
          dataSet={{ scrollbarFlatListAdjust: true }}
        />
      )}
    </Pressable>
  )
}

export default SearchComponent
