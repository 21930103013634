import React from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderLeft } from '../../shared'
import * as Screens from '../../../screens'
import { cap } from '../../../modules/strings/string-helpers'
import DreamEllipsis from '../../../components/composite/feed/dream-ellipsis'
import { MainStackType } from '../../MainStack'
import { Platform } from 'react-native'

export default function (MainStack: MainStackType) {
  const { t } = useTranslation()
  return (
    <>
      <MainStack.Screen
        name="PrivateFeed"
        component={Screens.PrivateFeed}
        options={{
          title: t('common.journal'),
        }}
      />
      <MainStack.Screen
        name="PrivateDreamView"
        component={Screens.DreamView}
        options={{
          title: cap(t('common.dream_plural', { count: 1 })),
          headerLeft: HeaderLeft,
          headerRight: () => <DreamEllipsis />,
        }}
      />
      <MainStack.Screen
        name="PrivateDashboard"
        component={Screens.Dashboard}
        options={{
          title: cap(t('common.dashboard')),
        }}
      />
      <MainStack.Screen
        name="PrivateTagView"
        component={Screens.TagView}
        options={{
          title: t('common.tag_plural', { count: 1 }),
          headerLeft: HeaderLeft,
        }}
      />
      <MainStack.Screen
        name="PrivateInterpretationView"
        component={Screens.InterpretationView}
        options={{
          title: cap(t('common.interpretation_plural', { count: 1 })),
          headerLeft: HeaderLeft,
        }}
      />
      <MainStack.Screen
        name="PrivateSymbolInterpretationView"
        component={Screens.PrivateSymbolInterpretationView}
        options={{
          title: t('interpretationPage.symbol.title'),
          headerLeft: HeaderLeft,
        }}
      />
    </>
  )
}
