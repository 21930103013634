import React, { ReactNode } from 'react'
import { InfoText } from '../common/copy/text-info'
import { useTranslation } from 'react-i18next'
import { Row } from '../common/row/row'

type LoadingLabelProps = {
  title?: string
  icon?: ReactNode
}

export const LoadingLabel = ({ title, icon }: LoadingLabelProps) => {
  const { t } = useTranslation()
  const titleText = title || t('loading.loadingGeneric')

  return (
    <Row
      justifyContent={'center'}
      alignItems={'center'}
      color={'gray.400'}
      mt={4}
    >
      <InfoText mr={2}>{titleText}</InfoText>
      {icon}
    </Row>
  )
}
