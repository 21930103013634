import type { AxiosInstance } from 'axios'
import axios from 'axios'

import type { Attribute, Subscribers, Subscriber, XPlatform } from './types'
import {
  PurchasesOfferings,
  PRORATION_MODE,
  PurchasesEntitlementInfo,
} from 'react-native-purchases'

// const Purchases = new Revenuecat({
//   secretKey: process.env.REACT_APP_REVENUECAT_SECRET_KEY as string,
//   iosKey: process.env.REACT_APP_REVENUECAT_APPLE_API_KEY as string,
//   androidKey: process.env.REACT_APP_REVENUECAT_GOOGLE_API_KEY as string,
// })

export class RevenueCat {
  private readonly secretKey: string = process.env
    .REACT_APP_REVENUECAT_SECRET_KEY as string
  private readonly iosKey: string = process.env
    .REACT_APP_REVENUECAT_APPLE_API_KEY as string
  private readonly androidKey: string = process.env
    .REACT_APP_REVENUECAT_GOOGLE_API_KEY as string
  private readonly url = 'https://api.revenuecat.com/v1'
  private userId: string | null = null
  private readonly platform: XPlatform = 'android'
  private axios: AxiosInstance

  /**
   * Construct a new Revenuecat instance
   * @param token Revenuecat API token
   */
  constructor() {
    this.secretKey = this.secretKey
    this.iosKey = this.iosKey
    this.androidKey = this.androidKey
    this.axios = axios.create({
      baseURL: this.url,
    })
  }

  /**
   * Creates authorization header
   * @returns Authorization header value
   */
  private createAuthorizationHeader(platform?: XPlatform): string {
    switch (platform) {
      case 'ios':
        return `Bearer ${this.iosKey}`
      case 'android':
        return `Bearer ${this.androidKey}`
      default:
        return `Bearer ${this.secretKey}`
    }
  }

  //   Customers

  /**
   * Gets the offerings for your app.
   */
  getSubscriptions = async (): Promise<Subscribers> => {
    let headers = {
      'Content-Type': 'application/json',
      Authorization: this.createAuthorizationHeader(this.platform),
    }
    if (this.platform)
      headers = Object.assign(headers, { 'x-platform': this.platform })

    const { data } = await this.axios.get(`/subscribers/${this.userId}`, {
      headers,
    })
    return data
  }

  postSubscriptionsAttributes = async ({
    data,
  }: {
    userId: string
    data: Attribute
  }) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: this.createAuthorizationHeader(),
      app_user_id: this.userId,
    }

    const { data: res } = await this.axios.post(
      `/subscribers/${this.userId}/attributes`,
      data,
      {
        headers,
      },
    )
    return res
  }

  getOfferings = async (): Promise<PurchasesOfferings> => {
    let headers = {
      'Content-Type': 'application/json',
      Authorization: this.createAuthorizationHeader(this.platform),
      app_user_id: this.userId,
    }
    if (this.platform)
      headers = Object.assign(headers, { 'x-platform': this.platform })

    const { data } = await this.axios.get(
      `/subscribers/${this.userId}/offerings`,
      {
        headers,
      },
    )

    // convert Offerings to PurchaseOfferings object
    const otherData = {
      lifetime: null,
      annual: null,
      sixMonth: null,
      threeMonth: null,
      twoMonth: null,
      monthly: null,
      weekly: null,
      metadata: {},
      serverDescription: '',
    }
    return {
      current: {
        ...otherData,
        identifier: data.current_offering_id,
        availablePackages: data?.offerings?.find(
          (o: any) => o.identifier === data.current_offering_id,
        )?.packages,
      },

      all: {
        premium: {
          ...otherData,
          identifier: 'premium',
          availablePackages: data?.offerings?.find(
            (o: any) => o.identifier === 'premium',
          )?.packages,
        },
        supporter: {
          ...otherData,
          identifier: 'supporter',
          availablePackages: data?.offerings?.find(
            (o: any) => o.identifier === 'supporter',
          )?.packages,
        },
        credits: {
          ...otherData,
          identifier: 'credits',
          availablePackages: data?.offerings?.find(
            (o: any) => o.identifier === 'credits',
          )?.packages,
        },
      },
    }
  }

  getCustomerInfo = async (): Promise<Subscriber> => {
    let headers = {
      'Content-Type': 'application/json',
      Authorization: this.createAuthorizationHeader(this.platform),
      app_user_id: this.userId,
    }
    if (this.platform)
      headers = Object.assign(headers, { 'x-platform': this.platform })

    const { data } = await this.axios.get(`/subscribers/${this.userId}`, {
      headers,
    })
    return data.subscriber as Subscriber
  }

  configure = async ({ appUserID }: { appUserID: string }) => {
    this.userId = appUserID
  }

  invalidateCustomerInfoCache = async () => {
    return
  }
}
const Purchases = new RevenueCat()

type PurchasesPackage = string
export { Purchases, PRORATION_MODE }
export type { PurchasesPackage, PurchasesEntitlementInfo, Subscriber }
