import { ImageForUpload, UploadOptions } from './types'
import {
  MAX_FILESIZE_BYTES,
  MAX_FILES_TO_UPLOAD,
} from '../../../../../constants/constants'
import { elsewhereToast } from '../../../toast/toast'

// @ts-ignore
window.cloudinary.setCloudName('dreamcloud-media')

// Web version of the onUploadButtonPress function
export const onUploadButtonPress = (
  options: UploadOptions | undefined,
  onImageSelectedCallback: (images: ImageForUpload[]) => Promise<void>,
  onDismiss: () => void,
) => {
  const defaultOptions = {
    cloudName: 'dreamcloud-media',
    uploadPreset: 'precrop',
    folder: options?.type || 'image',
    resourceType: 'image',
    clientAllowedFormats: ['jpg', 'jpeg', 'png'],
    showPoweredBy: false,
    // singleUploadAutoClose: false,
    croppingValidateDimensions: true,
    secure: true,
    sources: ['local', 'url', 'camera'],
    showAdvancedOptions: false,
    cropping: false,
    showSkipCropButton: true,
    croppingShowBackButton: true,
    croppingDefaultSelectionRatio: 1,
    defaultSource: 'local',
    maxFileSize: MAX_FILESIZE_BYTES,
    // Allow multiple files to be uploaded
    maxFiles: MAX_FILES_TO_UPLOAD,
    multiple: true,
  }

  //Overwrite defaults with our options
  Object.assign(defaultOptions, options)

  const imagesForUpload: ImageForUpload[] = []

  // Create the widget
  // @ts-ignore
  const widget = window.cloudinary.createUploadWidget(
    defaultOptions,
    (error: any, result: any) => {
      if (!error && result && result.event === 'success') {
        imagesForUpload.push({
          path: result.info.secure_url,
          size: result.info.bytes,
          mime: result.info.format,
          filename: `${result.info.public_id.split('/')[1]}.${
            result.info.format
          }`,
          secure_url: result.info.secure_url,
        })

        return onImageSelectedCallback(imagesForUpload)
      }
      if (error) {
        elsewhereToast.showToast({
          title: 'Error uploading image',
          status: 'error',
        })
        console.error(error)
      }
      if (!error && result && result.event == 'close') {
        onDismiss()
      }
    },
  )

  widget.open()
}
