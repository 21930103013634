import { useEffect } from 'react'
import { loadSubscriptionStatus } from './ducks/subscription/thunks/subscription-thunks'
import { useDispatch } from 'react-redux'
import { useSelector } from './ducks/root-reducer'
import { selectUser } from './ducks/user/user'

export function RevenueCatInit({ children }: any) {
  const dispatch = useDispatch<any>()
  const user = useSelector(selectUser)

  useEffect(() => {
    if (user?.id) {
      dispatch(loadSubscriptionStatus(user))
    }
  }, [user?.id])
  return children
}
