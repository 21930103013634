import { Image, Platform, StyleSheet } from 'react-native'
import { Language } from '../../../api/_openapi'
import { formatDateForDatabase } from '../modules/strings/string-helpers'

// Random collection of constants used throughout the app
// Sometime we could split them up into more specific files

// Magic numbers
export const NUM_MILLISECONDS_IN_MINUTE = 1000 * 60
export const NUM_MILLISECONDS_IN_DAY = NUM_MILLISECONDS_IN_MINUTE * 60 * 24
export const NUM_MILLISECONDS_IN_WEEK = NUM_MILLISECONDS_IN_DAY * 7

export const TEMP_DREAM_ID_PREFIX = 'new-dream-'
export const FALLBACK_IMG = require('../assets/images/fallback-cat.png')
export const FALLBACK_IMG_URI =
  Platform.OS === 'web'
    ? FALLBACK_IMG.source
    : Image.resolveAssetSource(require('../assets/images/fallback-cat.png')).uri
export const FALLBACK_CLOUDINARY_IMG =
  'https://res.cloudinary.com/dreamcloud-media/image/upload/v1721431867/dream-image/r1tq7w1xil3tlag3fwx6.png'

// Feed things
export const FEED_ENTRY_CONDENSED_TITLE_MAX_CHARS_MOBILE_SMALL = 15
export const FEED_ENTRY_CONDENSED_TITLE_MAX_CHARS_MOBILE = 20
export const FEED_ENTRY_CONDENSED_TITLE_MAX_CHARS_WEB = 36
export const FEED_ITEM_MAX_CHARS = 200

// Dream things
export const STYLES_W_CUSTOM_PLACEHOLDERS = ['freudian', 'jungian']

// Important user IDs
export const ELSEWHERE_AI_USER_ID = '9570e35b-71a0-4405-a034-9b273dcc1c5f'
export const ELSEWHERE_AI_USERNAME = 'elsewhere-ai'
export const FONTANA_USERNAME = 'david-fontana'
export const KELLY_USERNAME = 'kelly-bulkeley'

// Famous users
const FREUD_USERNAME = 'sigmundfreud'
const KAFKA_USERNAME = 'franzkafka'
const JUNG_USERNAME = 'carljung'

export const PUBLIC_USERS = [
  ELSEWHERE_AI_USERNAME,
  FONTANA_USERNAME,
  KELLY_USERNAME,
]

export const FAMOUS_USERS = [FREUD_USERNAME, KAFKA_USERNAME, JUNG_USERNAME]

export const PUBLIC_USERS_DISPLAY_NAME_MAP = {
  'elsewhere-ai': 'globalUser.elsewhere-ai.name',
  'david-fontana': 'David Fontana',
  'kelly-bulkeley': 'Kelly Bulkeley',
  sigmundfreud: 'Sigmund Freud',
  franzkafka: 'Franz Kafka',
  carljung: 'Carl Jung',
} as any

// Insight types (dictionary)
export const INSIGHT_TYPES = {
  AI_FONTANA_SYMBOL_IN_DREAM: 'AI_FONTANA_SYMBOL_IN_DREAM',
  AI_TAG_FOR_USER: 'AI_TAG_FOR_USER',
  AI_DREAM_INTERPRETATION: 'AI_DREAM_INTERPRETATION',
  SYMBOL: 'SYMBOL',
  AI_DAILY_TAG_SYNTHESIS: 'AI_DAILY_TAG_SYNTHESIS',
  USER_TAG_ASSOCIATION: 'USER_TAG_ASSOCIATION',
}

// Tag description things
export const DESC_TYPES_TO_SHOW = [
  INSIGHT_TYPES.AI_TAG_FOR_USER,
  INSIGHT_TYPES.AI_DREAM_INTERPRETATION,
]

// Links
export const LINKS = {
  discord: 'https://discord.gg/3aJeSgc6T9',
  instagram: 'https://www.instagram.com/elsewheredreams/',
  x: 'https://x.com/elsewheredreams',
  facebook: 'https://www.facebook.com/elsewheredreams',
  reddit: 'https://www.reddit.com/r/elsewheredreams/',
}

// App store things
export const APP_STORE_LINK_ID = '6445864345'
export const GOOGLE_PLAY_LINK_ID = 'to.elsewhere'

// UI
export const PADDING_HORIZONTAL_PIXELS = 16
export const PADDING_HORIZONTAL = 4
export const LINE_WIDTH =
  Platform.OS === 'web' ? 1.5 : StyleSheet.hairlineWidth * 3
export const LINE_WIDTH_PX =
  Platform.OS === 'web' ? '1.5px' : StyleSheet.hairlineWidth * 3
export const ADJUSTED_MARGIN_TOP =
  Platform.OS === 'web' || Platform.OS === 'android'
    ? -LINE_WIDTH * 2
    : -LINE_WIDTH
export const SELECTION_COLOR = '#00000044'
export const WEB_MAX_WIDTH = 530
export const HOMEPAGE_MAX_CONTENT_WIDTH = 750
export const BOTTOM_TAB_BAR_HEIGHT = 49

// FONT SIZES
export const XXS_FONTSIZE_PIXELS = 11
export const XS_FONTSIZE_PIXELS = 12
export const SM_FONTSIZE_PIXELS = 14
export const MD_FONTSIZE_PIXELS = 16
export const LG_FONTSIZE_PIXELS = 18
export const XL_FONTSIZE_PIXELS = 24
export const XXL_FONTSIZE_PIXELS = 32
export const XXXL_FONTSIZE_PIXELS = 48
export const XXXXL_FONTSIZE_PIXELS = 64
export const XXXXXL_FONTSIZE_PIXELS = 72

// LINE HEIGHTS (PX)
export const XXS_LINE_HEIGHT = 14
export const XS_LINE_HEIGHT = 16
export const SM_LINE_HEIGHT = 18
export const MD_LINE_HEIGHT = 20
export const LG_LINE_HEIGHT = 22
export const XL_LINE_HEIGHT = 32
export const XXL_LINE_HEIGHT = 40
export const XXXL_LINE_HEIGHT = 52
export const XXXXL_LINE_HEIGHT = 68
export const XXXXXL_LINE_HEIGHT = 76

// Fonts
export const SANS_BOOK_FONT = 'ABCFavorit-Book'
export const SANS_BOOK_FONT_ARABIC = 'NotoSansArabic-Regular'
export const BODY_FONT = 'OptimaLTPro-Roman'
export const BODY_FONT_ARABIC = 'NotoNaskhArabic-Regular'

export const EXTRA_SMALL_FONT_SIZE = XS_FONTSIZE_PIXELS
export const SMALL_FONT_SIZE = SM_FONTSIZE_PIXELS
export const MEDIUM_FONT_SIZE = MD_FONTSIZE_PIXELS
export const LARGE_FONT_SIZE = LG_FONTSIZE_PIXELS

export const EXTRA_SMALL_LINE_HEIGHT = XS_LINE_HEIGHT
export const SMALL_LINE_HEIGHT = SM_LINE_HEIGHT
export const MEDIUM_LINE_HEIGHT = MD_LINE_HEIGHT
export const LARGE_LINE_HEIGHT = LG_LINE_HEIGHT

export const SANS_LIGHT_FONT_WEIGHT = 300
export const SANS_BOOK_FONT_WEIGHT = 400
export const SANS_MEDIUM_FONT_WEIGHT = 500
export const SANS_BOOK_FONT_WEIGHT_STR = '400'
export const BODY_FONT_WEIGHT_STR = '400'
export const SANS_BOLD_FONT_WEIGHT = 600
export const HEADER_TITLE_FONT_SIZE = LG_FONTSIZE_PIXELS

// Groups
export const MAX_GROUPS = 10

export const DREAM_TYPES = [
  'dreamType.lucid',
  'dreamType.nightmare',
  'dreamType.recurrent',
  'dreamType.big',
  'dreamType.episodic',
  'dreamType.precognitive',
  'dreamType.fragment',
  'dreamType.first_person',
  'dreamType.third_person',
]

export const FIRST_IMAGE_WARNING = 16 // images remaining
export const LAST_IMAGE_WARNING = 2 // images remaining

// FILE UPLOAD
export const MAX_FILESIZE_BYTES = 13631488 // ~13mb in Windows 1024 * 1024 definition
export const MAX_FILES_TO_UPLOAD = 10
export const MAX_DREAMS_TO_UPLOAD = 500

// DEFAULTS
export const DEFAULT_ART_STYLE_ID =
  '/api/art_styles/0e41e333-c817-40e3-9c0b-19d8b6c1c6dc'
export const DEFAULT_INTERPRETATION_STYLE_ID =
  '/api/interpretation_styles/21c8f30d-2c20-40f4-ad76-638923db033f'

export const DEFAULT_LANGUAGE: Language = {
  code: 'en',
  name: 'English',
  supported: true,
  createdAt: formatDateForDatabase(new Date()),
}
