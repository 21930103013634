import React, { useState, useEffect } from 'react'
import { useColorModeValue, Badge, useDisclose, Radio } from 'native-base'
import { Platform } from 'react-native'
import {
  LARGE_LINE_HEIGHT,
  LG_FONTSIZE_PIXELS,
  LINE_WIDTH,
  MD_LINE_HEIGHT,
  MEDIUM_FONT_SIZE,
  SANS_BOOK_FONT_WEIGHT,
  WEB_MAX_WIDTH,
} from '../../../constants/constants'
import { PaddedContentArea } from '../../layout/content-area-padded'
import { ButtonPill } from '../../common/buttons/button-pill'
import { useTranslation } from 'react-i18next'
import {
  Purchases,
  PurchasesPackage,
  GoogleProductChangeInfo,
  PRORATION_MODE,
  PurchasesEntitlementInfo,
} from '../../../revenuecat'
import { ActionSheetMenu } from '../../common/action-sheet/action-sheet-menu'
import { useSelector } from '../../../ducks/root-reducer'
import { useDispatch } from 'react-redux'
import { AlertPopup } from '../../common/dialog/alert-dialog'
import { SansText } from '../../common/copy/text-sans'
import { selectIsPurchasing, setIsPurchasing } from '../../../ducks/ui/ui'
import {
  extractSubscriptionStatusFromCustomerInfo,
  refreshSubscriptionStatus,
  updateSubscriptionStatus,
} from '../../../ducks/subscription/thunks/subscription-thunks'
import { TabProps } from '../../layout/tab-view-tabs'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import {
  IconCoin,
  IconGem,
} from '../../../assets/react-native-svg/icons/icons-ui'
import { getColorForStatus } from '../../../modules/ui-helpers/ui-helpers'
import { selectUser } from '../../../ducks/user/user'
import { FEW } from '../../../i18n/config'
import { cap } from '../../../modules/strings/string-helpers'
import { Autolink } from 'react-native-autolink'
import { HeadingMain } from '../../common/copy/heading-main'
import { Row } from '../../common/row/row'
import { MarkdownText } from '../../common/copy/markdown-text'
import i18n from '../../../i18n/i18nnext'
import { getBodyFont } from '../../../modules/language-helpers/language-helpers'
import { Column } from '../../common/column/column'
import { Box } from '../../common/box/box'
import { View } from '../../common/view/view'
import { Link } from '../../common/link/link'

enum FeatureOffering {
  free = 'free',
  credits = 'credits',
  premium = 'premium',
  supporter = 'supporter',
}
export type FeatureSlide = TabProps<FeatureOffering> & {
  SvgIcon?: ({ color, size }: { color: string; size: number }) => JSX.Element
  key: FeatureOffering
  title?: string
  features: string
  defaultValue?: string
  offerings: PurchasesPackage[]
  name: FeatureOffering
}

type Offerings = {
  premium?: PurchasesPackage[]
  supporter?: PurchasesPackage[]
  credits?: PurchasesPackage[]
}

export const Feature = React.memo(
  ({
    feature,
    subscriptionTier,
    subscriptionInfo,
    creditsAvailable,
    triggerConfetti,
  }: {
    feature: keyof typeof FeatureOffering
    subscriptionTier?: string
    subscriptionInfo?: PurchasesEntitlementInfo | null
    creditsAvailable?: number
    triggerConfetti: () => void
  }) => {
    // I18N
    const { t } = useTranslation()
    const lang = i18n.resolvedLanguage || 'en'
    const bodyFont = getBodyFont(lang)

    const user = useSelector(selectUser)
    const isPurchasing = useSelector(selectIsPurchasing)
    const [loading, setLoading] = useState<boolean>(true)
    const [offerings, setOfferings] = useState<Offerings>({})

    const featureObject: {
      [key in FeatureOffering]: {
        title: string
        features: string
        defaultValue?: string
        showCTA: boolean
      }
    } = {
      free: {
        title: t('subscriptions.tiers.free'),
        features: t(`subscriptions.benefits.free`),
        defaultValue: undefined,
        showCTA: false,
      },
      premium: {
        title: t('subscriptions.tiers.premium'),
        features: t(`subscriptions.benefits.premium`),
        defaultValue: 'premium_monthly',
        showCTA: subscriptionTier === 'free',
      },
      supporter: {
        title: t('subscriptions.tiers.supporter'),
        features: t(`subscriptions.benefits.supporter`),
        defaultValue: 'supporter_monthly',
        showCTA: subscriptionTier === 'free' || subscriptionTier === 'premium',
      },
      credits: {
        title: cap(t('common.credit_plural', { count: FEW })),
        features: t('settings.credits.description'),
        defaultValue: 'credits_15',
        showCTA: true,
      },
    }
    const { defaultValue, title, features } =
      featureObject[feature as FeatureOffering]

    const [value, setValue] = useState(defaultValue)
    const color = useColorModeValue(BLACK, WHITE)
    const colorScheme = useColorModeValue('light', 'dark')
    const { isOpen, onOpen, onClose } = useDisclose()
    const {
      isOpen: dialogIsOpen,
      onOpen: dialogOnOpen,
      onClose: dialogOnClose,
    } = useDisclose()
    const dispatch = useDispatch<any>()

    const openChoicesOrDialog = () => {
      if (Platform.OS === 'web') {
        dialogOnOpen()
      } else {
        getOfferings()
        onOpen()
      }
    }

    const getIdentifier = (m: PurchasesPackage) => {
      // Android identifiers are built different
      return m?.product?.identifier?.split(':')[0] ?? m?.identifier
    }

    const onSelection = async (
      purchasePackage: PurchasesPackage,
      upgradeFromPackage?: PurchasesPackage,
    ) => {
      onClose()
      try {
        const googleProductChangeInfo: undefined | GoogleProductChangeInfo =
          upgradeFromPackage
            ? ({
                oldProductIdentifier: getIdentifier(upgradeFromPackage),
                prorationMode:
                  PRORATION_MODE.IMMEDIATE_AND_CHARGE_PRORATED_PRICE,
              } as GoogleProductChangeInfo)
            : undefined
        const { customerInfo }: any = await Purchases.purchasePackage(
          purchasePackage,
          null,
          googleProductChangeInfo,
        )

        if (
          typeof customerInfo.entitlements.active[
            purchasePackage.identifier
          ] !== 'undefined'
        ) {
          return
        }
        if (customerInfo && user) {
          // await dispatch(setPurchasedPackage(purchasePackage))
          const newSubscriptionStatus =
            await extractSubscriptionStatusFromCustomerInfo(customerInfo)
          await dispatch(
            updateSubscriptionStatus(user?.id, newSubscriptionStatus),
          )
          dispatch(refreshSubscriptionStatus())
        }
      } catch (e: any) {
        if (!e.userCancelled) {
          console.error('onSelection error', e)
        }
        dispatch(setIsPurchasing(false))
      }
    }

    const getSelectedOption = (
      value: string,
      tier: string,
    ): PurchasesPackage => {
      // @ts-ignore
      return offerings[tier].find((pp: PurchasesPackage) => {
        const identifier = getIdentifier(pp)
        return identifier === value
      })
    }

    const purchaseItem = async (upgrade = false) => {
      await dispatch(setIsPurchasing(true))
      try {
        const selected = getSelectedOption(value as string, feature)
        // local is only used for Android upgrades only offering upgrades from premium to supporter, so local can only apply to premium
        const upgradeFrom =
          Platform.OS === 'android' && upgrade && offerings.premium
            ? getSelectedOption('premium_monthly', 'premium')
            : undefined
        await onSelection(selected, upgradeFrom)
      } catch (e: any) {
        dispatch(setIsPurchasing(false))
        console.log('purchaseItem error', e)
      }
    }

    const getOfferings = async () => {
      try {
        if (Platform.OS === 'web') {
          await Purchases.configure({ appUserID: user?.id, apiKey: '' })
        }
        const offeringsFromRevCat = await Purchases.getOfferings()

        if (
          offeringsFromRevCat.current !== null &&
          offeringsFromRevCat.current.availablePackages.length > 0
        ) {
          ;[feature]
            .filter((o: string) => o !== 'free')
            .forEach((entitlement: string) => {
              setOfferings((prev) => ({
                ...prev,
                [entitlement]:
                  offeringsFromRevCat?.all[entitlement].availablePackages,
              }))
            })
        }
      } catch (e: any) {
        console.error('Error getting offers', e.message)
      } finally {
        setLoading(false)
      }
    }

    useEffect(() => {
      if (isPurchasing) {
        dispatch(setIsPurchasing(false))
        triggerConfetti()
      }
    }, [creditsAvailable, subscriptionTier])

    return (
      <>
        <PaddedContentArea flex={1}>
          <Column
            flex={1}
            m={0}
            p={1}
            alignItems="center"
            justifyContent={'flex-start'}
            maxWidth={WEB_MAX_WIDTH}
          >
            <Box minHeight={150} alignItems={'center'} justifyContent="center">
              <Box
                m={0}
                alignItems="center"
                alignContent={'center'}
                justifyContent="center"
              >
                <HeadingMain m={0} p={0} textAlign={'center'}>
                  {title?.toLocaleUpperCase()}
                </HeadingMain>
              </Box>
              <View
                alignItems="center"
                alignContent="center"
                justifyContent="center"
                paddingBottom={8}
              >
                {feature === 'credits' || feature === subscriptionTier ? (
                  <Row mt={3} space={2}>
                    <Badge
                      alignItems="center"
                      rounded="full"
                      colorScheme="success"
                      variant="outline"
                      borderWidth={LINE_WIDTH}
                      pl={3}
                      pr={3}
                      pt={'10px'}
                      pb={'8px'}
                      leftIcon={
                        feature === 'credits' ? (
                          <IconCoin
                            size={5}
                            color={getColorForStatus('success')}
                          />
                        ) : (
                          <IconGem
                            size={5}
                            color={getColorForStatus('success')}
                            mt={-0.5}
                          />
                        )
                      }
                    >
                      <SansText
                        fontSize="md"
                        color={getColorForStatus('success')}
                        ml={1}
                      >
                        {feature === 'credits'
                          ? t('settings.credits.balance', {
                              balance: creditsAvailable || 0,
                            })
                          : t(`subscriptions.yourCurrentTier`)}
                      </SansText>
                    </Badge>
                  </Row>
                ) : null}
              </View>
            </Box>
            <Box w="100%" m={0}>
              {
                <MarkdownText
                  style={{
                    body: {
                      fontFamily: bodyFont,
                      fontWeight: '400',
                      fontSize: LG_FONTSIZE_PIXELS,
                      lineHeight: LARGE_LINE_HEIGHT,
                      color,
                      marginBottom: 12,
                    },
                    link: {
                      marginBottom:
                        Platform.OS === 'android' ? -6.75 : undefined,
                    },
                  }}
                >
                  {features || ''}
                </MarkdownText>
              }
            </Box>
          </Column>
        </PaddedContentArea>
        <Box w="100%" m={0} maxHeight={120} flex={1}>
          <PaddedContentArea
            flexDirection={'column'}
            justifyContent={'flex-end'}
            w="100%"
            m={0}
          >
            {
              <>
                {featureObject[feature].showCTA && (
                  <ButtonPill
                    type={'primary'}
                    onPress={openChoicesOrDialog}
                    isDisabled={isOpen}
                    isLoading={isPurchasing}
                    mb={2}
                  >
                    {feature === 'credits'
                      ? t('subscriptions.cta.purchase')
                      : subscriptionTier === 'premium' &&
                        feature === 'supporter'
                      ? t('subscriptions.cta.upgrade')
                      : subscriptionTier === 'free' && feature !== 'free'
                      ? t('subscriptions.cta.subscribe')
                      : null}
                  </ButtonPill>
                )}

                <Row justifyContent={'center'} alignItems="center">
                  <Column
                    alignSelf="center"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {Platform.OS !== 'web' &&
                      feature === subscriptionTier &&
                      subscriptionTier !== 'free' &&
                      subscriptionInfo?.store &&
                      ((Platform.OS === 'android' &&
                        subscriptionInfo?.store.toUpperCase() ===
                          'PLAY_STORE') ||
                        (Platform.OS === 'ios' &&
                          subscriptionInfo?.store.toUpperCase() ===
                            'APP_STORE')) && (
                        <Link
                          mt={4}
                          href={
                            Platform.OS === 'android' &&
                            subscriptionInfo?.store &&
                            subscriptionInfo?.store.toUpperCase() ===
                              'PLAY_STORE'
                              ? `https://play.google.com/store/account/subscriptions?package=to.elsewhere`
                              : 'https://apps.apple.com/account/subscriptions'
                          }
                          _text={{
                            fontFamily: 'sans',
                            fontWeight: SANS_BOOK_FONT_WEIGHT,
                            fontSize: MEDIUM_FONT_SIZE,
                            lineHeight: MD_LINE_HEIGHT,
                            color: 'gray.400',
                          }}
                        >
                          {t('subscriptions.cta.manageSubscription')}
                        </Link>
                      )}
                  </Column>
                </Row>
              </>
            }
          </PaddedContentArea>
        </Box>
        <ActionSheetMenu
          isOpen={isOpen}
          onClose={onClose}
          menuItems={[]}
          content={
            <Column px={3}>
              {Platform.OS !== 'web' ? (
                <Column alignItems={'flex-start'}>
                  {Platform.OS === 'ios' && feature !== 'credits' && (
                    <Row>
                      <SansText fontSize={'xs'}>
                        <Autolink
                          text={t(`ios.subscriptions.legal`) as string}
                          matchers={[
                            {
                              pattern: /<terms>(.*)<\/terms>/g,
                              getLinkText: (replacerArgs) =>
                                `${replacerArgs[1]}`,
                              getLinkUrl: () => 'https://elsewhere.to/terms',
                            },
                            {
                              pattern: /<privacy>(.*)<\/privacy>/g,
                              getLinkText: (replacerArgs) =>
                                `${replacerArgs[1]}`,
                              getLinkUrl: () => 'https://elsewhere.to/privacy',
                            },
                          ]}
                          linkProps={{
                            style: {
                              textDecorationLine: 'underline',
                            },
                          }}
                        />
                      </SansText>
                    </Row>
                  )}
                  <Row>
                    {value ? (
                      <Radio.Group
                        key={feature + '-radio-group'}
                        name="packages"
                        space={8}
                        my={8}
                        value={value}
                        onChange={(nextValue: any) => {
                          setValue(nextValue)
                        }}
                      >
                        {/* @ts-ignore */}
                        {offerings[feature]?.map((pp: PurchasesPackage) => {
                          const isPremium = feature === 'premium'
                          const numCredits =
                            feature === 'credits' && pp.identifier.split('_')[1]
                          // Android identifiers are built different
                          const identifier = getIdentifier(pp)
                          return (
                            <Radio
                              key={identifier + '-radio'}
                              value={identifier}
                              isDisabled={isPurchasing}
                              colorScheme={colorScheme}
                            >
                              <SansText pt={1}>
                                {feature === 'credits'
                                  ? t(
                                      'subscriptions.credits.numCreditsWithPrice',
                                      {
                                        numCredits: numCredits,
                                        price: pp.product.priceString,
                                      },
                                    )
                                  : (isPremium
                                      ? t('subscriptions.tiers.premium')
                                      : t('subscriptions.tiers.supporter')) +
                                    ' (' +
                                    t(
                                      `subscriptions.rates.${
                                        pp.packageType === 'MONTHLY'
                                          ? 'perMonth'
                                          : 'perYear'
                                      }`,
                                      { price: pp.product.priceString },
                                    ) +
                                    ')'}
                              </SansText>
                            </Radio>
                          )
                        })}
                      </Radio.Group>
                    ) : null}
                  </Row>
                </Column>
              ) : null}
              <Box>
                <ButtonPill
                  isDisabled={isPurchasing}
                  isLoading={isPurchasing || loading}
                  type={'primary'}
                  onPress={() => {
                    const upgrade =
                      subscriptionTier === 'premium' && feature === 'supporter'
                    purchaseItem(upgrade)
                  }}
                >
                  {t('common.continue')}
                </ButtonPill>
              </Box>
            </Column>
          }
        />
        <AlertPopup
          header={t('dialog.notOnWeb.title')}
          description={t('dialog.notOnWeb.description')}
          hideConfirm={true}
          cancelLabel={t('common.close')}
          isOpen={dialogIsOpen}
          onClose={dialogOnClose}
        />
      </>
    )
  },
)

Feature.displayName = 'Feature'
