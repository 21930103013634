import { Checkbox, ICheckboxProps, useColorModeValue } from 'native-base'
import React from 'react'
import { IconCheck } from '../../../../assets/react-native-svg/icons/icons'
import Markdown from '@ronradtke/react-native-markdown-display'
import {
  DARK_MID_GRAY,
  LIGHT_MID_GRAY,
} from '../../../../constants/ui-constants'
import { Platform } from 'react-native'
import { SansText } from '../../copy/text-sans'
import {
  MD_LINE_HEIGHT,
  MEDIUM_FONT_SIZE,
} from '../../../../constants/constants'

// Children can only be a text node
type CheckboxProps = ICheckboxProps & {
  children?: string
  alignTop?: boolean
}

// Checkbox with a custom icon and a custom background color
export const CheckboxField = React.memo((props: CheckboxProps) => {
  const { children, alignTop, ...rest } = props

  const descriptionColorPure = useColorModeValue(DARK_MID_GRAY, LIGHT_MID_GRAY)

  return (
    <Checkbox
      icon={<IconCheck />}
      borderRadius={0}
      _stack={
        alignTop
          ? { alignItems: 'flex-start', margin: 0, padding: 0, flexShrink: 1 }
          : {}
      }
      {...rest}
    >
      {alignTop ? (
        <Markdown
          style={{
            body: {
              fontFamily: 'ABCFavorit-Book',
              fontWeight: '400',
              fontSize: MEDIUM_FONT_SIZE,
              lineHeight: MD_LINE_HEIGHT,
              color: descriptionColorPure,
              marginTop: -12,
              flex: 1,
              flexWrap: 'wrap',
            },
            link: {
              marginBottom: Platform.OS === 'android' ? -6.75 : -3.2,
            },
          }}
        >
          {children}
        </Markdown>
      ) : (
        <SansText fontFamily={'sans'}>{children}</SansText>
      )}
    </Checkbox>
  )
})
