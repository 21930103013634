import React, { memo } from 'react'
import { parseISO } from 'date-fns'
import { useRoute } from '@react-navigation/native'
import FeedEntryCondensed from './feed-entry-condensed'
import { TagType } from '../../../../../api/frontend-types'
import {
  DEFAULT_INSIGHT_ICON,
  TAGTYPE_ICON_MAP,
} from '../../../constants/ui-constants'
import {
  FeedItemInsightRouteProp,
  RootStackParamList,
} from '../../../navigation/types'
import { UseLinkPropsProps } from '../../../utilities/useLinkPropsFixed'
import { Insight } from '../../../../../api/_openapi'
import { getTitleForInsight } from '../../../modules/analysis-helpers/insight-helpers'
import { useTranslation } from 'react-i18next'

type FeedItemInsightProps = {
  route: FeedItemInsightRouteProp
  insight: Insight
  maxChars?: number
  isLocked?: boolean
}

const FeedItemInsight = React.memo(
  ({ insight, isLocked }: FeedItemInsightProps) => {
    // HOOKS
    const route = useRoute<any>()
    const { t } = useTranslation()

    // VARS
    const insightIsGenerated = Boolean(insight.generated)
    const iconKey = getIconKeyForTag(insight.tag?.type as TagType)
    const cardLabel = getTitleForInsight(insight, t)
    const isLibrary = route.name === 'LibraryDreamerView'
    const isAskYourDreams = route.name === 'PrivateAskYourDreams'
    const linkObject = isLibrary
      ? ({
          screen: 'LibraryInsightView',
          params: {
            id: insight.id,
            username: route?.params?.username,
          },
        } as UseLinkPropsProps['to'])
      : ({
          screen: isAskYourDreams
            ? 'PrivateAskYourDreamsInsightView'
            : ('PrivateInsightView' as keyof RootStackParamList),
          params: {
            id: insight.id,
            isRevealing: !insightIsGenerated,
            username: route?.params?.username,
          },
        } as UseLinkPropsProps['to'])

    const lockedLinkObject = {
      screen: 'PrivateCreditSettingsEdit',
    }

    const linkProps = {
      to: isLibrary ? linkObject : isLocked ? lockedLinkObject : linkObject,
      dataSet: { webLink: true },
    }

    return (
      <FeedEntryCondensed
        date={insight.date}
        title={cardLabel}
        isLocked={isLocked}
        linkProps={linkProps}
        iconKey={iconKey}
        isGenerated={insight.generated}
      />
    )
  },
)

// Decide which symbol icon to show on the card
// based on the tag type
function getIconKeyForTag(tagType?: TagType | null): string {
  if (!tagType) {
    return DEFAULT_INSIGHT_ICON
  } else if (TAGTYPE_ICON_MAP[tagType]) {
    return TAGTYPE_ICON_MAP[tagType]
  } else {
    return DEFAULT_INSIGHT_ICON
  }
}

export default memo(FeedItemInsight)
