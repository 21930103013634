import React, { FC, ReactNode } from 'react'
import { SANS_BOOK_FONT_WEIGHT } from '../../../constants/constants'
import { TextProps, Text } from './text'
import i18n from '../../../i18n/i18nnext'
import {
  ScriptType,
  SupportedLanguage,
} from '../../../../../api/frontend-types'
import {
  getSansFontKey,
  isRtlLanguage,
} from '../../../modules/language-helpers/language-helpers'

type InfoTextProps = TextProps & {
  children?: ReactNode
  language?: SupportedLanguage
  scriptType?: ScriptType
}

export const InfoText: FC<InfoTextProps> = React.memo(
  (props: InfoTextProps) => {
    const { children, language, scriptType, ...rest } = props
    const lang: string = language || i18n.resolvedLanguage || 'en'
    const textAlign = isRtlLanguage(lang) ? 'right' : 'left'
    const fontKey = getSansFontKey(lang, scriptType)

    return (
      <Text
        fontSize={'md'}
        lineHeight={'md'}
        color={'gray.400'}
        fontWeight={SANS_BOOK_FONT_WEIGHT}
        fontFamily={fontKey}
        selectable={true}
        textAlign={textAlign}
        {...rest}
      >
        {children}
      </Text>
    )
  },
)
