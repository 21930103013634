import React, { useState } from 'react'
import { TouchableOpacity } from 'react-native'
import { ImageOnObject } from '../../../../../api/frontend-types'
import { Loading } from '../../layout/loading'
import { ButtonIcon } from '../buttons/button-icon'
import { useTranslation } from 'react-i18next'
import { avatarAndIconButtonSizes, Size } from '../../../core/theme'
import BoringAvatar from '../../../modules/react-native-boring-avatars'
import {
  CREAM,
  DARK_GRAY,
  DARK_MID_GRAY,
  LIGHT_BLACK,
  LIGHT_GRAY,
  LIGHT_MID_GRAY,
} from '../../../constants/ui-constants'
import { ImageForUpload, UploadOptions } from './modules/upload-images/types'
import { uploadImages } from './modules/upload-images/upload-images'
import { onUploadButtonPress } from './modules/upload-images/on-onupload-button-press'
import { ElsewhereIconType } from '../../../modules/ui-helpers/icon-map'
import { IconCharacterOutlined } from '../../../assets/react-native-svg/icons/icons-tag'
import { useAuth } from '../../../contexts/auth-context'
import { Row } from '../row/row'
import { NbImage } from '../image/nb-image'

type FieldImageUploadProps = {
  value?: ImageOnObject
  onChange: (value: ImageOnObject) => void
  iconKey?: ElsewhereIconType
  options?: UploadOptions
  setImageLoading?: (loading: boolean) => void
  setLoadingLabel?: (loadingLabel: string) => void
  isDisabled?: boolean
  size?: Size
  avatarFallbackName?: string
}

export const FieldImageUpload = React.memo(
  ({
    value,
    onChange,
    iconKey,
    options,
    setImageLoading,
    setLoadingLabel,
    isDisabled,
    size,
    avatarFallbackName,
  }: FieldImageUploadProps) => {
    // STATE
    const [loading, setLoading] = useState<boolean>(false)

    // SELECTORS
    const { authData } = useAuth()

    // HOOKS
    const { t } = useTranslation()

    // VARIABLES
    const token = authData?.token
    const hasImage =
      value !== undefined && value !== null && value?.url !== null

    const onImageSelected = async (images: ImageForUpload[]) => {
      if (!images) return
      const newImages = await uploadImages(
        images,
        token || '',
        {
          fileSize: t('toast.uploadImageTooLargeError.description'),
          uploading: t('toast.uploadImageError.title'),
        },
        options,
      )

      if (newImages && newImages[0]) {
        onChange(newImages[0])
      }
      setLoading(false)
    }

    const avatarSize: Size = size || 'lg'
    const buttonSize = avatarAndIconButtonSizes[avatarSize].width

    const onDismissPicker = () => {
      setLoading(false)
    }

    return !iconKey ? (
      <Row mt={4} justifyContent={'center'}>
        <TouchableOpacity
          onPress={() => {
            onUploadButtonPress(options, onImageSelected, onDismissPicker)
            setLoading(true)
          }}
        >
          {/* LOADING */}
          {loading && <Loading width={buttonSize * 4} />}
          {/* PROFILE IMAGE */}
          {/* NO IMAGE, SHOW BORING AVATAR */}
          {!loading && !hasImage && avatarFallbackName && (
            <BoringAvatar
              style={{ backgroundColor: 'black' }}
              size={buttonSize * 4}
              name={avatarFallbackName}
              variant="bauhaus"
              colors={[
                CREAM,
                LIGHT_BLACK,
                DARK_MID_GRAY,
                LIGHT_MID_GRAY,
                LIGHT_GRAY,
                DARK_GRAY,
              ]}
            />
          )}
          {/* NO avatarFallbackName, SHOW DUMMY AVATAR */}
          {!loading && !hasImage && !avatarFallbackName && (
            <IconCharacterOutlined size={buttonSize} />
          )}
          {!loading && hasImage && (
            <NbImage
              source={{ uri: value.url }}
              alt={'Profile picture'}
              size={buttonSize}
              borderRadius={'full'}
            />
          )}
        </TouchableOpacity>
      </Row>
    ) : (
      <ButtonIcon
        m={0}
        p={0}
        iconKey={iconKey}
        variant={'outlined'}
        // onPress={() => showWidget(widget)}
        onPress={() => {
          onUploadButtonPress(options, onImageSelected, onDismissPicker)
          setLoading(true)
        }}
        isDisabled={isDisabled}
        size={size}
      />
    )
  },
)
