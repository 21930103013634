import { selectGenderOptions } from '../ducks/ui/ui'
import { FormDetails, FormFieldDetails } from '../components/common/form/form'
import { myersBriggsPersonalityTypes } from '../modules/select-options/myers-briggs'
import { sub } from 'date-fns'
// @ts-ignore
import store from '../ducks/store'
import { checkUsernameAvailability } from '../ducks/user/functions/user-functions'
import { dateFromString } from '../modules/strings/string-helpers'
import { WHITE } from '../constants/ui-constants'
import { Platform } from 'react-native'
import { CheckboxGroupWithFreeOptionType } from '../components/common/form/form-field'

const fields: { [key: string]: FormFieldDetails } = {
  username: {
    label: 'signupForm.chooseUsername',
    description: 'signupForm.chooseUsername.description',
    name: 'username',
    type: 'input',
    focus: true,
    placeholder: 'signupForm.chooseUsername.placeholder',
    dir: 'ltr', // Force LTR for email input
    scriptType: 'latin', // Force latin script for email input
    rules: {
      validate: async (value: string) => {
        if (!value) {
          return 'formWarning.username.required'
        }
        if (!!value && value === store.getState().user?.user?.username) {
          return true
        }
        if (value.length > 20) {
          return 'formWarning.username.tooLong'
        }
        if (
          !value.match(/^[^±!@£$%^&*+§¡€#¢¶•ªº«\\/<>?:;|=.,`~{}[\]]{1,20}$/)
        ) {
          return 'formWarning.username.invalidCharacters'
        } else if (!value.match(/^@?(\w){1,20}$/)) {
          return 'formWarning.username.validCharacters'
        } else if (value.length < 3) {
          return 'formWarning.username.tooShort'
        } else {
          const isAvailable = await checkUsernameAvailability(value)
          return isAvailable ? true : `formWarning.username.alreadyTaken`
        }
      },
    },
  },
  email: {
    label: 'signupForm.email',
    description: 'signupForm.email.description',
    name: 'email',
    type: 'input',
    placeholder: 'common.emailPlaceholder',
    dir: 'ltr', // Force LTR for email input
    scriptType: 'latin', // Force latin script for email input
    rules: {
      validate: async (value: string) => {
        if (!value) {
          return 'formWarning.email.required'
        }
        value = value.trim()
        if (!value.match(/^[^\s@]+@[^\s@]+\.(([^\s@]+){2,})$/i)) {
          return 'formWarning.email.invalid'
        } else {
          const isAvailable = await checkUsernameAvailability(
            value.toLowerCase(),
          )
          return isAvailable ? true : 'formWarning.email.alreadySignedUp'
        }
      },
    },
  },
  birthdate: {
    label: 'signupForm.dateOfBirth',
    name: 'birthdate',
    type: 'date',
    placeholder: 'common.selectDate',
    description: 'signupForm.dateOfBirth.description',
    displayType: 'textInput',
    rules: {
      required: false,
      validate: (date: string) => {
        const dateVar = date && dateFromString(date)
        if (dateVar && dateVar > sub(new Date(), { years: 13 })) {
          return 'formWarning.age.tooYoung'
        }
      },
    },
  },
  country: {
    label: 'signupForm.homeCountry',
    description: `signupForm.homeCountry.description`,
    name: 'country',
    type: 'country-select',
  },
  personalityType: {
    label: 'signupForm.myersBriggs',
    description: `signupForm.myersBriggs.description`,
    name: 'personalityType',
    type: 'select',
    options: myersBriggsPersonalityTypes,
    maintainSortOrder: true,
  },
  gender: {
    label: 'signupForm.gender',
    description: 'signupForm.gender.description',
    name: 'gender',
    type: 'checkboxes-with-free-option',
    optionsSelector: selectGenderOptions,
    checkboxWithFreeOptionProps: {
      multiSelect: false,
      disablePreSelected: false,
      freeOptionTextMaxLength: 50,
    },
    rules: {
      validate: async (value: CheckboxGroupWithFreeOptionType) => {
        if (
          value.values.includes(value.freeOption) &&
          value.freeOptionText.length === 0
        ) {
          return false
        }
        return true
      },
    },
  },
  pronouns: {
    label: 'signupForm.pronouns',
    description: 'signupForm.pronouns.description',
    name: 'pronouns',
    type: 'input',
    rules: {
      validate: async (value: string) => {
        if (value && value.length > 32) {
          return 'formWarning.inputTooLong'
        }
        return true
      },
    },
  },
  tags: {
    label: 'Tags',
    name: 'tags',
    type: 'tagarea',
  },
  profileImage: {
    label: 'userForm.profileImage',
    description: 'userForm.profileImage.description',
    name: 'profileImage',
    type: 'image-picker',
    imagePickerProps: {
      // @ts-ignore
      mediaType: 'photo',
      includeBase64: false,
      width: 512,
      height: 512,
      cropping: true,
      cropperCircleOverlay: true,
      cropperChooseColor: WHITE,
      cropperCancelColor: WHITE,
      type: 'profile-image',
      alt: 'Profile picture for user',
      croppingAspectRatio: Platform.OS === 'web' ? 1 : undefined,
      showSkipCropButton: Platform.OS === 'web' ? false : undefined,
      croppingDefaultSelectionRatio: Platform.OS === 'web' ? 0.99 : undefined,
    },
  },
  bio: {
    label: 'userForm.bio',
    description: 'userForm.bio.description',
    name: 'bio',
    type: 'textarea-controlled',
    placeholder: 'userForm.bio.placeholder',
  },
  marketingEmails: {
    label: 'userForm.marketing',
    name: 'marketingEmails',
    type: 'checkboxes',
    options: [
      {
        label: 'userForm.marketing.checkbox.label',
        value: 'true',
      },
    ],
  },
  terms: {
    label: 'userForm.terms',
    name: 'terms',
    type: 'checkboxes',
    options: [
      {
        label: 'userForm.terms.checkbox.label',
        value: 'true',
      },
    ],
    rules: {
      validate: (value: string[]) => {
        return value.length !== 0
      },
    },
  },
}

export const signupForm: FormDetails = {
  pages: [
    {
      fields: [fields.username, fields.email, fields.birthdate],
    },
    {
      fields: [fields.country, fields.personalityType],
    },
    {
      fields: [fields.gender, fields.pronouns],
    },
    {
      fields: [fields.marketingEmails, fields.terms],
    },
  ],
}

export const basicDetailsForm: FormDetails = {
  pages: [
    {
      fields: [
        { ...fields.username, label: 'userForm.username' },
        fields.profileImage,
        fields.pronouns,
        fields.bio,
      ],
    },
  ],
}

export const userSettingsForm: FormDetails = {
  pages: [
    {
      fields: [fields.darkMode, fields.language],
    },
  ],
}

export const notificationsForm: FormDetails = {
  pages: [
    {
      fields: [
        {
          label: 'settings.allNotifications',
          name: 'allowNotifications',
          type: 'switch',
        },
        // HIDE FOR NOW
        // {
        //   label: 'settings.allowWeeklyReportNotifications',
        //   name: 'allowWeeklyReportNotifications',
        //   type: 'switch',
        // },
        {
          label: 'userForm.dailyReminder',
          name: 'dailyReminder',
          type: 'switch-with-value',
        },
      ],
    },
  ],
}

export const dreamerProfileForm: FormDetails = {
  pages: [
    {
      fields: [
        fields.birthdate,
        fields.country,
        fields.personalityType,
        fields.gender,
      ],
    },
  ],
}
