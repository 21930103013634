import React from 'react'
import { ImageURISource } from 'react-native'
import { AvatarCircle } from '../../common/avatar/avatar-circle'
import { SansText } from '../../common/copy/text-sans'
import { useColorModeValue } from 'native-base'
import { useUserDate } from '../../../hooks/useUserDate'
import { SymbolComponent, SymbolComponentMap } from '../../../assets/symbols'
import { CREAM, LIGHT_BLACK } from '../../../constants/ui-constants'
import { ButtonPill } from '../../common/buttons/button-pill'
import { useTranslation } from 'react-i18next'
import { Row } from '../../common/row/row'
import { Box } from '../../common/box/box'
import { Column } from '../../common/column/column'

type FeedEntryCondensedProps = {
  maxChars?: number
  imageSource?: ImageURISource
  date: string
  title: string
  isLocked: boolean | undefined
  isGenerated: boolean | undefined
  linkProps: any
  iconKey?: string
}

const FeedEntryCondensed = ({
  imageSource,
  date,
  title,
  isLocked,
  linkProps,
  iconKey,
  isGenerated,
}: FeedEntryCondensedProps) => {
  // HOOKS
  const { t } = useTranslation()
  const symbolColor = useColorModeValue(LIGHT_BLACK, CREAM)
  const symbolBgColor = useColorModeValue(CREAM, LIGHT_BLACK)

  // 1) Symbol illustration
  const SymbolComponent: SymbolComponent | null =
    SymbolComponentMap[iconKey || ''] || null
  const hasSymbol = !!SymbolComponent
  const SymbolElement = SymbolComponent ? (
    <SymbolComponent size={30} color={symbolColor} />
  ) : null

  const { dateString } = useUserDate(date)

  return (
    <Row
      alignItems={'center'}
      justifyContent={'space-between'}
      minHeight={75}
      paddingLeft={3}
      paddingRight={3}
    >
      <Row alignItems={'center'}>
        {/* 2) Avatar */}
        {hasSymbol && (
          <Box
            width={12}
            height={12}
            backgroundColor={symbolBgColor}
            borderRadius={100}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {SymbolElement}
          </Box>
        )}
        {!hasSymbol && (
          <AvatarCircle size={'md'} source={imageSource} name={title} />
        )}
        <Column marginX={3}>
          <SansText mb={1}>{title}</SansText>
          <SansText fontSize={'sm'} color={'gray.400'}>
            {dateString}
          </SansText>
        </Column>
      </Row>
      <Row alignItems={'center'}>
        <Box alignItems={'center'} justifyContent={'center'}>
          {isGenerated ? (
            <ButtonPill
              endIconKey={'caret-i18n'}
              buttonSize={'sm'}
              type={'subtle'}
              width={120}
              isLink={true}
              linkProps={linkProps}
            >
              {t('common.view')}
            </ButtonPill>
          ) : (
            <ButtonPill
              endIconKey={isLocked ? 'lock' : 'wand'}
              buttonSize={'sm'}
              type={'subtle'}
              width={120}
              isLink={true}
              linkProps={linkProps}
            >
              {t('common.reveal')}
            </ButtonPill>
          )}
        </Box>
      </Row>
    </Row>
  )
}

export default FeedEntryCondensed
