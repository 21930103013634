import React, { FC, ReactNode } from 'react'
import i18n from 'i18next'
import {
  MD_LINE_HEIGHT,
  MEDIUM_FONT_SIZE,
  SANS_BOOK_FONT_WEIGHT,
} from '../../../constants/constants'
import {
  getFontSuffix,
  getSansFont,
} from '../../../modules/language-helpers/language-helpers'
import { SupportedLanguage } from '../../../../../api/frontend-types'
import { ILinkProps, Link } from '../link/link'

type SansTextProps = ILinkProps & {
  children?: ReactNode
  language?: SupportedLanguage
}

export const LinkSans: FC<ILinkProps> = React.memo(
  ({
    children,
    fontWeight = SANS_BOOK_FONT_WEIGHT,
    fontSize = MEDIUM_FONT_SIZE,
    lineHeight = MD_LINE_HEIGHT,
    textTransform = 'none',
    textAlign,
    language,
    href,
    color,
    ...rest
  }: SansTextProps) => {
    // VARS
    const lang: string = language || i18n.resolvedLanguage || 'en'
    const fontSuffix = getFontSuffix(lang)
    const alignText = textAlign || i18n.dir() === 'rtl' ? 'right' : 'left'
    const font = getSansFont(fontSuffix)

    return (
      <Link
        variant="link"
        href={href}
        color={color}
        textAlign={alignText}
        fontFamily={font}
        fontWeight={fontWeight}
        _text={{
          fontFamily: font,
          fontWeight: fontWeight,
          lineHeight: lineHeight,
          fontSize: fontSize,
        }}
        {...rest}
      >
        {children}
      </Link>
    )
  },
)
