import React from 'react'
import { Input as NBTextInput } from 'native-base'
import { IInputProps } from 'native-base/lib/typescript/components/primitives/Input/types'
import { ButtonIcon } from '../buttons/button-icon'
import { useColorModeValue } from 'native-base'
import { LINE_WIDTH, SANS_BOOK_FONT_WEIGHT } from '../../../constants/constants'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import i18n from '../../../i18n/i18nnext'
import { Row } from '../row/row'

// The regular props from NativeBase's Input component
// Plus a type prop, so we can say it's a password input
type InputSearchProps = IInputProps & {
  wrapperWidth?: number | string
  onSubmit: () => void
  onSubmitEditing: () => void
  onClear: () => void
  submitLabel?: string
  submitIsLoading?: boolean
  showClearButton?: boolean
}

export const InputSearch = ({
  type,
  wrapperWidth,
  onSubmit,
  onSubmitEditing,
  onClear,
  submitLabel,
  submitIsLoading,
  showClearButton,
  ...rest
}: InputSearchProps) => {
  const borderColor = useColorModeValue(BLACK, WHITE)
  const backgroundColor = useColorModeValue(WHITE, BLACK)
  const keyboardAppearance = useColorModeValue('light', 'dark')
  const dir = i18n.dir()
  const isRtl = dir === 'rtl'

  return (
    <Row position={'relative'} width={wrapperWidth} flexGrow={1}>
      <NBTextInput
        _focus={{ borderColor, backgroundColor }}
        textAlign={isRtl ? 'right' : 'left'}
        fontSize={'md'}
        size={'md'}
        fontFamily={'sans'}
        fontWeight={SANS_BOOK_FONT_WEIGHT}
        variant={'rounded'}
        type={'text'}
        autoCapitalize="none"
        borderWidth={LINE_WIDTH}
        pl={4}
        color={borderColor}
        // @ts-ignore
        enterKeyHint={'search'}
        keyboardAppearance={keyboardAppearance}
        style={{
          height: 55,
          color: borderColor,
        }}
        onSubmitEditing={onSubmitEditing}
        {...rest}
      />
      {showClearButton && (
        <ButtonIcon
          iconKey="close"
          onPress={() => {
            onClear()
          }}
          variant={'placeholder'}
          color={'transparent'}
          style={{
            position: 'absolute',
            right: isRtl ? undefined : 55,
            left: isRtl ? 55 : undefined,
            top: 5,
          }}
        />
      )}
      <ButtonIcon
        iconKey="search"
        onPress={onSubmit}
        variant={'filled'}
        color={borderColor}
        style={{
          position: 'absolute',
          right: isRtl ? undefined : 5,
          left: isRtl ? 5 : undefined,
          top: 5,
        }}
      />
    </Row>
  )
}
