import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DreamComment from './DreamComment'
import AddDreamComment from './AddDreamComment'
import { useFocusEffect } from '@react-navigation/native'
import { elsewhereToast } from '../../../components/common/toast/toast'
import { Loading } from '../../../components/layout/loading'
import { HeadingSans } from '../../../components/common/copy/heading-sans'
import { FEW } from '../../../i18n/config'
import { PADDING_TO_TABS } from '../../../constants/ui-constants'
import {
  decCommentCount,
  setCommentCount,
} from '../../../ducks/dream-tag/dream-tag'
import { useDispatch } from 'react-redux'
import { addReactionToDream } from '../../../ducks/dream-tag/thunks/dream-thunks'
import { ReactionRow } from '../../../components/composite/feed/reaction-row'
import { Column } from '../../../components/common/column/column'
import {
  FastComment,
  FastCommentService,
  FastDream,
} from '../../../../../api/_openapi'

type DreamCommentsProps = {
  inGroup: boolean
  scrollToFocusedInput?: (reactNode: any) => void
  dream: FastDream
}

export default function DreamComments({
  inGroup,
  dream,
  scrollToFocusedInput,
}: DreamCommentsProps) {
  // HOOKS
  const { t } = useTranslation()
  const dispatch = useDispatch<any>()

  // STATE
  const [comments, setComments] = useState<FastComment[]>([])
  const [commentsLoading, setCommentsLoading] = useState(false)

  // Load comments
  const loadComments = async (initialLoad: boolean) => {
    if (dream.shareId) {
      if (initialLoad) {
        setCommentsLoading(true)
      }
      try {
        const comments = await FastCommentService.getComments(dream.shareId)
        setComments(
          comments.sort((a, b) => a.createdAt.localeCompare(b.createdAt)),
        )
        dispatch(
          setCommentCount({ shareId: dream.shareId, count: comments.length }),
        )
      } catch (err: any) {
        if (initialLoad) {
          elsewhereToast.showToast({
            description: t('errors.loading'),
            status: 'error',
          })
        }
      } finally {
        setCommentsLoading(false)
      }
    } else {
      console.log('No shareId provided')
    }
  }

  // Delete a comment
  const deleteComment = (comment: FastComment) => {
    FastCommentService.deleteComment(comment.id)
      .then(() => {
        setComments(comments.filter((c) => c.id !== comment.id))
        dispatch(decCommentCount(dream.shareId))
        elsewhereToast.showToast({
          description: t('common.deleted'),
          status: 'success',
        })
      })
      .catch((e) => {
        console.log('Error deleting comment', e)
        elsewhereToast.showToast({
          description: t('common.errorGeneric'),
          status: 'error',
        })
      })
  }

  // EFFECTS
  useEffect(() => {
    loadComments(true)
  }, [dream.shareId])

  //POLLING EFFECTS
  useFocusEffect(
    React.useCallback(() => {
      const interval = setInterval(() => {
        loadComments(false)
      }, 15000)

      return () => {
        clearInterval(interval)
      }
    }, [dream.shareId]),
  )

  const addOrRemoveReaction = () => {
    dispatch(
      addReactionToDream(dream.id, dream.shareId, dream.userReactionCount),
    )
  }

  return (
    <Column mb={40}>
      <ReactionRow
        inGroup={inGroup}
        commentCount={dream.commentCount}
        reactionCount={dream.reactionCount}
        userReactionCount={dream.userReactionCount}
        showCommentCount={false}
        onReactionPress={addOrRemoveReaction}
      />
      {comments.length > 0 && (
        <HeadingSans>{t('common.comment_plural', { count: FEW })}</HeadingSans>
      )}
      {commentsLoading ? (
        <Loading mb={PADDING_TO_TABS} />
      ) : (
        <>
          <Column>
            {comments.map((comment) => (
              <DreamComment
                key={comment.id}
                comment={comment}
                deleteComment={deleteComment}
              />
            ))}
          </Column>
          <Column>
            <AddDreamComment
              shareId={dream.shareId}
              onAdded={(comment: FastComment) => {
                setComments([...comments, comment])
              }}
              scrollToFocusedInput={scrollToFocusedInput}
            />
          </Column>
        </>
      )}
    </Column>
  )
}
