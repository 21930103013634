import React, { useState, useMemo } from 'react'
import { useColorModeValue } from 'native-base'
import { Dimensions } from 'react-native'
import { InputField } from '../inputs/input-field'
import { IconCheck } from '../../../assets/react-native-svg/icons/icons'
import { FormFieldOption } from '../../../../../api/frontend-types'
import { useTranslation } from 'react-i18next'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { SansText } from '../copy/text-sans'
import { Row } from '../row/row'
import { ScrollView } from '../scrollview/scrollview'
import { FormControl } from '../form-control/form-control'
import { Actionsheet } from './action-sheet'

export type ActionSheetOption = FormFieldOption & {
  icon?: JSX.Element
  creating?: boolean
}

// If searchable, a search input will be shown at the top of the menu
// Something like react-select
type ActionSheetMenu = {
  options: ActionSheetOption[]
  isOpen: boolean
  onClose: () => void
  searchable?: boolean
  onOptionSelect: (option: ActionSheetOption) => void
  selectedOption?: string
  heading?: string
}

export const ActionSheetOptionsMenu = React.memo(
  ({
    options,
    onOptionSelect,
    searchable = false,
    onClose,
    isOpen,
    selectedOption,
    heading,
  }: ActionSheetMenu) => {
    // Get page height
    const pageHeight = Dimensions.get('window').height
    const { t } = useTranslation()
    const [searchValue, setSearchValue] = useState<string>('')

    // Todo: replace with Fuse.js
    const filteredOptions = useMemo(() => {
      if (searchValue === '') {
        return options
      }
      return options.filter((option) => {
        return (
          option.label.toLowerCase().includes(searchValue.toLowerCase()) ??
          false
        )
      })
    }, [searchValue, options])

    // If the menu is searchable, we want it to remain a fixed height
    // So it doesn't grow and shrink as the user types
    const height = searchable ? pageHeight * 0.7 : undefined

    const color = useColorModeValue(BLACK, WHITE)

    return (
      <Actionsheet
        isOpen={isOpen}
        onClose={() => {
          setSearchValue('')
          onClose()
        }}
      >
        <Actionsheet.Content height={height}>
          {searchable && (
            <FormControl>
              <InputField
                placeholder={t('common.search')}
                onChangeText={(text: string) => {
                  setSearchValue(text)
                }}
                value={searchValue}
                m={4}
                autoFocus
              />
            </FormControl>
          )}
          {heading && (
            <Row
              justifyContent={'center'}
              alignItems={'center'}
              p={4}
              borderBottomWidth={1}
            >
              <SansText color={color} fontSize={'sm'} fontWeight={600}>
                {heading}
              </SansText>
            </Row>
          )}
          <ScrollView width={'100%'}>
            {filteredOptions.map((option: ActionSheetOption) => {
              return (
                <Actionsheet.Item
                  key={option.value}
                  onPress={() => {
                    onOptionSelect(option)
                    onClose()
                  }}
                  startIcon={option.icon ? option.icon : undefined}
                  endIcon={
                    selectedOption === option.value ? (
                      <IconCheck size={'xs'} />
                    ) : undefined
                  }
                >
                  <SansText
                    fontFamily={'sans'}
                    color={color}
                    style={{
                      lineHeight: 22, // Somehow this is needed to make the text vertically centered
                    }}
                  >
                    {option.label}
                  </SansText>
                </Actionsheet.Item>
              )
            })}
          </ScrollView>
        </Actionsheet.Content>
      </Actionsheet>
    )
  },
)
