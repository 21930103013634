import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DreamerProfile,
  FastUser,
  InterpretationStyle,
} from '../../../../api/_openapi'
import { RootState } from '../root-reducer'
import { PURGE } from 'redux-persist'
import {
  DownloadFormat,
  SupportedLanguage,
} from '../../../../api/frontend-types'
import { undecorateId } from '../../../../api/decorate-ids'
import {
  DEFAULT_ART_STYLE_ID,
  DEFAULT_INTERPRETATION_STYLE_ID,
  STYLES_W_CUSTOM_PLACEHOLDERS,
} from '../../constants/constants'
import { AuthData } from '../../services/auth-service'
import { ColorMode } from 'native-base'
import { isEmptyObject } from '../../modules/helpers/helpers'

export type ExportLink = {
  code: string
  format: DownloadFormat
  expiry?: number
  loading: boolean
}

export type UserState = {
  authData?: AuthData
  user?: FastUser
  currentProfile?: DreamerProfile
  exportLink?: ExportLink
  notificationToken?: string
  userFetchFailed: boolean
  userProfileUpdated: number // Hack to force re-render of profile page
}

const initialState: UserState = {
  authData: undefined,
  user: undefined,
  currentProfile: undefined,
  exportLink: undefined,
  notificationToken: undefined,
  userFetchFailed: false,
  userProfileUpdated: 0,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  // https://redux-toolkit.js.org/usage/usage-guide
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState
    })
  },
  reducers: {
    setAuthData: (state, action) => {
      state.authData = action.payload
    },
    setUser(state, action: PayloadAction<FastUser>) {
      const newUser = action.payload
      if (!newUser.id || isEmptyObject(newUser)) {
        state.userFetchFailed = true
      } else {
        state.userFetchFailed = false
        state.user = newUser
      }
    },
    // Set everything on the user object except the profile image
    // Because the user returned by UserService.updateUserSubscription is wrong
    setUserSubscriptionStatus(state, action: PayloadAction<FastUser>) {
      const originalUser = state.user
      const newUser = action.payload
      const profileImage = {
        profileImageId: originalUser?.profileImageId || null,
        profileImageUrl: originalUser?.profileImageUrl || null,
        profileImageAlt: originalUser?.profileImageAlt || null,
      }
      state.user = {
        ...newUser,
        ...profileImage,
      }
    },
    setCurrentUserProfile(state, action: PayloadAction<DreamerProfile>) {
      state.currentProfile = action.payload
    },
    setUserCreditsAvailable(state, action: PayloadAction<number>) {
      if (state.user) {
        state.user.creditsAvailable = action.payload
      }
    },
    setNotificationToken(state, action: PayloadAction<string>) {
      state.notificationToken = action.payload
    },
    setExportLink(state, action: PayloadAction<ExportLink>) {
      state.exportLink = action.payload
    },
    setUserProfileUpdated(state) {
      state.userProfileUpdated = new Date().getTime()
    },
    resetUserState() {
      return initialState
    },
    setColorMode(state, action: PayloadAction<ColorMode | 'auto'>) {
      if (state?.user) {
        state.user.colorMode = action.payload as string
      }
    },
  },
})

export const {
  setAuthData,
  setUser,
  resetUserState,
  setCurrentUserProfile,
  setNotificationToken,
  setExportLink,
  setUserProfileUpdated,
  setUserCreditsAvailable,
  setColorMode,
  setUserSubscriptionStatus,
} = userSlice.actions

export const selectAuthData = (state: RootState): AuthData | undefined => {
  return state?.user.authData
}

export const selectUser = (state: RootState): FastUser | undefined => {
  return state?.user.user
}

export const selectUserFetchFailed = (state: RootState): boolean => {
  return state?.user.userFetchFailed
}

export const selectUserLanguage = (state: RootState): SupportedLanguage => {
  const userLanguage = state?.user.user?.languageCode
  return userLanguage || 'en'
}

export const selectUserInterpretationStyle = (state: RootState): string => {
  const userInterpretationStyle = state?.user.user?.interpretationStyleId
  const interpStyle = userInterpretationStyle || DEFAULT_INTERPRETATION_STYLE_ID
  return undecorateId(interpStyle)
}

export const selectDefaultArtStyle = (state: RootState): string => {
  const defaultArtStyle = state?.user.user?.artStyleId
  return defaultArtStyle || DEFAULT_ART_STYLE_ID
}

export const selectNotificationToken = (
  state: RootState,
): string | undefined => {
  return state?.user.notificationToken
}

export const selectFeatureMap = (state: RootState): any => {
  return state?.ui.featureMap
}

export const selectUserIsPremium = (state: RootState): boolean => {
  return ['supporter', 'premium'].includes(
    state?.user.user?.subscriptionTier || 'free',
  )
}

export const selectExportLink = (state: RootState): ExportLink | undefined => {
  return state?.user.exportLink
}

export const selectUserProfileUpdated = (state: RootState): number => {
  return state?.user.userProfileUpdated
}

export const selectCurrentUserProfile = (
  state: RootState,
): DreamerProfile | undefined => {
  return state?.user.currentProfile
}

export const selectDreamAddPlaceholder = (state: RootState): string => {
  // 1) Interpretation style from state
  const user: FastUser | undefined = state?.user.user
  const interpretationStyles = state?.ui.interpretationStyles || []
  const interpretationStyleKey = getInterpretationStyleKey(
    user,
    interpretationStyles,
  )
  const isCustom = STYLES_W_CUSTOM_PLACEHOLDERS.includes(interpretationStyleKey)
  const userStyleKey = isCustom ? interpretationStyleKey : 'standard'
  // 2) Return placeholder string key
  return `addDreamPage.textArea.placeholder.${userStyleKey}`
}

// Select placeholder string key for dream notes
// Based on user's selected interpretation style
export const selectNotePlaceholder = (state: RootState): string => {
  // 1) Interpretation style from state
  const user: FastUser | undefined = state?.user.user
  const interpretationStyles = state?.ui.interpretationStyles || []
  const interpretationStyleKey = getInterpretationStyleKey(
    user,
    interpretationStyles,
  )

  const isCustom = STYLES_W_CUSTOM_PLACEHOLDERS.includes(interpretationStyleKey)
  const userStyleKey = isCustom ? interpretationStyleKey : 'standard'

  // 2) Return placeholder string key
  return `addDreamPage.note.placeholder.${userStyleKey}`
}

// HELPERS
// Get user's interpretation style key
// E.g. 'freudian', 'jungian', 'standard'
function getInterpretationStyleKey(
  user: FastUser | undefined,
  styles: InterpretationStyle[],
): string {
  const userStyleId = undecorateId(user?.interpretationStyleId)
  const styleForUser = styles.find((s) => s.id === userStyleId)
  return styleForUser?.key || 'standard'
}

export const selectColorMode = (state: RootState): ColorMode | 'auto' => {
  return state?.user?.user?.colorMode as ColorMode | 'auto'
}

export const selectIsElsewhereTeam = (state: RootState): boolean => {
  return state?.user?.user?.roles.includes('ROLE_ELSEWHERE_TEAM') || false
}

export default userSlice
