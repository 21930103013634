import React from 'react'
import { SettingsMenuItem } from './settings-item'
import { useSelector } from '../../../ducks/root-reducer'
import { ContentDivider } from '../../layout/content-divider'
import {
  SettingsItem,
  SettingsSection,
} from '../../../../../api/frontend-types'
import { Platform } from 'react-native'
import { HeadingSans } from '../../common/copy/heading-sans'
import { selectSubscriptionStatus } from '../../../ducks/subscription/subscription'
import { isAtLeastTier } from '../../../modules/subscriptions/subscription-helpers'
import { RootStackScreenProps, SettingsLinks } from '../../../navigation/types'
import { SCROLLVIEW_PROPS } from '../../../constants/ui-constants'
import { Box } from '../../common/box/box'
import { ScrollView } from '../../common/scrollview/scrollview'

type SettingsListProps = (
  | RootStackScreenProps<'PrivateSettings'>
  | RootStackScreenProps<'PrivateAboutElsewhere'>
  | RootStackScreenProps<'GroupSettings'>
) & {
  sections: SettingsSection[]
}

export const SettingsList = React.memo(
  ({ navigation, route, sections }: SettingsListProps) => {
    // SELECTORS
    const subscriptionStatus = useSelector(selectSubscriptionStatus)
    const userTier = subscriptionStatus.tier

    // VARS
    const isWeb = Platform.OS === 'web'
    const isMobile = Platform.OS === 'ios' || Platform.OS === 'android'

    return (
      <ScrollView {...SCROLLVIEW_PROPS}>
        {sections
          .filter((section) => {
            const userMeetsTier = isAtLeastTier(userTier, section.minTier)
            const platformAllowed = !(section.hideOnWeb && isWeb)
            return userMeetsTier && platformAllowed
          })
          .map((section, index) => (
            <Box style={{ width: '100%' }} key={index}>
              <Box px={Platform.OS === 'web' ? 0 : 4}>
                <HeadingSans width={'100%'} mb={2} mt={6}>
                  {section.title}
                </HeadingSans>
                {section.items
                  .filter((item: SettingsItem) => {
                    return (
                      !(item.hideOnWeb && isWeb) &&
                      !(item.hideOnMobile && isMobile)
                    )
                  })
                  .map((item, index) => (
                    <SettingsMenuItem
                      key={index}
                      label={item.label}
                      subLabel={item.subLabel}
                      icon={item.icon}
                      onPress={() => {
                        if (item.onPress) {
                          item.onPress()
                        } else if (item.internalLink) {
                          navigation.navigate(
                            item.internalLink as SettingsLinks,
                            item.internalLinkParams,
                          )
                        }
                      }}
                      internalLink={item.internalLink}
                    />
                  ))}
              </Box>
              {index !== sections.length - 1 ? (
                <ContentDivider mt={4} mb={0} />
              ) : (
                <Box mb={16} />
              )}
            </Box>
          ))}
      </ScrollView>
    )
  },
)
