import React from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderLeft } from '../../shared'
import * as Screens from '../../../screens'
import { cap } from '../../../modules/strings/string-helpers'
import { FEW } from '../../../i18n/config'
import DreamEllipsis from '../../../components/composite/feed/dream-ellipsis'
import { MainStackType } from '../../MainStack'

export default function (MainStack: MainStackType) {
  const { t } = useTranslation()
  return (
    <>
      <MainStack.Screen
        name="PrivateDiaryFeed"
        component={Screens.DiaryFeed}
        options={{
          title: cap(t('common.diary')),
        }}
      />
      <MainStack.Screen
        name="PrivateDiaryView"
        component={Screens.DiaryView}
        options={{
          title: cap(t('Diary Entry')),
          headerLeft: HeaderLeft,
          headerRight: () => <DreamEllipsis />,
        }}
      />
    </>
  )
}
