import React, { useState } from 'react'
import { Platform } from 'react-native'
import { ImageOnObject } from '../../../../../api/frontend-types'
import { useTranslation } from 'react-i18next'
import { ButtonPill } from '../buttons/button-pill'
import { uploadImages } from './modules/upload-images/upload-images'
import { ImageForUpload, UploadOptions } from './modules/upload-images/types'
import { onUploadButtonPress } from './modules/upload-images/on-onupload-button-press'
import { ImageArrayEditable } from '../image-array-editable/image-array-editable'
import { onTakePhotoButtonPress } from './modules/upload-images/on-take-photo-press'
import { useAuth } from '../../../contexts/auth-context'

type FieldFieldMultiImagePickerWithCameraProps = {
  pickedImages: ImageOnObject[]
  onChange: (value: ImageOnObject[]) => void
  options?: UploadOptions
}

export const FieldMultiImagePickerWithCamera = ({
  pickedImages,
  onChange,
  options,
}: FieldFieldMultiImagePickerWithCameraProps) => {
  // STATE
  const [loading, setLoading] = useState(false)

  // SELECTORS
  const { authData } = useAuth()

  // HOOKS
  const { t } = useTranslation()

  // VARIABLES
  const token = authData?.token
  const isWeb = Platform.OS === 'web'

  // FUNCTIONS
  const onImageSelected = async (images: ImageForUpload[]) => {
    if (!images) return
    const newPickedImages = await uploadImages(
      images,
      token || '',
      {
        fileSize: t('toast.uploadImageTooLargeError.description'),
        uploading: t('toast.uploadImageError.title'),
      },
      options,
    )
    const newFilesArray = [...pickedImages, ...newPickedImages]
    onChange(newFilesArray)
    setLoading(false)
  }

  const onDismissPickerOrCamera = () => {
    setLoading(false)
  }

  return (
    <>
      {!isWeb && (
        <ButtonPill
          onPress={() => {
            onTakePhotoButtonPress(
              options,
              onImageSelected,
              onDismissPickerOrCamera,
            )
            setLoading(true)
          }}
          mb={4}
        >
          {t('dreamscan.takePhotoBtnLabel')}
        </ButtonPill>
      )}
      <ButtonPill
        onPress={() => {
          onUploadButtonPress(options, onImageSelected, onDismissPickerOrCamera)
          setLoading(true)
        }}
      >
        {t('common.upload')}
      </ButtonPill>
      <ImageArrayEditable
        images={pickedImages}
        onChange={onChange}
        loading={loading}
      />
    </>
  )
}
