import React, { useState, useEffect } from 'react'
import { Tabs, TabProps } from '../../../components/layout/tab-view-tabs'
import { LoadingPage } from '../../../components/layout/loading-page'
import { useSelector } from '../../../ducks/root-reducer'
import { selectUser } from '../../../ducks/user/user'
import { useTranslation } from 'react-i18next'
import { idEquals } from '../../../ducks/helpers'
import { ListRenderItem, Platform } from 'react-native'
import { FEW } from '../../../i18n/config'
import { EmptyFeedPage } from '../../../components/composite/feed/page-empty-feed'
import { TagCharts } from '../../Charts/tag-charts'
import { DreamerViewHeader } from './header'
import {
  selectGroupById,
  selectPrivateGroupId,
  selectUserMembershipInGroup,
} from '../../../ducks/groups/groups'
import { ELSEWHERE_AI_USER_ID } from '../../../constants/constants'
import { Loading } from '../../../components/layout/loading'
import { InsightTab } from '../../Insights/InsightsMain/InsightTab'
import {
  FastDream,
  FastMembership,
  FastUser,
  UserRank,
  UserRankService,
} from '../../../../../api/_openapi'
import { selectSubscriptionStatus } from '../../../ducks/subscription/subscription'
import { capitalize } from '../../../modules/strings/string-helpers'
import {
  RootStackNavigationProp,
  RootStackParamList,
  RootStackScreenProps,
} from '../../../navigation/types'
import FeedItemDream from '../../../components/composite/feed/feed-item-dream'
import { RouteProp } from '@react-navigation/native'
import { CentredListEmptyComponent } from '../../../components/layout/centred-list-empty-component'
import { useActiveGroupId } from '../../../hooks/useActiveGroupId'
import { selectPublicUserById } from '../../../ducks/ui/ui'
import { useAuth } from '../../../contexts/auth-context'
import { Center } from '../../../components/common/center/center'
import { ScrollView } from '../../../components/common/scrollview/scrollview'

export type DreamerViewNavigationProp = RootStackNavigationProp<
  'PrivateDreamerView' | 'GroupDreamerView' | 'LibraryDreamerView'
>
export type DreamerViewRouteProp = RouteProp<
  RootStackParamList,
  'LibraryDreamerView' | 'PrivateDreamerView' | 'GroupDreamerView'
>
export default function DreamerView({
  navigation,
  route,
}: RootStackScreenProps<
  'PrivateDreamerView' | 'GroupDreamerView' | 'LibraryDreamerView'
>) {
  // ROUTE PARAMS
  const { userId } = route.params

  // STATE
  const [userRank, setUserRank] = useState<UserRank | null>(null)

  // SELECTORS
  const appUser = useSelector(selectUser)
  const subscriptionStatus = useSelector(selectSubscriptionStatus)
  const privateGroupId = useSelector(selectPrivateGroupId)

  // HOOKS
  const { t } = useTranslation()
  const { authData } = useAuth()

  // VARS
  const isElsewhereAI = idEquals(userId, ELSEWHERE_AI_USER_ID)
  const activeGroupId = useActiveGroupId()

  const currentGroup = useSelector(selectGroupById(activeGroupId))

  // Load user rank stats
  useEffect(() => {
    const anyUserId = userId || null
    if (anyUserId && authData?.token) {
      UserRankService.userRank(anyUserId)
        .then((userRank) => {
          setUserRank(userRank)
        })
        .catch((error) => {
          console.error('Error getting user rank:', error)
        })
    }
  }, [userId, authData])

  const normalUser = useSelector(
    selectUserMembershipInGroup(userId, activeGroupId),
  )
  const publicUser = useSelector(selectPublicUserById(userId))
  const {
    id,
    username: displayName,
    bio,
    createdAt,
    updatedAt,
    profileImageId: imageId,
    profileImageUrl: imageUrl,
    profileImageAlt: imageAlt,
  } = (publicUser as FastUser) || {}
  const publicUserToMembership: FastMembership = {
    id,
    displayName,
    bio,
    createdAt,
    updatedAt,
    imageId,
    imageUrl,
    imageAlt,
    userId: '',
    groupId: '',
    role: '',
    pronouns: '',
  }

  const translateMembership = (membership: FastMembership): FastMembership => {
    if (isElsewhereAI) {
      return {
        ...membership,
        bio: t('globalUser.' + membership.displayName + '.bio'),
        displayName: t('globalUser.' + membership.displayName + '.name'),
      }
    } else {
      return membership
    }
  }

  const membership =
    normalUser || (publicUser && translateMembership(publicUserToMembership))

  // RENDERERS
  const renderDreamItem: ListRenderItem<FastDream> = ({ item, index }) => {
    return appUser ? (
      <FeedItemDream
        route={route}
        dream={item}
        index={index}
        privateGroupId={privateGroupId}
        currentGroup={currentGroup}
        appUser={appUser}
        activeGroupId={activeGroupId || ''}
      />
    ) : null
  }

  // TAB CONTENTS
  const DreamTabContents = () => (
    <Tabs.FlatList
      data={[]}
      ListEmptyComponent={CentredListEmptyComponent(
        false ? (
          <Loading loadingLabel={t('loading.loadingGeneric')} />
        ) : !authData?.token ? (
          <EmptyFeedPage h="100%" />
        ) : null,
      )}
      renderItem={renderDreamItem}
      keyExtractor={(item) => item.id as string}
      contentContainerStyle={Platform.OS === 'web' ? { flex: 1 } : undefined}
    />
  )

  const ChartsTabContents = () =>
    false ? (
      <Center h="100%" mt={20}>
        <Loading loadingLabel={t('loading.loadingGeneric')} />
      </Center>
    ) : (
      <Tabs.ScrollView>
        {!authData?.token ? (
          <EmptyFeedPage />
        ) : (
          <TagCharts
            dateRangeWithKey={{
              key: 'ALL_TIME',
              range: {
                start: new Date('1500-01-01'),
                end: new Date(),
              },
            }}
          />
        )}
      </Tabs.ScrollView>
    )

  const InsightTabContents = () =>
    !authData?.token ? (
      <EmptyFeedPage />
    ) : (
      <InsightTab
        route={route}
        user={undefined}
        subscriptionStatus={subscriptionStatus}
        noInsightsMsg={t('insightsPage.emptyInsightsSymbol')}
      />
    )

  // TABS
  const tabs: TabProps<string>[] = [
    {
      name: 'Dreams',
      label: t('common.dream_plural', { count: FEW }),
      children: <DreamTabContents />,
    },
    {
      name: 'Charts',
      label: t('common.charts'),
      children: <ChartsTabContents />,
    },
    {
      name: 'Insights',
      label: capitalize(t('common.insight_plural', { count: FEW })),
      children: <InsightTabContents />,
    },
  ]

  // RENDER HEADER
  const HeaderComponent = () => (
    <DreamerViewHeader
      navigation={navigation}
      displayName={membership?.displayName || 'unknown'}
      pronouns={membership?.pronouns || ''}
      isUsersPrivateGroup={activeGroupId === appUser?.userGroupId}
      isLoggedInUser={idEquals(userId, appUser?.id)}
      isFamousUser={false}
      bio={membership?.bio}
      imageSrc={{ uri: membership?.imageUrl || undefined }}
      userRank={userRank}
    />
  )

  if (!membership) {
    return <LoadingPage />
  } else {
    return (
      <ScrollView padding={0}>
        <HeaderComponent />
      </ScrollView>
    )
  }
}
