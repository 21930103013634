// From https://github.com/GeorgeHop/react-native-country-codes-picker/blob/master/constants/countryCodes.ts

export interface CountryItem {
  name: { [key: string]: string }
  dial_code: string
  code: string
  flag: string
}

export const countryCodes: CountryItem[] = [
  {
    name: {
      en: 'Afghanistan',
      hu: 'Afganisztán',
      id: 'Afganistan',
      ru: 'Афганистан',
      pl: 'Afganistan',
      uk: 'Афганістан',
      cz: 'Afghánistán',
      by: 'Афганістан',
      pt: 'Afeganistão',
      es: 'Afganistán',
      ro: 'Afganistan',
      bg: 'Афганистан',
      de: 'Afghanistan',
      fr: "L'Afghanistan",
      nl: 'Afghanistan',
      it: 'Afghanistan',
      cn: '阿富汗',
      ee: 'Afganistan',
      ja: 'アフガニスタン',
      da: 'Afghanistan',
      fa: 'افغانستان',
    },
    dial_code: '+93',
    code: 'AF',
    flag: '🇦🇫',
  },
  {
    name: {
      en: 'Albania',
      hu: 'Albánia',
      id: 'Albania',
      ru: 'Албания',
      pl: 'Albania',
      uk: 'Албанія',
      cz: 'Albánie',
      by: 'Албанія',
      pt: 'Albânia',
      es: 'Albania',
      ro: 'Albania',
      bg: 'Албания',
      de: 'Albanien',
      fr: 'Albanie',
      nl: 'Albanië',
      it: 'Albania',
      cn: '阿尔巴尼亚',
      ee: 'Albaania',
      ja: 'アルバニア',
      da: 'Albanien',
      fa: 'آلبانی',
    },
    dial_code: '+355',
    code: 'AL',
    flag: '🇦🇱',
  },
  {
    name: {
      en: 'Algeria',
      hu: 'Algéria',
      id: 'Algeria',
      ru: 'Алжир',
      pl: 'Algieria',
      uk: 'Алжир',
      cz: 'Alžírsko',
      by: 'Алжыр',
      pt: 'Argélia',
      es: 'Argelia',
      ro: 'Algeria',
      bg: 'Алжир',
      de: 'Algerien',
      fr: 'Algérie',
      nl: 'Algerije',
      it: 'Algeria',
      cn: '阿尔及利亚',
      ee: 'Alžeeria',
      ja: 'アルジェリア',
      da: 'Algeriet',
      fa: 'الجزایر',
    },
    dial_code: '+213',
    code: 'DZ',
    flag: '🇩🇿',
  },
  {
    name: {
      en: 'American Samoa',
      hu: 'Amerikai Szamoa',
      id: 'Samoa Amerika',
      ru: 'Американское Самоа',
      pl: 'Samoa Amerykańskie',
      uk: 'Американське Самоа',
      cz: 'Americká Samoa',
      by: 'Амерыканскае Самоа',
      pt: 'Samoa',
      es: 'Samoa Americana',
      ro: 'Samoa Americană',
      bg: 'Американска Самоа',
      de: 'Amerikanischen Samoa-Inseln',
      fr: 'Samoa américaines',
      nl: 'Amerikaans Samoa',
      it: 'Samoa americane',
      cn: '美属萨摩亚',
      ee: 'Ameerika Samoa',
      ja: 'アメリカ領サモア',
      da: 'Amerikansk Samoa',
      fa: 'ساموآی آمریکا',
    },
    dial_code: '+1684',
    code: 'AS',
    flag: '🇦🇸',
  },
  {
    name: {
      en: 'Andorra',
      hu: 'Andorra',
      id: 'Andorra',
      ru: 'Андорра',
      pl: 'Andora',
      uk: 'Андорра',
      cz: 'Andorra',
      by: 'Андора',
      pt: 'Andorra',
      es: 'Andorra',
      ro: 'Andorra',
      bg: 'Андора',
      de: 'Andorra',
      fr: 'Andorre',
      nl: 'Andorra',
      it: 'Andorra',
      cn: '安道尔',
      ee: 'Andorra',
      ja: 'アンドラ',
      da: 'Andorra',
      fa: 'آندورا',
    },
    dial_code: '+376',
    code: 'AD',
    flag: '🇦🇩',
  },
  {
    name: {
      en: 'Angola',
      hu: 'Angola',
      id: 'Angola',
      ru: 'Ангола',
      pl: 'Angola',
      uk: 'Ангола',
      cz: 'Angola',
      by: 'Ангола',
      pt: 'Angola',
      es: 'Angola',
      ro: 'Angola',
      bg: 'Ангола',
      de: 'Angola',
      fr: "L'Angola",
      nl: 'Angola',
      it: 'Angola',
      cn: '安哥拉',
      ee: 'Angola',
      ja: 'アンゴラ',
      da: 'Angola',
      fa: 'آنگولا',
    },
    dial_code: '+244',
    code: 'AO',
    flag: '🇦🇴',
  },
  {
    name: {
      en: 'Anguilla',
      hu: 'Anguilla',
      id: 'Anguilla',
      ru: 'Ангилья',
      pl: 'Anguilla',
      uk: 'Ангілья',
      cz: 'Anguilla',
      by: 'Ангілья',
      pt: 'Anguila',
      es: 'Anguila',
      ro: 'Anguilla',
      bg: 'Ангила',
      de: 'Anguilla',
      fr: 'Anguilla',
      nl: 'Anguilla',
      it: 'Anguilla',
      cn: '安圭拉岛',
      ee: 'Anguilla',
      ja: 'アンギラ',
      da: 'Anguilla',
      fa: 'آنگویلا',
    },
    dial_code: '+1264',
    code: 'AI',
    flag: '🇦🇮',
  },
  {
    name: {
      en: 'Antarctica',
      hu: 'Antarktisz',
      id: 'Antartika',
      ru: 'Антарктида',
      pl: 'Antarktyda',
      uk: 'Антарктида',
      cz: 'Antarktida',
      by: 'Антарктыда',
      pt: 'Antarctica',
      es: 'Antártida',
      ro: 'Antarctica',
      bg: 'Антарктида',
      de: 'Antarktis',
      fr: 'Antarctique',
      nl: 'Antarctica',
      it: 'Antartide',
      cn: '南极洲',
      ee: 'Antarktika',
      ja: '南極大陸',
      da: 'Antarktis',
      fa: 'جنوبگان',
    },
    dial_code: '+672',
    code: 'AQ',
    flag: '🇦🇶',
  },
  {
    name: {
      en: 'Antigua and Barbuda',
      hu: 'Antigua és Barbuda',
      id: 'Antigua dan Barbuda',
      ru: 'Антигуа и Барбуда',
      pl: 'Antigua i Barbuda',
      uk: 'Антигуа та Барбуда',
      cz: 'Antigua a Barbuda',
      by: 'Антыгуа і Барбуда',
      pt: 'Antiga e Barbuda',
      es: 'Antigua y Barbuda',
      ro: 'Antigua si Barbuda',
      bg: 'Антигуа и Барбуда',
      de: 'Antigua und Barbuda',
      fr: 'Antigua-et-Barbuda',
      nl: 'Antigua en Barbuda',
      it: 'Antigua e Barbuda',
      cn: '安提瓜和巴布达',
      ee: 'Antigua ja Barbuda',
      ja: 'アンティグアバーブーダ',
      da: 'Antigua og Barbuda',
      fa: 'آنتیگوا و باربودا',
    },
    dial_code: '+1268',
    code: 'AG',
    flag: '🇦🇬',
  },
  {
    name: {
      en: 'Argentina',
      hu: 'Argentína',
      id: 'Argentina',
      ru: 'Аргентина',
      pl: 'Argentyna',
      uk: 'Аргентина',
      cz: 'Argentina',
      by: 'Аргентына',
      pt: 'Argentina',
      es: 'Argentina',
      ro: 'Argentina',
      bg: 'Аржентина',
      de: 'Argentinien',
      fr: 'Argentine',
      nl: 'Argentinië',
      it: 'Argentina',
      cn: '阿根廷',
      ee: 'Argentina',
      ja: 'アルゼンチン',
      da: 'Argentina',
      fa: 'آرژانتین',
    },
    dial_code: '+54',
    code: 'AR',
    flag: '🇦🇷',
  },
  {
    name: {
      en: 'Armenia',
      hu: 'Örményország',
      id: 'Armenia',
      ru: 'Армения',
      pl: 'Armenia',
      uk: 'Вірменія',
      cz: 'Arménie',
      by: 'Арменія',
      pt: 'Arménia',
      es: 'Armenia',
      ro: 'Armenia',
      bg: 'Армения',
      de: 'Armenien',
      fr: 'Arménie',
      nl: 'Armenië',
      it: 'Armenia',
      cn: '亚美尼亚',
      ee: 'Armeenia',
      ja: 'アルメニア',
      da: 'Armenien',
      fa: 'ارمنستان',
    },
    dial_code: '+374',
    code: 'AM',
    flag: '🇦🇲',
  },
  {
    name: {
      en: 'Aruba',
      hu: 'Aruba',
      id: 'Aruba',
      ru: 'Аруба',
      pl: 'Aruba',
      uk: 'Аруба',
      cz: 'Aruba',
      by: 'Аруба',
      pt: 'Aruba',
      es: 'Aruba',
      ro: 'Aruba',
      bg: 'Аруба',
      de: 'Aruba',
      fr: 'Aruba',
      nl: 'Aruba',
      it: 'Aruba',
      cn: '阿鲁巴岛',
      ee: 'Aruba',
      ja: 'アルバ',
      da: 'Aruba',
      fa: 'آروبا',
    },
    dial_code: '+297',
    code: 'AW',
    flag: '🇦🇼',
  },
  {
    name: {
      en: 'Australia',
      hu: 'Ausztrália',
      id: 'Australia',
      ru: 'Австралия',
      pl: 'Australia',
      uk: 'Австралія',
      cz: 'Austrálie',
      by: 'Аўстралія',
      pt: 'Austrália',
      es: 'Australia',
      ro: 'Australia',
      bg: 'Австралия',
      de: 'Australien',
      fr: 'Australie',
      nl: 'Australië',
      it: 'Australia',
      cn: '澳大利亚',
      ee: 'Austraalia',
      ja: 'オーストラリア',
      da: 'Australien',
      fa: 'استرالیا',
    },
    dial_code: '+61',
    code: 'AU',
    flag: '🇦🇺',
  },
  {
    name: {
      en: 'Austria',
      hu: 'Ausztria',
      id: 'Austria',
      ru: 'Австрия',
      pl: 'Austria',
      uk: 'Австрія',
      cz: 'Rakousko',
      by: 'Аўстрыя',
      pt: 'Áustria',
      es: 'Austria',
      ro: 'Austria',
      bg: 'Австрия',
      de: 'Österreich',
      fr: "L'Autriche",
      nl: 'Oostenrijk',
      it: 'Austria',
      cn: '奥地利',
      ee: 'Austria',
      ja: 'オーストリア',
      da: 'Østrig',
      fa: 'اتریش',
    },
    dial_code: '+43',
    code: 'AT',
    flag: '🇦🇹',
  },
  {
    name: {
      en: 'Azerbaijan',
      hu: 'Azerbajdzsán',
      id: 'Azerbaijan',
      ru: 'Азербайджан',
      pl: 'Azerbejdżan',
      uk: 'Азербайджан',
      cz: 'Ázerbajdžán',
      by: 'Азербайджан',
      pt: 'Azerbaijão',
      es: 'Azerbaiyán',
      ro: 'Azerbaidjan',
      bg: 'Азербайджан',
      de: 'Aserbaidschan',
      fr: 'Azerbaïdjan',
      nl: 'Azerbeidzjan',
      it: 'Azerbaigian',
      cn: '阿塞拜疆',
      ee: 'Aserbaidžaan',
      ja: 'アゼルバイジャン',
      da: 'Aserbajdsjan',
      fa: 'آذربایجان',
    },
    dial_code: '+994',
    code: 'AZ',
    flag: '🇦🇿',
  },
  {
    name: {
      en: 'Bahamas',
      hu: 'Bahama-szigetek',
      id: 'Bahama',
      ru: 'Багамские Острова',
      pl: 'Bahamy',
      uk: 'Багамські острови',
      cz: 'Bahamy',
      by: 'Багамскія астравы',
      pt: 'Baamas',
      es: 'Bahamas',
      ro: 'Bahamas',
      bg: 'Бахамски острови',
      de: 'Bahamas',
      fr: 'Bahamas',
      nl: 'Bahamas',
      it: 'Bahamas',
      cn: '巴哈马',
      ee: 'Bahama',
      ja: 'バハマ',
      da: 'Bahamas',
      fa: 'باهاما',
    },
    dial_code: '+1242',
    code: 'BS',
    flag: '🇧🇸',
  },
  {
    name: {
      en: 'Bahrain',
      hu: 'Bahrein',
      id: 'Bahrain',
      ru: 'Бахрейн',
      pl: 'Bahrajn',
      uk: 'Бахрейн',
      cz: 'Bahrajn',
      by: 'Бахрэйн',
      pt: 'Barém',
      es: 'Bahrein',
      ro: 'Bahrain',
      bg: 'Бахрейн',
      de: 'Bahrain',
      fr: 'Bahreïn',
      nl: 'Bahrein',
      it: 'Bahrein',
      cn: '巴林',
      ee: 'Bahrein',
      ja: 'バーレーン',
      da: 'Bahrain',
      fa: 'بحرین',
    },
    dial_code: '+973',
    code: 'BH',
    flag: '🇧🇭',
  },
  {
    name: {
      en: 'Bangladesh',
      hu: 'Banglades',
      id: 'Bangladesh',
      ru: 'Бангладеш',
      pl: 'Bangladesz',
      uk: 'Бангладеш',
      cz: 'Bangladéš',
      by: 'Бангладэш',
      pt: 'Bangladeche',
      es: 'Bangladesh',
      ro: 'Bangladesh',
      bg: 'Бангладеш',
      de: 'Bangladesch',
      fr: 'Bangladesh',
      nl: 'Bangladesh',
      it: 'Bangladesh',
      cn: '孟加拉国',
      ee: 'Bangladesh',
      ja: 'バングラデシュ',
      da: 'Bangladesh',
      fa: 'بنگلادش',
    },
    dial_code: '+880',
    code: 'BD',
    flag: '🇧🇩',
  },
  {
    name: {
      en: 'Barbados',
      hu: 'Barbados',
      id: 'Barbados',
      ru: 'Барбадос',
      pl: 'Barbados',
      uk: 'Барбадос',
      cz: 'Barbados',
      by: 'Барбадас',
      pt: 'Barbados',
      es: 'Barbados',
      ro: 'Barbados',
      bg: 'Барбадос',
      de: 'Barbados',
      fr: 'Barbade',
      nl: 'Barbados',
      it: 'Barbados',
      cn: '巴巴多斯',
      ee: 'Barbados',
      ja: 'バルバドス',
      da: 'Barbados',
      fa: 'باربادوس',
    },
    dial_code: '+1246',
    code: 'BB',
    flag: '🇧🇧',
  },
  {
    name: {
      en: 'Belarus',
      hu: 'Fehéroroszország',
      id: 'Belarus',
      ru: 'Белоруссия',
      pl: 'Białoruś',
      uk: 'Білорусь',
      cz: 'Bělorusko',
      by: 'Беларусь',
      pt: 'Bielorrússia',
      es: 'Bielorrusia',
      ro: 'Bielorusia',
      bg: 'Беларус',
      de: 'Weißrussland',
      fr: 'Biélorussie',
      nl: 'Wit-Rusland',
      it: 'Bielorussia',
      cn: '白俄罗斯',
      ee: 'Valgevene',
      ja: 'ベラルーシ',
      da: 'Hviderusland',
      fa: 'بلاروس',
    },
    dial_code: '+375',
    code: 'BY',
    flag: '🇧🇾',
  },
  {
    name: {
      en: 'Belgium',
      hu: 'Belgium',
      id: 'Belgia',
      ru: 'Бельгия',
      pl: 'Belgia',
      uk: 'Бельгія',
      cz: 'Belgie',
      by: 'Бельгія',
      pt: 'Bélgica',
      es: 'Bélgica',
      ro: 'Belgia',
      bg: 'Белгия',
      de: 'Belgien',
      fr: 'Belgique',
      nl: 'Belgie',
      it: 'Belgio',
      cn: '比利时',
      ee: 'Belgia',
      ja: 'ベルギー',
      da: 'Belgien',
      fa: 'بلژیک',
    },
    dial_code: '+32',
    code: 'BE',
    flag: '🇧🇪',
  },
  {
    name: {
      en: 'Belize',
      hu: 'Belize',
      id: 'Belize',
      ru: 'Белиз',
      pl: 'Belize',
      uk: 'Беліз',
      cz: 'Belize',
      by: 'Беліз',
      pt: 'Belize',
      es: 'Belice',
      ro: 'Belize',
      bg: 'Белиз',
      de: 'Belize',
      fr: 'Belize',
      nl: 'Belize',
      it: 'Belize',
      cn: '伯利兹',
      ee: 'Belize',
      ja: 'ベリーズ',
      da: 'Belize',
      fa: 'بلیز',
    },
    dial_code: '+501',
    code: 'BZ',
    flag: '🇧🇿',
  },
  {
    name: {
      en: 'Benin',
      hu: 'Benin',
      id: 'Benin',
      ru: 'Бенин',
      pl: 'Benin',
      uk: 'Бенін',
      cz: 'Benin',
      by: 'Бенін',
      pt: 'Benim',
      es: 'Benín',
      ro: 'Benin',
      bg: 'Бенин',
      de: 'Benin',
      fr: 'Bénin',
      nl: 'Benin',
      it: 'Benin',
      cn: '贝宁',
      ee: 'Benin',
      ja: 'ベナン',
      da: 'Benin',
      fa: 'بنین',
    },
    dial_code: '+229',
    code: 'BJ',
    flag: '🇧🇯',
  },
  {
    name: {
      en: 'Bermuda',
      hu: 'Bermuda',
      id: 'Bermuda',
      ru: 'Бермуды',
      pl: 'Bermudy',
      uk: 'Бермуди',
      cz: 'Bermudy',
      by: 'Бярмуды',
      pt: 'Bermudas',
      es: 'Bermudas',
      ro: 'Bermuda',
      bg: 'Бермуди',
      de: 'Bermuda',
      fr: 'Bermudes',
      nl: 'Bermuda',
      it: 'Bermuda',
      cn: '百慕大',
      ee: 'Bermuda',
      ja: 'バミューダ',
      da: 'Bermuda',
      fa: 'برمودا',
    },
    dial_code: '+1441',
    code: 'BM',
    flag: '🇧🇲',
  },
  {
    name: {
      en: 'Bhutan',
      hu: 'Bhután',
      id: 'Bhutan',
      ru: 'Бутан',
      pl: 'Bhutan',
      uk: 'Бутан',
      cz: 'Bhútán',
      by: 'Бутан',
      pt: 'Butão',
      es: 'Bután',
      ro: 'Bhutan',
      bg: 'Бутан',
      de: 'Bhutan',
      fr: 'Bhoutan',
      nl: 'Bhutan',
      it: 'Bhutan',
      cn: '不丹',
      ee: 'Bhutan',
      ja: 'ブータン',
      da: 'Bhutan',
      fa: 'بوتان',
    },
    dial_code: '+975',
    code: 'BT',
    flag: '🇧🇹',
  },
  {
    name: {
      en: 'Bolivia',
      hu: 'Bolívia',
      id: 'Bolivia',
      ru: 'Боливия',
      pl: 'Boliwia',
      uk: 'Болівія',
      cz: 'Bolívie',
      by: 'Балівія',
      pt: 'Bolívia',
      es: 'Bolivia',
      ro: 'Bolivia',
      bg: 'Боливия',
      de: 'Bolivien',
      fr: 'Bolivie',
      nl: 'Bolivia',
      it: 'Bolivia',
      cn: '玻利维亚',
      ee: 'Boliivia',
      ja: 'ボリビア',
      da: 'Bolivia',
      fa: 'بولیوی',
    },
    dial_code: '+591',
    code: 'BO',
    flag: '🇧🇴',
  },
  {
    name: {
      en: 'Bosnia and Herzegovina',
      hu: 'Bosznia-Hercegovina',
      id: 'Bosnia dan Herzegovina',
      ru: 'Босния и Герцеговина',
      pl: 'Bośnia i Hercegowina',
      uk: 'Боснія і Герцеговина',
      cz: 'Bosna a Hercegovina',
      by: 'Боснія і Герцагавіна',
      pt: 'Bósnia e Herzegovina',
      es: 'Bosnia y Herzegovina',
      ro: 'Bosnia si Hertegovina',
      bg: 'Босна и Херцеговина',
      de: 'Bosnien und Herzegowina',
      fr: 'Bosnie Herzégovine',
      nl: 'Bosnië-Herzegovina',
      it: 'Bosnia Erzegovina',
      cn: '波斯尼亚和黑塞哥维那（简称：波黑',
      ee: 'Bosnia ja Hertsegoviina',
      ja: 'ボスニア・ヘルツェゴビナ',
      da: 'Bosnien-Herzegowina',
      fa: 'بوسنی و هرزگوین',
    },
    dial_code: '+387',
    code: 'BA',
    flag: '🇧🇦',
  },
  {
    name: {
      en: 'Botswana',
      hu: 'Botswana',
      id: 'Botswana',
      ru: 'Ботсвана',
      pl: 'Botswana',
      uk: 'Ботсвана',
      cz: 'Botswana',
      by: 'Батсвана',
      pt: 'Botsuana',
      es: 'Botsuana',
      ro: 'Botswana',
      bg: 'Ботсвана',
      de: 'Botswana',
      fr: 'Botswana',
      nl: 'Botswana',
      it: 'Botswana',
      cn: '博茨瓦纳',
      ee: 'Botswana',
      ja: 'ボツワナ',
      da: 'Botswana',
      fa: 'بوتسوانا',
    },
    dial_code: '+267',
    code: 'BW',
    flag: '🇧🇼',
  },
  {
    name: {
      en: 'Brazil',
      hu: 'Brazília',
      id: 'Brasil',
      ru: 'Бразилия',
      pl: 'Brazylia',
      uk: 'Бразилія',
      cz: 'Brazílie',
      by: 'Бразілія',
      pt: 'Brasil',
      es: 'Brasil',
      ro: 'Brazilia',
      bg: 'Бразилия',
      de: 'Brasilien',
      fr: 'Brésil',
      nl: 'Brazilië',
      it: 'Brasile',
      cn: '巴西',
      ee: 'Brasiilia',
      ja: 'ブラジル',
      da: 'Brasilien',
      fa: 'برزیل',
    },
    dial_code: '+55',
    code: 'BR',
    flag: '🇧🇷',
  },
  {
    name: {
      en: 'British Indian Ocean Territory',
      hu: 'Brit Indiai-óceáni Terület',
      id: 'Wilayah Samudra Hindia Britania',
      ru: 'Британская территория Индийского океана',
      pl: 'Brytyjskie Terytorium Oceanu Indyjskiego',
      uk: 'Британська територія в Індійському океані',
      cz: 'Britské území v Indickém oceánu',
      by: 'Брытанская тэрыторыя ў Індыйскім акіяне',
      pt: 'Território Britânico do Oceano Índico',
      es: 'Territorio Británico del Océano Índico',
      ro: 'Teritoriul Britanic al Oceanului Indian',
      bg: 'Британска територия в Индийския океан',
      de: 'Britisches Territorium des Indischen Ozeans',
      fr: "Territoire britannique de l'océan Indien",
      nl: 'Brits-Indisch oceaan gebied',
      it: "Territorio britannico dell'Oceano Indiano",
      cn: '英属印度洋领地',
      ee: 'Briti India ookeani territoorium',
      ja: 'イギリス領インド洋地域',
      da: 'Det britiske territorium i det Indiske Ocean',
      fa: 'قلمرو بریتانیایی اقیانوس هند',
    },
    dial_code: '+246',
    code: 'IO',
    flag: '🇮🇴',
  },
  {
    name: {
      en: 'Brunei Darussalam',
      hu: 'Brunei',
      id: 'Brunei Darussalam',
      ru: 'Бруней-Даруссалам',
      pl: 'Brunei Darussalam',
      uk: 'Бруней-Даруссалам',
      cz: 'Brunej Darussalam',
      by: 'Бруней-Дарусалам',
      pt: 'Brunei',
      es: 'Brunei Darussalam',
      ro: 'Brunei Darussalam',
      bg: 'Бруней Дарусалам',
      de: 'Brunei Darussalam',
      fr: 'Brunei Darussalam',
      nl: 'Brunei Darussalam',
      it: 'Brunei Darussalam',
      cn: '文莱达鲁萨兰国',
      ee: 'Brunei Darussalam',
      ja: 'ブルネイダルサラーム',
      da: 'Brunei Darussalam',
      fa: 'برونئی دارالسلام',
    },
    dial_code: '+673',
    code: 'BN',
    flag: '🇧🇳',
  },
  {
    name: {
      en: 'Bulgaria',
      hu: 'Bulgária',
      id: 'Bulgaria',
      ru: 'Болгария',
      pl: 'Bułgaria',
      uk: 'Болгарія',
      cz: 'Bulharsko',
      by: 'Балгарыя',
      pt: 'Bulgária',
      es: 'Bulgaria',
      ro: 'Bulgaria',
      bg: 'България',
      de: 'Bulgarien',
      fr: 'Bulgarie',
      nl: 'Bulgarije',
      it: 'Bulgaria',
      cn: '保加利亚',
      ee: 'Bulgaaria',
      ja: 'ブルガリア',
      da: 'Bulgarien',
      fa: 'بلغارستان',
    },
    dial_code: '+359',
    code: 'BG',
    flag: '🇧🇬',
  },
  {
    name: {
      en: 'Burkina Faso',
      hu: 'Burkina Faso',
      id: 'Burkina Faso',
      ru: 'Буркина-Фасо',
      pl: 'Burkina Faso',
      uk: 'Буркіна-Фасо',
      cz: 'Burkina Faso',
      by: 'Буркіна-Фасо',
      pt: 'Burquina Faso',
      es: 'Burkina Faso',
      ro: 'Burkina Faso',
      bg: 'Буркина Фасо',
      de: 'Burkina Faso',
      fr: 'Burkina Faso',
      nl: 'Burkina Faso',
      it: 'Burkina Faso',
      cn: '布基纳法索',
      ee: 'Burkina Faso',
      ja: 'ブルキナファソ',
      da: 'Burkina Faso',
      fa: 'بورکینافاسو',
    },
    dial_code: '+226',
    code: 'BF',
    flag: '🇧🇫',
  },
  {
    name: {
      en: 'Burundi',
      hu: 'Burundi',
      id: 'Burundi',
      ru: 'Бурунди',
      pl: 'Burundi',
      uk: 'Бурунді',
      cz: 'Burundi',
      by: 'Бурундзі',
      pt: 'Burúndi',
      es: 'Burundi',
      ro: 'Burundi',
      bg: 'Бурунди',
      de: 'Burundi',
      fr: 'Burundi',
      nl: 'Burundi',
      it: 'Burundi',
      cn: '布隆迪',
      ee: 'Burundi',
      ja: 'ブルンジ',
      da: 'Burundi',
      fa: 'بوروندی',
    },
    dial_code: '+257',
    code: 'BI',
    flag: '🇧🇮',
  },
  {
    name: {
      en: 'Cambodia',
      hu: 'Kambodzsa',
      id: 'Kamboja',
      ru: 'Камбоджа',
      pl: 'Kambodża',
      uk: 'Камбоджа',
      cz: 'Kambodža',
      by: 'Камбоджа',
      pt: 'Camboja',
      es: 'Camboya',
      ro: 'Cambodgia',
      bg: 'Камбоджа',
      de: 'Kambodscha',
      fr: 'Cambodge',
      nl: 'Cambodja',
      it: 'Cambogia',
      cn: '柬埔寨',
      ee: 'Kambodža',
      ja: 'カンボジア',
      da: 'Cambodja',
      fa: 'کامبوج',
    },
    dial_code: '+855',
    code: 'KH',
    flag: '🇰🇭',
  },
  {
    name: {
      en: 'Cameroon',
      hu: 'Kamerun',
      id: 'Kamerun',
      ru: 'Камерун',
      pl: 'Kamerun',
      uk: 'Камерун',
      cz: 'Kamerun',
      by: 'Камерун',
      pt: 'Camarões',
      es: 'Camerún',
      ro: 'Camerun',
      bg: 'Камерун',
      de: 'Kamerun',
      fr: 'Cameroun',
      nl: 'Kameroen',
      it: 'Camerun',
      cn: '喀麦隆',
      ee: 'Kamerun',
      ja: 'カメルーン',
      da: 'Cameroun',
      fa: 'کامرون',
    },
    dial_code: '+237',
    code: 'CM',
    flag: '🇨🇲',
  },
  {
    name: {
      en: 'Canada',
      hu: 'Kanada',
      id: 'Kanada',
      ru: 'Канада',
      pl: 'Kanada',
      uk: 'Канада',
      cz: 'Kanada',
      by: 'Канада',
      pt: 'Canadá',
      es: 'Canadá',
      ro: 'Canada',
      bg: 'Канада',
      de: 'Kanada',
      fr: 'Canada',
      nl: 'Canada',
      it: 'Canada',
      cn: '加拿大',
      ee: 'Kanada',
      ja: 'カナダ',
      da: 'Canada',
      fa: 'کانادا',
    },
    dial_code: '+1',
    code: 'CA',
    flag: '🇨🇦',
  },
  {
    name: {
      en: 'Cape Verde',
      hu: 'Zöld-foki Köztársaság',
      id: 'Tanjung Verde',
      ru: 'Кабо-Верде',
      pl: 'Wyspy Zielonego Przylądka',
      uk: 'Кабо-Верде',
      cz: 'Kapverdy',
      by: 'Каба-Вэрдэ',
      pt: 'Cabo Verde',
      es: 'Cabo Verde',
      ro: 'capul Verde',
      bg: 'Кабо Верде',
      de: 'Kap Verde',
      fr: 'Cap-Vert',
      nl: 'Kaapverdië',
      it: 'capo Verde',
      cn: '佛得角',
      ee: 'Roheneemesaared',
      ja: 'カーボベルデ',
      da: 'Kap Verde',
      fa: 'کیپ ورد',
    },
    dial_code: '+238',
    code: 'CV',
    flag: '🇨🇻',
  },
  {
    name: {
      en: 'Cayman Islands',
      hu: 'Kajmán-szigetek',
      id: 'Kepulauan Cayman',
      ru: 'Каймановы острова',
      pl: 'Kajmany',
      uk: 'Кайманові острови',
      cz: 'Kajmanské ostrovy',
      by: 'Кайманавы выспы',
      pt: 'Ilhas Caimão',
      es: 'Islas Caimán',
      ro: 'Insulele Cayman',
      bg: 'Кайманови острови',
      de: 'Cayman Inseln',
      fr: 'Îles Caïmans',
      nl: 'Kaaiman Eilanden',
      it: 'Isole Cayman',
      cn: '开曼群岛',
      ee: 'Kaimani saared',
      ja: 'ケイマン諸島',
      da: 'Caymanøerne',
      fa: 'جزایر کیمن',
    },
    dial_code: '+345',
    code: 'KY',
    flag: '🇰🇾',
  },
  {
    name: {
      en: 'Central African Republic',
      hu: 'Közép-afrikai Köztársaság',
      id: 'Republik Afrika Tengah',
      ru: 'Центрально-Африканская Республика',
      pl: 'Republika Środkowoafrykańska',
      uk: 'Центральноафриканська Республіка',
      cz: 'Středoafrická republika',
      by: 'Цэнтральна-Афрыканская Рэспубліка',
      pt: 'República Centro-Africana',
      es: 'República Centroafricana',
      ro: 'Republica Centrafricană',
      bg: 'Централноафриканска република',
      de: 'Zentralafrikanische Republik',
      fr: 'République centrafricaine',
      nl: 'Centraal Afrikaanse Republiek',
      it: 'Repubblica Centrafricana',
      cn: '中非共和国',
      ee: 'Kesk-Aafrika Vabariik',
      ja: '中央アフリカ共和国',
      da: 'Den Centralafrikanske Republik',
      fa: 'جمهوری آفریقای مرکزی',
    },
    dial_code: '+236',
    code: 'CF',
    flag: '🇨🇫',
  },
  {
    name: {
      en: 'Chad',
      hu: 'Csád',
      id: 'Cad',
      ru: 'Чад',
      pl: 'Czad',
      uk: 'Чад',
      cz: 'Čad',
      by: 'Чад',
      pt: 'Chade',
      es: 'Chad',
      ro: 'Ciad',
      bg: 'Чад',
      de: 'Tschad',
      fr: 'Tchad',
      nl: 'Tsjaad',
      it: 'Chad',
      cn: '乍得',
      ee: 'Tšaad',
      ja: 'チャド',
      da: 'Tchad',
      fa: 'چاد',
    },
    dial_code: '+235',
    code: 'TD',
    flag: '🇹🇩',
  },
  {
    name: {
      en: 'Chile',
      hu: 'Chile',
      id: 'Cile',
      ru: 'Чили',
      pl: 'Chile',
      uk: 'Чилі',
      cz: 'Chile',
      by: 'Чылі',
      pt: 'Chile',
      es: 'Chile',
      ro: 'Chile',
      bg: 'Чили',
      de: 'Chile',
      fr: 'Chili',
      nl: 'Chili',
      it: 'Chile',
      cn: '智利',
      ee: 'Tšiili',
      ja: 'チリ',
      da: 'Chile',
      fa: 'شیلی',
    },
    dial_code: '+56',
    code: 'CL',
    flag: '🇨🇱',
  },
  {
    name: {
      en: 'China',
      hu: 'Kína',
      id: 'Cina',
      ru: 'Китай',
      pl: 'Chiny',
      uk: 'Китай',
      cz: 'Čína',
      by: 'Кітай',
      pt: 'China',
      es: 'China',
      ro: 'China',
      bg: 'Китай',
      de: 'China',
      fr: 'Chine',
      nl: 'China',
      it: 'Cina',
      cn: '中国',
      ee: 'Hiina',
      ja: '中国',
      da: 'Kina',
      fa: 'چین',
    },
    dial_code: '+86',
    code: 'CN',
    flag: '🇨🇳',
  },
  {
    name: {
      en: 'Christmas Island',
      hu: 'Karácsony-sziget',
      id: 'Pulau Christmas',
      ru: 'Остров Рождества',
      pl: 'Wyspa Bożego Narodzenia',
      uk: 'Острів Різдва',
      cz: 'Vánoční ostrov',
      by: 'Востраў Каляд',
      pt: 'Ilha do Natal',
      es: 'Isla de Navidad',
      ro: 'Insula Craciunului',
      bg: 'Коледен остров',
      de: 'Weihnachtsinsel',
      fr: "L'île de noël",
      nl: 'Kersteiland',
      it: 'Isola di Natale',
      cn: '圣诞岛',
      ee: 'Jõulusaar',
      ja: 'クリスマス島',
      da: 'Juleøen',
      fa: 'جزیره کریسمس',
    },
    dial_code: '+61',
    code: 'CX',
    flag: '🇨🇽',
  },
  {
    name: {
      en: 'Cocos (Keeling) Islands',
      hu: 'Kókusz-szigetek',
      id: 'Kepulauan Cocos (Keeling)',
      ru: 'Кокосовые (Килинг) острова',
      pl: 'Wyspy Kokosowe (Keelinga)',
      uk: 'Кокосові (Кілінг) острови',
      cz: 'Kokosové (Keelingovy) ostrovy',
      by: 'Какосавыя (Кілінскія) астравы',
      pt: 'Ilhas dos Cocos',
      es: 'Islas Cocos (Keeling)',
      ro: 'Insulele Cocos (Keeling)',
      bg: 'Кокосови (Кийлинг) острови',
      de: 'Kokosinseln (Keelinginseln)',
      fr: 'Îles Cocos (Keeling)',
      nl: 'Cocos (Keeling) eilanden',
      it: 'Isole Cocos (Keeling)',
      cn: '科科斯（基林）群岛',
      ee: 'Kookossaared (Keelingi) saared',
      ja: 'ココス（キーリング）諸島',
      da: 'Cocosøerne (Keelingøerne)',
      fa: 'جزایر کوکوس (کیلینگ)',
    },
    dial_code: '+61',
    code: 'CC',
    flag: '🇨🇨',
  },
  {
    name: {
      en: 'Colombia',
      hu: 'Kolumbia',
      id: 'Kolombia',
      ru: 'Колумбия',
      pl: 'Kolumbia',
      uk: 'Колумбія',
      cz: 'Kolumbie',
      by: 'Калумбія',
      pt: 'Colômbia',
      es: 'Colombia',
      ro: 'Columbia',
      bg: 'Колумбия',
      de: 'Kolumbien',
      fr: 'Colombie',
      nl: 'Colombia',
      it: 'Colombia',
      cn: '哥伦比亚',
      ee: 'Kolumbia',
      ja: 'コロンビア',
      da: 'Colombia',
      fa: 'کلمبیا',
    },
    dial_code: '+57',
    code: 'CO',
    flag: '🇨🇴',
  },
  {
    name: {
      en: 'Comoros',
      hu: 'Comore-szigetek',
      id: 'Komoro',
      ru: 'Коморские острова',
      pl: 'Komory',
      uk: 'Коморські острови',
      cz: 'Komory',
      by: 'Каморскія выспы',
      pt: 'Comores',
      es: 'Comoras',
      ro: 'Comore',
      bg: 'Коморски острови',
      de: 'Komoren',
      fr: 'Comores',
      nl: 'Comoren',
      it: 'Comore',
      cn: '科摩罗',
      ee: 'Komoorid',
      ja: 'コモロ',
      da: 'Comorerne',
      fa: 'کومور',
    },
    dial_code: '+269',
    code: 'KM',
    flag: '🇰🇲',
  },
  {
    name: {
      en: 'Congo',
      hu: 'Kongó',
      id: 'Kongo',
      ru: 'Конго',
      pl: 'Kongo',
      uk: 'Конго',
      cz: 'Kongo',
      by: 'Конга',
      pt: 'Congo-Brazzaville',
      es: 'Congo',
      ro: 'Congo',
      bg: 'Конго',
      de: 'Kongo',
      fr: 'Congo',
      nl: 'Congo',
      it: 'Congo',
      cn: '刚果',
      ee: 'Kongo',
      ja: 'コンゴ',
      da: 'Congo',
      fa: 'کنگو',
    },
    dial_code: '+242',
    code: 'CG',
    flag: '🇨🇬',
  },
  {
    name: {
      en: 'Congo, The Democratic Republic of the',
      hu: 'Kongói, Demokratikus Köztársaság',
      id: 'Kongo, Republik Demokratik',
      ru: 'Конго, Демократическая Республика',
      pl: 'Kongo, Republika Demokratyczna',
      uk: 'Конго, Демократична Республіка',
      cz: 'Kongo, Demokratická Republika',
      by: 'Конга, Дэмакратычная Рэспубліка Конга',
      pt: 'Congo-Kinshasa',
      es: 'Congo, República Democrática del',
      ro: 'Congo, Republica Democrată Congo',
      bg: 'Конго, Демократична република Конго',
      de: 'Kongo, Demokratische Republik Kongo',
      fr: 'Congo, République démocratique du Congo',
      nl: 'Congo, Democratische Republiek Congo',
      it: 'Congo, Repubblica Democratica del Congo',
      cn: '刚果民主共和国',
      ee: 'Kongo, Kongo Demokraatlik Vabariik',
      ja: 'コンゴ、コンゴ民主共和国',
      da: 'Congo, Den Demokratiske Republik Congo',
      fa: 'کنگو، جمهوری دموکراتیک کنگو',
    },
    dial_code: '+243',
    code: 'CD',
    flag: '🇨🇩',
  },
  {
    name: {
      en: 'Cook Islands',
      hu: 'Cook-szigetek',
      id: 'Kepulauan Cook',
      ru: 'Острова Кука',
      pl: 'Wyspy Cooka',
      uk: 'Острови Кука',
      cz: 'Cookovy ostrovy',
      by: 'Астравы Кука',
      pt: 'Ilhas Cook',
      es: 'Islas Cook',
      ro: 'Insulele Cook',
      bg: 'Острови Кук',
      de: 'Cookinseln',
      fr: 'les Îles Cook',
      nl: 'Cook Eilanden',
      it: 'Isole Cook',
      cn: '库克群岛',
      ee: 'Cooki saared',
      ja: 'クック諸島',
      da: 'Cookøerne',
      fa: 'جزایر کوک',
    },
    dial_code: '+682',
    code: 'CK',
    flag: '🇨🇰',
  },
  {
    name: {
      en: 'Costa Rica',
      hu: 'Costa Rica',
      id: 'Kosta Rika',
      ru: 'Коста-Рика',
      pl: 'Kostaryka',
      uk: 'Коста-Ріка',
      cz: 'Kostarika',
      by: 'Коста-Рыка',
      pt: 'Costa Rica',
      es: 'Costa Rica',
      ro: 'Costa Rica',
      bg: 'Коста Рика',
      de: 'Costa Rica',
      fr: 'Costa Rica',
      nl: 'Costa Rica',
      it: 'Costa Rica',
      cn: '哥斯达黎加',
      ee: 'Costa Rica',
      ja: 'コスタリカ',
      da: 'Costa Rica',
      fa: 'کاستاریکا',
    },
    dial_code: '+506',
    code: 'CR',
    flag: '🇨🇷',
  },
  {
    name: {
      en: "Cote d'Ivoire",
      hu: 'Elefántcsontpart',
      id: 'Pantai Gading',
      ru: "Кот-д'Ивуар",
      pl: 'Wybrzeże Kości Słoniowej',
      uk: "Кот-д'Івуар",
      cz: 'Pobřeží slonoviny',
      by: "Кот-д'Івуар",
      pt: 'Costa do Marfim',
      es: 'Costa de Marfil',
      ro: 'Coasta de Fildeș',
      bg: "Кот д'Ивоар",
      de: 'Elfenbeinküste',
      fr: "Côte d'Ivoire",
      nl: 'Ivoorkust',
      it: "Costa d'Avorio",
      cn: '科特迪瓦',
      ee: 'Elevandiluurannik',
      ja: 'コートジボワール',
      da: 'Elfenbenskysten',
      fa: 'ساحل عاج',
    },
    dial_code: '+225',
    code: 'CI',
    flag: '🇨🇮',
  },
  {
    name: {
      en: 'Croatia',
      hu: 'Horvátország',
      id: 'Kroasia',
      ru: 'Хорватия',
      pl: 'Chorwacja',
      uk: 'Хорватія',
      cz: 'Chorvatsko',
      by: 'Харватыя',
      pt: 'Croácia',
      es: 'Croacia',
      ro: 'Croaţia',
      bg: 'Хърватия',
      de: 'Kroatien',
      fr: 'Croatie',
      nl: 'Kroatië',
      it: 'Croazia',
      cn: '克罗地亚',
      ee: 'Horvaatia',
      ja: 'クロアチア',
      da: 'Kroatien',
      fa: 'کرواسی',
    },
    dial_code: '+385',
    code: 'HR',
    flag: '🇭🇷',
  },
  {
    name: {
      en: 'Cuba',
      hu: 'Kuba',
      id: 'Kuba',
      ru: 'Куба',
      pl: 'Kuba',
      uk: 'Куба',
      cz: 'Kuba',
      by: 'Куба',
      pt: 'Cuba',
      es: 'Cuba',
      ro: 'Cuba',
      bg: 'Куба',
      de: 'Kuba',
      fr: 'Cuba',
      nl: 'Cuba',
      it: 'Cuba',
      cn: '古巴',
      ee: 'Kuuba',
      ja: 'キューバ',
      da: 'Cuba',
      fa: 'کوبا',
    },
    dial_code: '+53',
    code: 'CU',
    flag: '🇨🇺',
  },
  {
    name: {
      en: 'Cyprus',
      hu: 'Ciprus',
      id: 'Siprus',
      ru: 'Кипр',
      pl: 'Cypr',
      uk: 'Кіпр',
      cz: 'Kypr',
      by: 'Кіпр',
      pt: 'Chipre',
      es: 'Chipre',
      ro: 'Cipru',
      bg: 'Кипър',
      de: 'Zypern',
      fr: 'Chypre',
      nl: 'Cyprus',
      it: 'Cipro',
      cn: '塞浦路斯',
      ee: 'Küpros',
      ja: 'キプロス',
      da: 'Cypern',
      fa: 'قبرس',
    },
    dial_code: '+537',
    code: 'CY',
    flag: '🇨🇾',
  },
  {
    name: {
      en: 'Czech Republic',
      hu: 'Csehország',
      id: 'Republik Ceko',
      ru: 'Чехия',
      pl: 'Republika Czeska',
      uk: 'Чеська Республіка',
      cz: 'Česká republika',
      by: 'Чэская Рэспубліка',
      pt: 'República Checa',
      es: 'República Checa',
      ro: 'Republica Cehă',
      bg: 'Чехия',
      de: 'Tschechien',
      fr: 'République Tchèque',
      nl: 'Tsjechië',
      it: 'Repubblica Ceca',
      cn: '捷克共和国',
      ee: 'Tšehhi Vabariik',
      ja: 'チェコ共和国',
      da: 'Tjekkiet',
      fa: 'جمهوری چک',
    },
    dial_code: '+420',
    code: 'CZ',
    flag: '🇨🇿',
  },
  {
    name: {
      en: 'Denmark',
      hu: 'Dánia',
      id: 'Denmark',
      ru: 'Дания',
      pl: 'Dania',
      uk: 'Данія',
      cz: 'Dánsko',
      by: 'Данія',
      pt: 'Dinamarca',
      es: 'Dinamarca',
      ro: 'Danemarca',
      bg: 'Дания',
      de: 'Dänemark',
      fr: 'Danemark',
      nl: 'Denemarken',
      it: 'Danimarca',
      cn: '丹麦',
      ee: 'Taani',
      ja: 'デンマーク',
      da: 'Danmark',
      fa: 'دانمارک',
    },
    dial_code: '+45',
    code: 'DK',
    flag: '🇩🇰',
  },
  {
    name: {
      en: 'Djibouti',
      hu: 'Dzsibuti',
      id: 'Jibuti',
      ru: 'Джибути',
      pl: 'Dżibuti',
      uk: 'Джибуті',
      cz: 'Džibuti',
      by: 'Джыбуці',
      pt: 'Jibuti',
      es: 'Yibuti',
      ro: 'Djibouti',
      bg: 'Джибути',
      de: 'Dschibuti',
      fr: 'Djibouti',
      nl: 'Djibouti',
      it: 'Gibuti',
      cn: '吉布地',
      ee: 'Djibouti',
      ja: 'ジブチ',
      da: 'Djibouti',
      fa: 'جیبوتی',
    },
    dial_code: '+253',
    code: 'DJ',
    flag: '🇩🇯',
  },
  {
    name: {
      en: 'Dominica',
      hu: 'Dominikai Közösség',
      id: 'Dominika',
      ru: 'Доминика',
      pl: 'Dominika',
      uk: 'Домініка',
      cz: 'Dominika',
      by: 'Дамініка',
      pt: 'Domínica',
      es: 'Dominica',
      ro: 'Dominica',
      bg: 'Доминика',
      de: 'Dominica',
      fr: 'Dominique',
      nl: 'Dominica',
      it: 'Dominica',
      cn: '多米尼加',
      ee: 'Dominica',
      ja: 'ドミニカ',
      da: 'Dominica',
      fa: 'دومینیکا',
    },
    dial_code: '+1767',
    code: 'DM',
    flag: '🇩🇲',
  },
  {
    name: {
      en: 'Dominican Republic',
      hu: 'Dominikai Köztársaság',
      id: 'Republik Dominika',
      ru: 'Доминиканская Респблика',
      pl: 'Republika Dominikany',
      uk: 'Домініканська республіка',
      cz: 'Dominikánská republika',
      by: 'Дамініканская Рэспубліка',
      pt: 'República Dominicana',
      es: 'República Dominicana',
      ro: 'Republica Dominicana',
      bg: 'Доминиканска република',
      de: 'Dominikanische Republik',
      fr: 'République dominicaine',
      nl: 'Dominicaanse Republiek',
      it: 'Repubblica Dominicana',
      cn: '多明尼加共和国',
      ee: 'Dominikaani Vabariik',
      ja: 'ドミニカ共和国',
      da: 'Den Dominikanske Republik',
      fa: 'جمهوری دومینیکن',
    },
    dial_code: '+1849',
    code: 'DO',
    flag: '🇩🇴',
  },
  {
    name: {
      en: 'Ecuador',
      hu: 'Ecuador',
      id: 'Ekuador',
      ru: 'Эквадор',
      pl: 'Ekwador',
      uk: 'Еквадор',
      cz: 'Ekvádor',
      by: 'Эквадор',
      pt: 'Equador',
      es: 'Ecuador',
      ro: 'Ecuador',
      bg: 'Еквадор',
      de: 'Ecuador',
      fr: 'Equateur',
      nl: 'Ecuador',
      it: 'Ecuador',
      cn: '厄瓜多尔',
      ee: 'Ecuador',
      ja: 'エクアドル',
      da: 'Ecuador',
      fa: 'اکوادور',
    },
    dial_code: '+593',
    code: 'EC',
    flag: '🇪🇨',
  },
  {
    name: {
      en: 'Egypt',
      hu: 'Egyiptom',
      id: 'Mesir',
      ru: 'Египет',
      pl: 'Egipt',
      uk: 'Єгипет',
      cz: 'Egypt',
      by: 'Егіпет',
      pt: 'Egipto',
      es: 'Egipto',
      ro: 'Egipt',
      bg: 'Египет',
      de: 'Ägypten',
      fr: 'Egypte',
      nl: 'Egypte',
      it: 'Egitto',
      cn: '埃及',
      ee: 'Egiptus',
      ja: 'エジプト',
      da: 'Egypten',
      fa: 'مصر',
    },
    dial_code: '+20',
    code: 'EG',
    flag: '🇪🇬',
  },
  {
    name: {
      en: 'El Salvador',
      hu: 'Salvador',
      id: 'El Salvador',
      ru: 'Сальвадор',
      pl: 'Salwador',
      uk: 'Сальвадор',
      cz: 'El Salvador',
      by: 'Сальвадор',
      pt: 'Salvador',
      es: 'El Salvador',
      ro: 'El Salvador',
      bg: 'Ел Салвадор',
      de: 'El Salvador',
      fr: 'Le Salvador',
      nl: 'El Salvador',
      it: 'El Salvador',
      cn: '萨尔瓦多',
      ee: 'El Salvador',
      ja: 'エルサルバドル',
      da: 'El Salvador',
      fa: 'السالوادور',
    },
    dial_code: '+503',
    code: 'SV',
    flag: '🇸🇻',
  },
  {
    name: {
      en: 'Equatorial Guinea',
      hu: 'Egyenlítői-Guinea',
      id: 'Guinea Khatulistiwa',
      ru: 'Экваториальная Гвинея',
      pl: 'Gwinea Równikowa',
      uk: 'Екваторіальна Гвінея',
      cz: 'Rovníková Guinea',
      by: 'Экватарыяльная Гвінея',
      pt: 'Guiné Equatorial',
      es: 'Guinea Ecuatorial',
      ro: 'Guineea Ecuatorială',
      bg: 'Екваториална Гвинея',
      de: 'Äquatorialguinea',
      fr: 'Guinée Équatoriale',
      nl: 'Equatoriaal-Guinea',
      it: 'Guinea Equatoriale',
      cn: '赤道几内亚',
      ee: 'Ekvatoriaalne Guinea',
      ja: '赤道ギニア',
      da: 'Ækvatorialguinea',
      fa: 'گینه استوایی',
    },
    dial_code: '+240',
    code: 'GQ',
    flag: '🇬🇶',
  },
  {
    name: {
      en: 'Eritrea',
      hu: 'Eritrea',
      id: 'Eritrea',
      ru: 'Эритрея',
      pl: 'Erytrea',
      uk: 'Еритрея',
      cz: 'Eritrea',
      by: 'Эрытрэя',
      pt: 'Eritreia',
      es: 'Eritrea',
      ro: 'Eritreea',
      bg: 'Еритрея',
      de: 'Eritrea',
      fr: 'Érythrée',
      nl: 'Eritrea',
      it: 'Eritrea',
      cn: '厄立特里亚',
      ee: 'Eritrea',
      ja: 'エリトリア',
      da: 'Eritrea',
      fa: 'اریتره',
    },
    dial_code: '+291',
    code: 'ER',
    flag: '🇪🇷',
  },
  {
    name: {
      en: 'Estonia',
      hu: 'Észtország',
      id: 'Estonia',
      ru: 'Эстония',
      pl: 'Estonia',
      uk: 'Естонія',
      cz: 'Estonsko',
      by: 'Эстонія',
      pt: 'Estónia',
      es: 'Estonia',
      ro: 'Estonia',
      bg: 'Естония',
      de: 'Estland',
      fr: 'Estonie',
      nl: 'Estland',
      it: 'Estonia',
      cn: '爱沙尼亚',
      ee: 'Eesti',
      ja: 'エストニア',
      da: 'Estland',
      fa: 'استونی',
    },
    dial_code: '+372',
    code: 'EE',
    flag: '🇪🇪',
  },
  {
    name: {
      en: 'Ethiopia',
      hu: 'Etiópia',
      id: 'Etiopia',
      ru: 'Эфиопия',
      pl: 'Etiopia',
      uk: 'Ефіопія',
      cz: 'Etiopie',
      by: 'Эфіопія',
      pt: 'Etiópia',
      es: 'Etiopía',
      ro: 'Etiopia',
      bg: 'Етиопия',
      de: 'Äthiopien',
      fr: 'Ethiopie',
      nl: 'Ethiopië',
      it: 'Etiopia',
      cn: '埃塞俄比亚',
      ee: 'Etioopia',
      ja: 'エチオピア',
      da: 'Etiopien',
      fa: 'اتیوپی',
    },
    dial_code: '+251',
    code: 'ET',
    flag: '🇪🇹',
  },
  {
    name: {
      en: 'Falkland Islands (Malvinas)',
      hu: 'Falkland-szigetek (Malvinas)',
      id: 'Kepulauan Falkland (Malvinas)',
      ru: 'Фолклендские острова (Malvinas)',
      pl: 'Falklandy (Malwiny)',
      uk: 'Фолклендські острови (Мальвінські острови)',
      cz: 'Falklandské ostrovy (Malvíny)',
      by: 'Фолклендскія астравы (Мальвіна)',
      pt: 'Ilhas Falkland',
      es: 'Islas Malvinas (Falkland Islands)',
      ro: 'Insulele Falkland (Malvinas)',
      bg: 'Фолкландски острови (Малвински острови)',
      de: 'Falklandinseln (Malvinas)',
      fr: 'Îles Falkland (Malvinas)',
      nl: 'Falklandeilanden (Malvinas)',
      it: 'Isole Falkland (Malvinas)',
      cn: '福克兰群岛（马尔维纳斯）',
      ee: 'Falklandi saared (Malvinas)',
      ja: 'フォークランド諸島（マルビナス）',
      da: 'Falklandsøerne (Malvinas)',
      fa: 'جزایر فالکلند (مالوین)',
    },
    dial_code: '+500',
    code: 'FK',
    flag: '🇫🇰',
  },
  {
    name: {
      en: 'Faroe Islands',
      hu: 'Feröer-szigetek',
      id: 'Kepulauan Faroe',
      ru: 'Фарерские острова',
      pl: 'Wyspy Owcze',
      uk: 'Фарерські острови',
      cz: 'Faerské ostrovy',
      by: 'Фарэрскія астравы',
      pt: 'Faroé',
      es: 'Islas Feroe',
      ro: 'Insulele Feroe',
      bg: 'Фарьорските острови',
      de: 'Färöer Inseln',
      fr: 'Îles Féroé',
      nl: 'Faeröer',
      it: 'Isole Faroe',
      cn: '法罗群岛',
      ee: 'Fääri saared',
      ja: 'フェロー諸島',
      da: 'Færøerne',
      fa: 'جزایر فارو',
    },
    dial_code: '+298',
    code: 'FO',
    flag: '🇫🇴',
  },
  {
    name: {
      en: 'Fiji',
      hu: 'Fidzsi-szigetek',
      id: 'Fiji',
      ru: 'Фиджи',
      pl: 'Fidżi',
      uk: 'Фіджі',
      cz: 'Fidži',
      by: 'Фіджы',
      pt: 'Fiji',
      es: 'Fiyi',
      ro: 'Fiji',
      bg: 'Фиджи',
      de: 'Fidschi',
      fr: 'Fidji',
      nl: 'Fiji',
      it: 'Figi',
      cn: '斐济',
      ee: 'Fidži',
      ja: 'フィジー',
      da: 'Fiji',
      fa: 'فیجی',
    },
    dial_code: '+679',
    code: 'FJ',
    flag: '🇫🇯',
  },
  {
    name: {
      en: 'Finland',
      hu: 'Finnország',
      id: 'Finlandia',
      ru: 'Финляндия',
      pl: 'Finlandia',
      uk: 'Фінляндія',
      cz: 'Finsko',
      by: 'Фінляндыя',
      pt: 'Finlândia',
      es: 'Finlandia',
      ro: 'Finlanda',
      bg: 'Финландия',
      de: 'Finnland',
      fr: 'Finlande',
      nl: 'Finland',
      it: 'Finlandia',
      cn: '芬兰',
      ee: 'Soome',
      ja: 'フィンランド',
      da: 'Finland',
      fa: 'فنلاند',
    },
    dial_code: '+358',
    code: 'FI',
    flag: '🇫🇮',
  },
  {
    name: {
      en: 'France',
      hu: 'Franciaország',
      id: 'Perancis',
      ru: 'Франция',
      pl: 'Francja',
      uk: 'Франція',
      cz: 'Francie',
      by: 'Францыя',
      pt: 'França',
      es: 'Francia',
      ro: 'Franţa',
      bg: 'Франция',
      de: 'Frankreich',
      fr: 'France',
      nl: 'Frankrijk',
      it: 'Francia',
      cn: '法国',
      ee: 'Prantsusmaa',
      ja: 'フランス',
      da: 'Frankrig',
      fa: 'فرانسه',
    },
    dial_code: '+33',
    code: 'FR',
    flag: '🇫🇷',
  },
  {
    name: {
      en: 'French Guiana',
      hu: 'Francia Guyana',
      id: 'Guyana Perancis',
      ru: 'Французская Гвиана',
      pl: 'Gujana Francuska',
      uk: 'Французька Гвіана',
      cz: 'Francouzská Guyana',
      by: 'Французская Гвіяна',
      pt: 'Guiana Francesa',
      es: 'Guayana Francesa',
      ro: 'Guyana Franceză',
      bg: 'Френска Гвиана',
      de: 'Französisch-Guayana',
      fr: 'Guyane Française',
      nl: 'Frans Guyana',
      it: 'Guiana francese',
      cn: '法属圭亚那',
      ee: 'Prantsuse Guajaana',
      ja: 'フランス領ギアナ',
      da: 'Fransk Guyana',
      fa: 'گویان فرانسه',
    },
    dial_code: '+594',
    code: 'GF',
    flag: '🇬🇫',
  },
  {
    name: {
      en: 'French Polynesia',
      hu: 'Francia Polinézia',
      id: 'Polinesia Perancis',
      ru: 'Французская Полинезия',
      pl: 'Polinezja Francuska',
      uk: 'Французька Полінезія',
      cz: 'Francouzská Polynésie',
      by: 'Французская Палінезія',
      pt: 'Polinésia Francesa',
      es: 'Polinesia Francesa',
      ro: 'Polinezia Franceză',
      bg: 'Френска полинезия',
      de: 'Französisch Polynesien',
      fr: 'Polynésie française',
      nl: 'Frans-Polynesië',
      it: 'Polinesia francese',
      cn: '法属波利尼西亚',
      ee: 'Prantsuse Polüneesia',
      ja: 'フランス領ポリネシア',
      da: 'Fransk Polynesien',
      fa: 'پلی‌نزی فرانسه',
    },
    dial_code: '+689',
    code: 'PF',
    flag: '🇵🇫',
  },
  {
    name: {
      en: 'Gabon',
      hu: 'Gabon',
      id: 'Gabon',
      ru: 'Габон',
      pl: 'Gabon',
      uk: 'Габон',
      cz: 'Gabon',
      by: 'Габон',
      pt: 'Gabão',
      es: 'Gabón',
      ro: 'Gabon',
      bg: 'Габон',
      de: 'Gabun',
      fr: 'Gabon',
      nl: 'Gabon',
      it: 'Gabon',
      cn: '加蓬',
      ee: 'Gabon',
      ja: 'ガボン',
      da: 'Gabon',
      fa: 'گابن',
    },
    dial_code: '+241',
    code: 'GA',
    flag: '🇬🇦',
  },
  {
    name: {
      en: 'Gambia',
      hu: 'Gambia',
      id: 'Gambia',
      ru: 'Гамбия',
      pl: 'Gambia',
      uk: 'Гамбія',
      cz: 'Gambie',
      by: 'Гамбія',
      pt: 'Gâmbia',
      es: 'Gambia',
      ro: 'Gambia',
      bg: 'Гамбия',
      de: 'Gambia',
      fr: 'Gambie',
      nl: 'Gambia',
      it: 'Gambia',
      cn: '冈比亚',
      ee: 'Gambia',
      ja: 'ガンビア',
      da: 'Gambia',
      fa: 'گامبیا',
    },
    dial_code: '+220',
    code: 'GM',
    flag: '🇬🇲',
  },
  {
    name: {
      en: 'Georgia',
      hu: 'Grúzia',
      id: 'Georgia',
      ru: 'Грузия',
      pl: 'Gruzja',
      uk: 'Грузія',
      cz: 'Gruzie',
      by: 'Грузія',
      pt: 'Geórgia',
      es: 'Georgia',
      ro: 'Georgia',
      bg: 'Джорджия',
      de: 'Georgia',
      fr: 'Géorgie',
      nl: 'Georgië',
      it: 'Georgia',
      cn: '乔治亚州',
      ee: 'Gruusia',
      ja: 'ジョージア',
      da: 'Georgien',
      fa: 'گرجستان',
    },
    dial_code: '+995',
    code: 'GE',
    flag: '🇬🇪',
  },
  {
    name: {
      en: 'Germany',
      hu: 'Németország',
      id: 'Jerman',
      ru: 'Германия',
      pl: 'Niemcy',
      uk: 'Німеччина',
      cz: 'Německo',
      by: 'Германія',
      pt: 'Alemanha',
      es: 'Alemania',
      ro: 'Germania',
      bg: 'Германия',
      de: 'Deutschland',
      fr: 'Allemagne',
      nl: 'Duitsland',
      it: 'Germania',
      cn: '德国',
      ee: 'Saksamaa',
      ja: 'ドイツ',
      da: 'Tyskland',
      fa: 'آلمان',
    },
    dial_code: '+49',
    code: 'DE',
    flag: '🇩🇪',
  },
  {
    name: {
      en: 'Ghana',
      hu: 'Ghána',
      id: 'Ghana',
      ru: 'Гана',
      pl: 'Ghana',
      uk: 'Гана',
      cz: 'Ghana',
      by: 'Гана',
      pt: 'Gana',
      es: 'Ghana',
      ro: 'Ghana',
      bg: 'Гана',
      de: 'Ghana',
      fr: 'Ghana',
      nl: 'Ghana',
      it: 'Ghana',
      cn: '加纳',
      ee: 'Ghana',
      ja: 'ガーナ',
      da: 'Ghana',
      fa: 'غنا',
    },
    dial_code: '+233',
    code: 'GH',
    flag: '🇬🇭',
  },
  {
    name: {
      en: 'Gibraltar',
      hu: 'Gibraltár',
      id: 'Gibraltar',
      ru: 'Гибралтар',
      pl: 'Gibraltar',
      uk: 'Гібралтар',
      cz: 'Gibraltar',
      by: 'Гібралтар',
      pt: 'Gibraltar',
      es: 'Gibraltar',
      ro: 'Gibraltar',
      bg: 'Гибралтар',
      de: 'Gibraltar',
      fr: 'Gibraltar',
      nl: 'Gibraltar',
      it: 'Gibilterra',
      cn: '直布罗陀',
      ee: 'Gibraltar',
      ja: 'ジブラルタル',
      da: 'Gibraltar',
      fa: 'جبل‌طارق',
    },
    dial_code: '+350',
    code: 'GI',
    flag: '🇬🇮',
  },
  {
    name: {
      en: 'Greece',
      hu: 'Görögország',
      id: 'Yunani',
      ru: 'Греция',
      pl: 'Grecja',
      uk: 'Греція',
      cz: 'Řecko',
      by: 'Грэцыя',
      pt: 'Grécia',
      es: 'Grecia',
      ro: 'Grecia',
      bg: 'Гърция',
      de: 'Griechenland',
      fr: 'Grèce',
      nl: 'Griekenland',
      it: 'Grecia',
      cn: '希腊',
      ee: 'Kreeka',
      ja: 'ギリシャ',
      da: 'Grækenland',
      fa: 'یونان',
    },
    dial_code: '+30',
    code: 'GR',
    flag: '🇬🇷',
  },
  {
    name: {
      en: 'Greenland',
      hu: 'Grönland',
      id: 'Greenland',
      ru: 'Гренландия',
      pl: 'Grenlandia',
      uk: 'Гренландія',
      cz: 'Grenlandia',
      by: 'Грэнландыя',
      pt: 'Gronelândia',
      es: 'Groenlandia',
      ro: 'Groenlanda',
      bg: 'Гренландия',
      de: 'Grönland',
      fr: 'Groenland',
      nl: 'Groenland',
      it: 'Groenlandia',
      cn: '格陵兰',
      ee: 'Gröönimaa',
      ja: 'グリーンランド',
      da: 'Grønland',
      fa: 'گرینلند',
    },
    dial_code: '+299',
    code: 'GL',
    flag: '🇬🇱',
  },
  {
    name: {
      en: 'Grenada',
      hu: 'Grenada',
      id: 'Grenada',
      ru: 'Гренада',
      pl: 'Grenada',
      uk: 'Гренада',
      cz: 'Grenada',
      by: 'Грэнада',
      pt: 'Granada',
      es: 'Granada',
      ro: 'Grenada',
      bg: 'Гренада',
      de: 'Grenada',
      fr: 'Grenade',
      nl: 'Grenada',
      it: 'Grenada',
      cn: '格林纳达',
      ee: 'Grenada',
      ja: 'グレナダ',
      da: 'Grenada',
      fa: 'گرنادا',
    },
    dial_code: '+1473',
    code: 'GD',
    flag: '🇬🇩',
  },
  {
    name: {
      en: 'Guadeloupe',
      hu: 'Guadeloupe',
      id: 'Guadeloupe',
      ru: 'Гваделупа',
      pl: 'Gwadelupa',
      uk: 'Гваделупа',
      cz: 'Guadeloupe',
      by: 'Гвадэлупа',
      pt: 'Guadalupe',
      es: 'Guadalupe',
      ro: 'Guadelupa',
      bg: 'Гваделупа',
      de: 'Guadeloupe',
      fr: 'Guadeloupe',
      nl: 'Guadeloupe',
      it: 'Guadalupa',
      cn: '瓜德罗普岛',
      ee: 'Guadeloupe',
      ja: 'グアドループ',
      da: 'Guadeloupe',
      fa: 'جزیره گوادلوپ',
    },
    dial_code: '+590',
    code: 'GP',
    flag: '🇬🇵',
  },
  {
    name: {
      en: 'Guam',
      hu: 'Guam',
      id: 'Guam',
      ru: 'Гуам',
      pl: 'Guam',
      uk: 'Гуам',
      cz: 'Guam',
      by: 'Гуам',
      pt: 'Guame',
      es: 'Guam',
      ro: 'Guam',
      bg: 'Гуам',
      de: 'Guam',
      fr: 'Guam',
      nl: 'Guam',
      it: 'Guam',
      cn: '关岛',
      ee: 'Guam',
      ja: 'グアム',
      da: 'Guam',
      fa: 'گوام',
    },
    dial_code: '+1671',
    code: 'GU',
    flag: '🇬🇺',
  },
  {
    name: {
      en: 'Guatemala',
      hu: 'Guatemala',
      id: 'Guatemala',
      ru: 'Гватемала',
      pl: 'Gwatemala',
      uk: 'Гватемала',
      cz: 'Guatemala',
      by: 'Гватэмала',
      pt: 'Guatemala',
      es: 'Guatemala',
      ro: 'Guatemala',
      bg: 'Гватемала',
      de: 'Guatemala',
      fr: 'Guatemala',
      nl: 'Guatemala',
      it: 'Guatemala',
      cn: '危地马拉',
      ee: 'Guatemala',
      ja: 'グアテマラ',
      da: 'Guatemala',
      fa: 'گواتمالا',
    },
    dial_code: '+502',
    code: 'GT',
    flag: '🇬🇹',
  },
  {
    name: {
      en: 'Guernsey',
      hu: 'Guernsey',
      id: 'Guernsey',
      ru: 'Гернси',
      pl: 'Guernsey',
      uk: 'Гернсі',
      cz: 'Guernsey',
      by: 'Гернсі',
      pt: 'Guernsey',
      es: 'Guernsey',
      ro: 'Guernsey',
      bg: 'Гернси',
      de: 'Guernsey',
      fr: 'Guernesey',
      nl: 'Guernsey',
      it: 'Guernsey',
      cn: '根西岛',
      ee: 'Guernsey',
      ja: 'ガーンジー',
      da: 'Guernsey',
      fa: 'گرنزی',
    },
    dial_code: '+44',
    code: 'GG',
    flag: '🇬🇬',
  },
  {
    name: {
      en: 'Guinea',
      hu: 'Guinea',
      id: 'Guinea',
      ru: 'Гвинея',
      pl: 'Gwinea',
      uk: 'Гвінея',
      cz: 'Guinea',
      by: 'Гвінея',
      pt: 'Guiné',
      es: 'Guinea',
      ro: 'Guineea',
      bg: 'Гвинея',
      de: 'Guinea',
      fr: 'Guinée',
      nl: 'Guinea',
      it: 'Guinea',
      cn: '几内亚',
      ee: 'Guinea',
      ja: 'ギニア',
      da: 'Guinea',
      fa: 'گینه',
    },
    dial_code: '+224',
    code: 'GN',
    flag: '🇬🇳',
  },
  {
    name: {
      en: 'Guinea-Bissau',
      hu: 'Bissau-Guinea',
      id: 'Guinea-Bissau',
      ru: 'Гвинея-Бисау',
      pl: 'Gwinea Bissau',
      uk: 'Гвінея-Бісау',
      cz: 'Guinea-Bissau',
      by: 'Гвінея-Бісаў',
      pt: 'Guiné-Bissau',
      es: 'Guinea-Bissau',
      ro: 'Guineea-Bissau',
      bg: 'Гвинея-Бисау',
      de: 'Guinea-Bissau',
      fr: 'Guinée-Bissau',
      nl: 'Guinee-Bissau',
      it: 'Guinea-Bissau',
      cn: '几内亚比绍',
      ee: 'Guinea-Bissau',
      ja: 'ギニアビサウ',
      da: 'Guinea-Bissau',
      fa: 'گینه بیسائو',
    },
    dial_code: '+245',
    code: 'GW',
    flag: '🇬🇼',
  },
  {
    name: {
      en: 'Guyana',
      hu: 'Guyana',
      id: 'Guyana',
      ru: 'Гайана',
      pl: 'Gujana',
      uk: 'Гайана',
      cz: 'Guyana',
      by: 'Гаяна',
      pt: 'Guiana',
      es: 'Guyana',
      ro: 'Guyana',
      bg: 'Гвиана',
      de: 'Guyana',
      fr: 'Guyane',
      nl: 'Guyana',
      it: 'Guyana',
      cn: '圭亚那',
      ee: 'Guajaana',
      ja: 'ガイアナ',
      da: 'Guyana',
      fa: 'گویان',
    },
    dial_code: '+595',
    code: 'GY',
    flag: '🇬🇾',
  },
  {
    name: {
      en: 'Haiti',
      hu: 'Haiti',
      id: 'Haiti',
      ru: 'Гаити',
      pl: 'Haiti',
      uk: 'Гаїті',
      cz: 'Haiti',
      by: 'Гаіці',
      pt: 'Haiti',
      es: 'Haití',
      ro: 'Haiti',
      bg: 'Хаити',
      de: 'Haiti',
      fr: 'Haïti',
      nl: 'Haïti',
      it: 'Haiti',
      cn: '海地',
      ee: 'Haiti',
      ja: 'ハイチ',
      da: 'Haiti',
      fa: 'هائیتی',
    },
    dial_code: '+509',
    code: 'HT',
    flag: '🇭🇹',
  },
  {
    name: {
      en: 'Holy See (Vatican City State)',
      hu: 'Vatikán',
      id: 'Vatikan',
      ru: 'Святой Престол (Vatican City State)',
      pl: 'Stolica Apostolska (Państwo Watykańskie)',
      uk: 'Святий Престол (Ватикан)',
      cz: 'Svatý stolec (Vatikánský městský stát)',
      by: 'Святы Пасад (дзяржава Ватыкан)',
      pt: 'Vaticano',
      es: 'Santa Sede (Estado de la Ciudad del Vaticano)',
      ro: 'Sfântul Scaun (Statul Vatican)',
      bg: 'Свети престол (Ватикан)',
      de: 'Heiliger Stuhl (Staat der Vatikanstadt)',
      fr: 'Saint-Siège (État de la Cité du Vatican)',
      nl: 'Heilige Stoel (Vaticaanstad)',
      it: 'Santa Sede (Stato della Città del Vaticano)',
      cn: '罗马教廷（梵蒂冈城国）',
      ee: 'Püha Tool (Vatikani linnriik)',
      ja: 'ホーリーシー（バチカン市国）',
      da: 'Vatikanstaten',
      fa: 'سریر مقدس (واتیکان)',
    },
    dial_code: '+379',
    code: 'VA',
    flag: '🇻🇦',
  },
  {
    name: {
      en: 'Honduras',
      hu: 'Honduras',
      id: 'Honduras',
      ru: 'Гондурас',
      pl: 'Honduras',
      uk: 'Гондурас',
      cz: 'Honduras',
      by: 'Гандурас',
      pt: 'Honduras',
      es: 'Honduras',
      ro: 'Honduras',
      bg: 'Хондурас',
      de: 'Honduras',
      fr: 'Honduras',
      nl: 'Honduras',
      it: 'Honduras',
      cn: '洪都拉斯',
      ee: 'Honduras',
      ja: 'ホンジュラス',
      da: 'Honduras',
      fa: 'هندوراس',
    },
    dial_code: '+504',
    code: 'HN',
    flag: '🇭🇳',
  },
  {
    name: {
      en: 'Hong Kong',
      hu: 'Hongkong',
      id: 'Hong Kong',
      ru: 'Гонконг',
      pl: 'Hongkong',
      uk: 'Гонконг',
      cz: 'Hongkong',
      by: 'Ганконг',
      pt: 'Hong Kong',
      es: 'Hong Kong',
      ro: 'Hong Kong',
      bg: 'Хонг Конг',
      de: 'Hongkong',
      fr: 'Hong Kong',
      nl: 'Hong Kong',
      it: 'Hong Kong',
      cn: '香港',
      ee: 'Hongkong',
      ja: '香港',
      da: 'Hongkong',
      fa: 'هنگ‌کنگ',
    },
    dial_code: '+852',
    code: 'HK',
    flag: '🇭🇰',
  },
  {
    name: {
      en: 'Hungary',
      hu: 'Magyarország',
      id: 'Hungaria',
      ru: 'Венгрия',
      pl: 'Węgry',
      uk: 'Угорщина',
      cz: 'Maďarsko',
      by: 'Венгрыя',
      pt: 'Hungria',
      es: 'Hungría',
      ro: 'Ungaria',
      bg: 'Унгария',
      de: 'Ungarn',
      fr: 'Hongrie',
      nl: 'Hongarije',
      it: 'Ungheria',
      cn: '匈牙利',
      ee: 'Ungari',
      ja: 'ハンガリー',
      da: 'Ungarn',
      fa: 'مجارستان',
    },
    dial_code: '+36',
    code: 'HU',
    flag: '🇭🇺',
  },
  {
    name: {
      en: 'Iceland',
      hu: 'Izland',
      id: 'Islandia',
      ru: 'Исландия',
      pl: 'Islandia',
      uk: 'Ісландія',
      cz: 'Island',
      by: 'Ісландыя',
      pt: 'Islândia',
      es: 'Islandia',
      ro: 'Islanda',
      bg: 'Исландия',
      de: 'Island',
      fr: 'Islande',
      nl: 'IJsland',
      it: 'Islanda',
      cn: '冰岛',
      ee: 'Island',
      ja: 'アイスランド',
      da: 'Island',
      fa: 'ایسلند',
    },
    dial_code: '+354',
    code: 'IS',
    flag: '🇮🇸',
  },
  {
    name: {
      en: 'India',
      hu: 'India',
      id: 'India',
      ru: 'Индия',
      pl: 'Indie',
      uk: 'Індія',
      cz: 'Indie',
      by: 'Індыя',
      pt: 'Índia',
      es: 'India',
      ro: 'India',
      bg: 'Индия',
      de: 'Indien',
      fr: 'Inde',
      nl: 'India',
      it: 'India',
      cn: '印度',
      ee: 'India',
      ja: 'インド',
      da: 'Indien',
      fa: 'هند',
    },
    dial_code: '+91',
    code: 'IN',
    flag: '🇮🇳',
  },
  {
    name: {
      en: 'Indonesia',
      hu: 'Indonézia',
      id: 'Indonesia',
      ru: 'Индонезия',
      pl: 'Indonezja',
      uk: 'Індонезія',
      cz: 'Indonésie',
      by: 'Інданезія',
      pt: 'Indonésia',
      es: 'Indonesia',
      ro: 'Indonezia',
      bg: 'Индонезия',
      de: 'Indonesien',
      fr: 'Indonésie',
      nl: 'Indonesië',
      it: 'Indonesia',
      cn: '印度尼西亚',
      ee: 'Indoneesia',
      ja: 'インドネシア',
      da: 'Indonesien',
      fa: 'اندونزی',
    },
    dial_code: '+62',
    code: 'ID',
    flag: '🇮🇩',
  },
  {
    name: {
      en: 'Iran',
      hu: 'Irán',
      id: 'Iran',
      ru: 'Иран',
      pl: 'Iran',
      uk: 'Іран',
      cz: 'Írán',
      by: 'Іран',
      pt: 'Irão',
      es: 'Irán',
      ro: 'Iran',
      bg: 'Иран',
      de: 'Iran',
      fr: 'Iran',
      nl: 'Iran',
      it: 'Iran',
      cn: '伊朗',
      ee: 'Iraan',
      ja: 'イラン',
      da: 'Iran',
      fa: 'ایران',
    },
    dial_code: '+98',
    code: 'IR',
    flag: '🇮🇷',
  },
  {
    name: {
      en: 'Iraq',
      hu: 'Irak',
      id: 'Irak',
      ru: 'Ирак',
      pl: 'Irak',
      uk: 'Ірак',
      cz: 'Irák',
      by: 'Ірак',
      pt: 'Iraque',
      es: 'Irak',
      ro: 'Irak',
      bg: 'Ирак',
      de: 'Irak',
      fr: 'Irak',
      nl: 'Irak',
      it: 'Iraq',
      cn: '伊拉克',
      ee: 'Iraak',
      ja: 'イラク',
      da: 'Irak',
      fa: 'عراق',
    },
    dial_code: '+964',
    code: 'IQ',
    flag: '🇮🇶',
  },
  {
    name: {
      en: 'Ireland',
      hu: 'Írország',
      id: 'Irlandia',
      ru: 'Ирландия',
      pl: 'Irlandia',
      uk: 'Ірландія',
      cz: 'Irsko',
      by: 'Ірландыя',
      pt: 'Irlanda',
      es: 'Irlanda',
      ro: 'Irlanda',
      bg: 'Ирландия',
      de: 'Irland',
      fr: 'Irlande',
      nl: 'Ierland',
      it: 'Irlanda',
      cn: '爱尔兰',
      ee: 'Iirimaa',
      ja: 'アイルランド',
      da: 'Irland',
      fa: 'ایرلند',
    },
    dial_code: '+353',
    code: 'IE',
    flag: '🇮🇪',
  },
  {
    name: {
      en: 'Isle of Man',
      hu: 'Man-sziget',
      id: 'Pulau Man',
      ru: 'Остров Мэн',
      pl: 'Wyspa Man',
      uk: 'Острів Мен',
      cz: 'Isle of Man',
      by: 'Востраў Мэн',
      pt: 'Ilha de Man',
      es: 'Isla de Man',
      ro: 'insula Barbatului',
      bg: 'Остров Ман',
      de: 'Isle of Man',
      fr: 'île de Man',
      nl: 'Isle of Man',
      it: 'Isola di Man',
      cn: '马恩岛',
      ee: 'Mani saar',
      ja: 'マン島',
      da: 'Isle of Man',
      fa: 'جزیره من',
    },
    dial_code: '+44',
    code: 'IM',
    flag: '🇮🇲',
  },
  {
    name: {
      en: 'Israel',
      hu: 'Izrael',
      id: 'Israel',
      ru: 'Израиль',
      pl: 'Izrael',
      uk: 'Ізраїль',
      cz: 'Izrael',
      by: 'Ізраіль',
      pt: 'Israel',
      es: 'Israel',
      ro: 'Israel',
      bg: 'Израел',
      de: 'Israel',
      fr: 'Israël',
      nl: 'Israël',
      it: 'Israele',
      cn: '以色列',
      ee: 'Iisrael',
      ja: 'イスラエル',
      da: 'Israel',
      fa: 'اسرائیل',
    },
    dial_code: '+972',
    code: 'IL',
    flag: '🇮🇱',
  },
  {
    name: {
      en: 'Italy',
      hu: 'Olaszország',
      id: 'Italia',
      ru: 'Италия',
      pl: 'Włochy',
      uk: 'Італія',
      cz: 'Itálie',
      by: 'Італія',
      pt: 'Itália',
      es: 'Italia',
      ro: 'Italia',
      bg: 'Италия',
      de: 'Italien',
      fr: 'Italie',
      nl: 'Italië',
      it: 'Italia',
      cn: '意大利',
      ee: 'Itaalia',
      ja: 'イタリア',
      da: 'Italien',
      fa: 'ایتالیا',
    },
    dial_code: '+39',
    code: 'IT',
    flag: '🇮🇹',
  },
  {
    name: {
      en: 'Jamaica',
      hu: 'Jamaica',
      id: 'Jamaika',
      ru: 'Ямайка',
      pl: 'Jamajka',
      uk: 'Ямайка',
      cz: 'Jamaica',
      by: 'Ямайка',
      pt: 'Jamaica',
      es: 'Jamaica',
      ro: 'Jamaica',
      bg: 'Ямайка',
      de: 'Jamaika',
      fr: 'Jamaïque',
      nl: 'Jamaica',
      it: 'Giamaica',
      cn: '的牙买加',
      ee: 'Jamaica',
      ja: 'ジャマイカ',
      da: 'Jamaica',
      fa: 'جامائیکا',
    },
    dial_code: '+1876',
    code: 'JM',
    flag: '🇯🇲',
  },
  {
    name: {
      en: 'Japan',
      hu: 'Japán',
      id: 'Jepang',
      ru: 'Япония',
      pl: 'Japonia',
      uk: 'Японія',
      cz: 'Japonsko',
      by: 'Японія',
      pt: 'Japão',
      es: 'Japón',
      ro: 'Japonia',
      bg: 'Япония',
      de: 'Japan',
      fr: 'Japon',
      nl: 'Japan',
      it: 'Giappone',
      cn: '日本',
      ee: 'Jaapan',
      ja: '日本',
      da: 'Japan',
      fa: 'ژاپن',
    },
    dial_code: '+81',
    code: 'JP',
    flag: '🇯🇵',
  },
  {
    name: {
      en: 'Jersey',
      hu: 'Jersey',
      id: 'Jersey',
      ru: 'Джерси',
      pl: 'Jersey',
      uk: 'Джерсі',
      cz: 'Trikot',
      by: 'Джэрсі',
      pt: 'Jersey',
      es: 'Jersey',
      ro: 'Jersey',
      bg: 'Джърси',
      de: 'Jersey',
      fr: 'Jersey',
      nl: 'Jersey',
      it: 'Jersey',
      cn: '球衣',
      ee: 'Jersey',
      ja: 'ジャージー',
      da: 'Jersey',
      fa: 'جرزی',
    },
    dial_code: '+44',
    code: 'JE',
    flag: '🇯🇪',
  },
  {
    name: {
      en: 'Jordan',
      hu: 'Jordánia',
      id: 'Yordania',
      ru: 'Иордания',
      pl: 'Jordania',
      uk: 'Йорданія',
      cz: 'Jordán',
      by: 'Іарданія',
      pt: 'Jordânia',
      es: 'Jordania',
      ro: 'Iordania',
      bg: 'Йордания',
      de: 'Jordanien',
      fr: 'Jordan',
      nl: 'Jordanië',
      it: 'Giordania',
      cn: '约旦',
      ee: 'Jordaania',
      ja: 'ヨルダン',
      da: 'Jordan',
      fa: 'اردن',
    },
    dial_code: '+962',
    code: 'JO',
    flag: '🇯🇴',
  },
  {
    name: {
      en: 'Kazakhstan',
      hu: 'Kazahsztán',
      id: 'Kazakhstan',
      ru: 'Казахстан',
      pl: 'Kazachstan',
      uk: 'Казахстан',
      cz: 'Kazachstán',
      by: 'Казахстан',
      pt: 'Cazaquistão',
      es: 'Kazajstán',
      ro: 'Kazahstan',
      bg: 'Казахстан',
      de: 'Kasachstan',
      fr: 'Kazakhstan',
      nl: 'Kazachstan',
      it: 'Kazakistan',
      cn: '哈萨克斯坦',
      ee: 'Kasahstan',
      ja: 'カザフスタン',
      da: 'Kasakhstan',
      fa: 'قزاقستان',
    },
    dial_code: '+77',
    code: 'KZ',
    flag: '🇰🇿',
  },
  {
    name: {
      en: 'Kenya',
      hu: 'Kenya',
      id: 'Kenya',
      ru: 'Кения',
      pl: 'Kenia',
      uk: 'Кенія',
      cz: 'Keňa',
      by: 'Кенія',
      pt: 'Quénia',
      es: 'Kenia',
      ro: 'Kenya',
      bg: 'Кения',
      de: 'Kenia',
      fr: 'Kenya',
      nl: 'Kenia',
      it: 'Kenya',
      cn: '肯尼亚',
      ee: 'Keenia',
      ja: 'ケニア',
      da: 'Kenya',
      fa: 'کنیا',
    },
    dial_code: '+254',
    code: 'KE',
    flag: '🇰🇪',
  },
  {
    name: {
      en: 'Kiribati',
      hu: 'Kiribati',
      id: 'Kiribati',
      ru: 'Кирибати',
      pl: 'Kiribati',
      uk: 'Кірибаті',
      cz: 'Kiribati',
      by: 'Кірыбаці',
      pt: 'Quiribáti',
      es: 'Kiribati',
      ro: 'Kiribati',
      bg: 'Кирибати',
      de: 'Kiribati',
      fr: 'Kiribati',
      nl: 'Kiribati',
      it: 'Kiribati',
      cn: '基里巴斯',
      ee: 'Kiribati',
      ja: 'キリバス',
      da: 'Kiribati',
      fa: 'کیریباتی',
    },
    dial_code: '+686',
    code: 'KI',
    flag: '🇰🇮',
  },
  {
    name: {
      en: "Korea, Democratic People's Republic of",
      hu: 'Észak-Korea',
      id: 'Korea, Republik Rakyat Demokratik',
      ru: 'Корея, Народно-Демократическая Республика',
      pl: 'Korea, Republika Ludowo-Demokratyczna',
      uk: 'Корея, Народно-Демократична Республіка',
      cz: 'Korea, Demokratická lidová republika',
      by: 'Карэя, Народна-Дэмакратычная Рэспубліка',
      pt: 'Coreia do Norte',
      es: 'Corea, República Popular Democrática de',
      ro: 'Coreea, Republica Populară Democrată din',
      bg: 'Корея, Демократична народна република',
      de: 'Korea, Demokratische Volksrepublik',
      fr: 'République populaire démocratique de Corée',
      nl: 'Korea, Democratische Volksrepubliek',
      it: 'Corea, Repubblica Popolare Democratica di',
      cn: '韩国，朝鲜民主主义人民共和国',
      ee: 'Korea, Korea Rahvademokraatlik Vabariik',
      ja: '韓国、朝鮮民主主義人民共和国',
      da: 'Korea, Demokratiske Folkerepublik',
      fa: 'کره، جمهوری دموکراتیک خلقی کره',
    },
    dial_code: '+850',
    code: 'KP',
    flag: '🇰🇵',
  },
  {
    name: {
      en: 'Korea, Republic of',
      hu: 'Dél-Korea',
      id: 'Korea, Republik',
      ru: 'Республика Корея',
      pl: 'Republika Korei',
      uk: 'Корея, Республіка',
      cz: 'Korea, republika',
      by: 'Карэя, Рэспубліка',
      pt: 'Coreia do Sul',
      es: 'Corea, República de',
      ro: 'Republica Coreea',
      bg: 'Република Корея',
      de: 'Korea, Republik von',
      fr: 'Corée, République de',
      nl: 'Korea, republiek van',
      it: 'Corea, Repubblica di',
      cn: '韩国',
      ee: 'Korea Vabariik',
      ja: '大韓民国',
      da: 'Korea, Republik',
      fa: 'کره، جمهوری',
    },
    dial_code: '+82',
    code: 'KR',
    flag: '🇰🇷',
  },
  {
    name: {
      en: 'Kuwait',
      hu: 'Kuvait',
      id: 'Kuwait',
      ru: 'Кувейт',
      pl: 'Kuwejt',
      uk: 'Кувейт',
      cz: 'Kuvajt',
      by: 'Кувейт',
      pt: 'Kuwait',
      es: 'Kuwait',
      ro: 'Kuweit',
      bg: 'Кувейт',
      de: 'Kuwait',
      fr: 'Koweit',
      nl: 'Koeweit',
      it: 'Kuwait',
      cn: '科威特',
      ee: 'Kuveit',
      ja: 'クウェート',
      da: 'Kuwait',
      fa: 'کویت',
    },
    dial_code: '+965',
    code: 'KW',
    flag: '🇰🇼',
  },
  {
    name: {
      en: 'Kyrgyzstan',
      hu: 'Kirgizisztán',
      id: 'Kirgizstan',
      ru: 'Кыргызстан',
      pl: 'Kirgistan',
      uk: 'Киргизстан',
      cz: 'Kyrgyzstán',
      by: 'Кыргызстан',
      pt: 'Quirguizistão',
      es: 'Kirguistán',
      ro: 'Kârgâzstan',
      bg: 'Киргизстан',
      de: 'Kirgisistan',
      fr: 'Kirghizistan',
      nl: 'Kirgizië',
      it: 'Kirghizistan',
      cn: '吉尔吉斯斯坦',
      ee: 'Kõrgõzstan',
      ja: 'キルギスタン',
      da: 'Kirgisistan',
      fa: 'قرقیزستان',
    },
    dial_code: '+996',
    code: 'KG',
    flag: '🇰🇬',
  },
  {
    name: {
      en: 'Laos',
      hu: 'Laosz',
      id: 'Laos',
      ru: 'Лаосская Народно-Демократическая Республика',
      pl: 'Laotańska Republika Ludowo-Demokratyczna',
      uk: 'Лаоська Народно-Демократична Республіка',
      cz: 'Laoská lidově demokratická republika',
      by: 'Лаоская Народна-Дэмакратычная Рэспубліка',
      pt: 'Laos',
      es: 'República Democrática Popular de Laos',
      ro: 'Republica Populară Democrată Laos',
      bg: 'Лаосска народнодемократична република',
      de: 'Demokratische Volksrepublik Laos',
      fr: 'République démocratique populaire lao',
      nl: 'Lao Democratische Volksrepubliek',
      it: 'Repubblica Democratica Popolare del Laos',
      cn: '老挝人民民主共和国',
      ee: 'Laose Demokraatlik Rahvavabariik',
      ja: 'ラオス人民民主共和国',
      da: 'Laos',
      fa: 'لائوس',
    },
    dial_code: '+856',
    code: 'LA',
    flag: '🇱🇦',
  },
  {
    name: {
      en: 'Latvia',
      hu: 'Lettország',
      id: 'Latvia',
      ru: 'Латвия',
      pl: 'Łotwa',
      uk: 'Латвія',
      cz: 'Lotyšsko',
      by: 'Латвія',
      pt: 'Letónia',
      es: 'Letonia',
      ro: 'Letonia',
      bg: 'Латвия',
      de: 'Lettland',
      fr: 'Lettonie',
      nl: 'Letland',
      it: 'Lettonia',
      cn: '拉脱维亚',
      ee: 'Läti',
      ja: 'ラトビア',
      da: 'Letland',
      fa: 'لتونی',
    },
    dial_code: '+371',
    code: 'LV',
    flag: '🇱🇻',
  },
  {
    name: {
      en: 'Lebanon',
      hu: 'Libanon',
      id: 'Lebanon',
      ru: 'Ливан',
      pl: 'Liban',
      uk: 'Ліван',
      cz: 'Libanon',
      by: 'Ліван',
      pt: 'Líbano',
      es: 'Líbano',
      ro: 'Liban',
      bg: 'Ливан',
      de: 'Libanon',
      fr: 'Liban',
      nl: 'Libanon',
      it: 'Libano',
      cn: '黎巴嫩的',
      ee: 'Liibanon',
      ja: 'レバノン',
      da: 'Libanon',
      fa: 'لبنان',
    },
    dial_code: '+961',
    code: 'LB',
    flag: '🇱🇧',
  },
  {
    name: {
      en: 'Lesotho',
      hu: 'Lesotho',
      id: 'Lesotho',
      ru: 'Лесото',
      pl: 'Lesoto',
      uk: 'Лесото',
      cz: 'Lesotho',
      by: 'Лесота',
      pt: 'Lesoto',
      es: 'Lesotho',
      ro: 'Lesotho',
      bg: 'Лесото',
      de: 'Lesotho',
      fr: 'Lesotho',
      nl: 'Lesotho',
      it: 'Lesotho',
      cn: '莱索托',
      ee: 'Lesotho',
      ja: 'レソト',
      da: 'Lesotho',
      fa: 'لسوتو',
    },
    dial_code: '+266',
    code: 'LS',
    flag: '🇱🇸',
  },
  {
    name: {
      en: 'Liberia',
      hu: 'Libéria',
      id: 'Liberia',
      ru: 'Либерия',
      pl: 'Liberia',
      uk: 'Ліберія',
      cz: 'Libérie',
      by: 'Ліберыя',
      pt: 'Libéria',
      es: 'Liberia',
      ro: 'Liberia',
      bg: 'Либерия',
      de: 'Liberia',
      fr: 'Libéria',
      nl: 'Liberia',
      it: 'Liberia',
      cn: '利比里亚',
      ee: 'Libeeria',
      ja: 'リベリア',
      da: 'Liberia',
      fa: 'لیبریا',
    },
    dial_code: '+231',
    code: 'LR',
    flag: '🇱🇷',
  },
  {
    name: {
      en: 'Libya',
      hu: 'Líbia',
      id: 'Libya',
      ru: 'Ливийская Арабская Джамахирия',
      pl: 'Libijska Arabska Dżamahirija',
      uk: 'Лівійська Арабська Джамахірія',
      cz: 'Libyjský Arab Jamahiriya',
      by: 'Лівійская Арабская Джамахірыя',
      pt: 'Líbia',
      es: 'Jamahiriya Árabe Libia',
      ro: 'Jamahiriya arabă libiană',
      bg: 'Либийска арабска Джамахирия',
      de: 'Libyscher arabischer Jamahiriya',
      fr: 'Libye',
      nl: 'Libië',
      it: 'Giamahiria araba libica',
      cn: '阿拉伯利比亚民众国',
      ee: 'Liibüa Araabia Jamahiriya',
      ja: 'リビアアラブジャマヒリヤ',
      da: 'Libyen',
      fa: 'لیبی',
    },
    dial_code: '+218',
    code: 'LY',
    flag: '🇱🇾',
  },
  {
    name: {
      en: 'Liechtenstein',
      hu: 'Liechtenstein',
      id: 'Liechtenstein',
      ru: 'Лихтенштейн',
      pl: 'Liechtenstein',
      uk: 'Ліхтенштейн',
      cz: 'Lichtenštejnsko',
      by: 'Ліхтэнштэйн',
      pt: 'Listenstaine',
      es: 'Liechtenstein',
      ro: 'Liechtenstein',
      bg: 'Лихтенщайн',
      de: 'Liechtenstein',
      fr: 'Liechtenstein',
      nl: 'Liechtenstein',
      it: 'Liechtenstein',
      cn: '列支敦士登',
      ee: 'Liechtenstein',
      ja: 'リヒテンシュタイン',
      da: 'Liechtenstein',
      fa: 'لیختن اشتاین',
    },
    dial_code: '+423',
    code: 'LI',
    flag: '🇱🇮',
  },
  {
    name: {
      en: 'Lithuania',
      hu: 'Litvánia',
      id: 'Lithuania',
      ru: 'Литва',
      pl: 'Litwa',
      uk: 'Литва',
      cz: 'Litva',
      by: 'Літва',
      pt: 'Lituânia',
      es: 'Lituania',
      ro: 'Lituania',
      bg: 'Литва',
      de: 'Litauen',
      fr: 'Lituanie',
      nl: 'Litouwen',
      it: 'Lituania',
      cn: '立陶宛',
      ee: 'Leedu',
      ja: 'リトアニア',
      da: 'Litauen',
      fa: 'لیتوانی',
    },
    dial_code: '+370',
    code: 'LT',
    flag: '🇱🇹',
  },
  {
    name: {
      en: 'Luxembourg',
      hu: 'Luxemburg',
      id: 'Luksemburg',
      ru: 'Люксембург',
      pl: 'Luksemburg',
      uk: 'Люксембург',
      cz: 'Lucembursko',
      by: 'Люксембург',
      pt: 'Luxemburgo',
      es: 'Luxemburgo',
      ro: 'Luxemburg',
      bg: 'Люксембург',
      de: 'Luxemburg',
      fr: 'Luxembourg',
      nl: 'Luxemburg',
      it: 'Lussemburgo',
      cn: '卢森堡',
      ee: 'Luksemburg',
      ja: 'ルクセンブルク',
      da: 'Luxembourg',
      fa: 'لوکزامبورگ',
    },
    dial_code: '+352',
    code: 'LU',
    flag: '🇱🇺',
  },
  {
    name: {
      en: 'Macao',
      hu: 'Makaó',
      id: 'Makau',
      ru: 'Макао',
      pl: 'Makao',
      uk: 'Макао',
      cz: 'Macao',
      by: 'Макао',
      pt: 'Macau',
      es: 'Macao',
      ro: 'Macao',
      bg: 'Макао',
      de: 'Macao',
      fr: 'Macao',
      nl: 'Macao',
      it: 'Macao',
      cn: '澳门',
      ee: 'Aomen',
      ja: 'マカオ',
      da: 'Macao',
      fa: 'ماکائو',
    },
    dial_code: '+853',
    code: 'MO',
    flag: '🇲🇴',
  },
  {
    name: {
      en: 'North Macedonia',
      hu: 'Észak-Macedónia',
      id: 'Makedonia Utara',
      ru: 'Северная Македония',
      pl: 'Macedonia Północna',
      uk: 'Північна Македонія',
      cz: 'Severní Makedonie',
      by: 'Паўночная Македонія',
      pt: 'Macedônia do Norte',
      es: 'Macedonia del Norte',
      ro: 'Macedonia de Nord',
      bg: 'Северна Македония',
      de: 'Nordmazedonien',
      fr: 'Macédoine du Nord',
      nl: 'Noord-Macedonië',
      it: 'Macedonia del Nord',
      cn: '北马其顿',
      ee: 'Põhja-Makedoonia',
      ja: '北マケドニア',
      da: 'Nordmakedonien',
      fa: 'مقدونیه شمالی',
    },
    dial_code: '+389',
    code: 'MK',
    flag: '🇲🇰',
  },
  {
    name: {
      en: 'Madagascar',
      hu: 'Madagaszkár',
      id: 'Madagaskar',
      ru: 'Мадагаскар',
      pl: 'Madagaskar',
      uk: 'Мадагаскар',
      cz: 'Madagaskar',
      by: 'Мадагаскар',
      pt: 'Madagáscar',
      es: 'Madagascar',
      ro: 'Madagascar',
      bg: 'Мадагаскар',
      de: 'Madagaskar',
      fr: 'Madagascar',
      nl: 'Madagascar',
      it: 'Madagascar',
      cn: '马达加斯加',
      ee: 'Madagaskar',
      ja: 'マダガスカル',
      da: 'Madagaskar',
      fa: 'ماداگاسکار',
    },
    dial_code: '+261',
    code: 'MG',
    flag: '🇲🇬',
  },
  {
    name: {
      en: 'Malawi',
      hu: 'Malawi',
      id: 'Malawi',
      ru: 'Малави',
      pl: 'Malawi',
      uk: 'Малаві',
      cz: 'Malawi',
      by: 'Малаві',
      pt: 'Malávi',
      es: 'Malawi',
      ro: 'Malawi',
      bg: 'Малави',
      de: 'Malawi',
      fr: 'Malawi',
      nl: 'Malawi',
      it: 'Malawi',
      cn: '马拉维',
      ee: 'Malawi',
      ja: 'マラウイ',
      da: 'Malawi',
      fa: 'مالاوی',
    },
    dial_code: '+265',
    code: 'MW',
    flag: '🇲🇼',
  },
  {
    name: {
      en: 'Malaysia',
      hu: 'Malajzia',
      id: 'Malaysia',
      ru: 'Малайзия',
      pl: 'Malezja',
      uk: 'Малайзія',
      cz: 'Malajsie',
      by: 'Малайзія',
      pt: 'Malásia',
      es: 'Malasia',
      ro: 'Malaezia',
      bg: 'Малайзия',
      de: 'Malaysia',
      fr: 'Malaisie',
      nl: 'Maleisië',
      it: 'Malaysia',
      cn: '马来西亚',
      ee: 'Malaisia',
      ja: 'マレーシア',
      da: 'Malaysia',
      fa: 'مالزی',
    },
    dial_code: '+60',
    code: 'MY',
    flag: '🇲🇾',
  },
  {
    name: {
      en: 'Maldives',
      hu: 'Maldív-szigetek',
      id: 'Maladewa',
      ru: 'Мальдивы',
      pl: 'Malediwy',
      uk: 'Мальдіви',
      cz: 'Maledivy',
      by: 'Мальдывы',
      pt: 'Maldivas',
      es: 'Maldivas',
      ro: 'Maldive',
      bg: 'Малдивите',
      de: 'Malediven',
      fr: 'Maldives',
      nl: 'Maldiven',
      it: 'Maldive',
      cn: '马尔代夫',
      ee: 'Maldiivid',
      ja: 'モルディブ',
      da: 'Maldiverne',
      fa: 'مالدیو',
    },
    dial_code: '+960',
    code: 'MV',
    flag: '🇲🇻',
  },
  {
    name: {
      en: 'Mali',
      hu: 'Mali',
      id: 'Mali',
      ru: 'Мали',
      pl: 'Mali',
      uk: 'Малі',
      cz: 'Mali',
      by: 'Малі',
      pt: 'Mali',
      es: 'Malí',
      ro: 'Mali',
      bg: 'Мали',
      de: 'Mali',
      fr: 'Mali',
      nl: 'Mali',
      it: 'Mali',
      cn: '马里',
      ee: 'Mali',
      ja: 'マリ',
      da: 'Mali',
      fa: 'مالی',
    },
    dial_code: '+223',
    code: 'ML',
    flag: '🇲🇱',
  },
  {
    name: {
      en: 'Malta',
      hu: 'Málta',
      id: 'Malta',
      ru: 'Мальта',
      pl: 'Malta',
      uk: 'Мальта',
      cz: 'Malta',
      by: 'Мальта',
      pt: 'Malta',
      es: 'Malta',
      ro: 'Malta',
      bg: 'Малта',
      de: 'Malta',
      fr: 'Malte',
      nl: 'Malta',
      it: 'Malta',
      cn: '马耳他',
      ee: 'Malta',
      ja: 'マルタ',
      da: 'Malta',
      fa: 'مالت',
    },
    dial_code: '+356',
    code: 'MT',
    flag: '🇲🇹',
  },
  {
    name: {
      en: 'Marshall Islands',
      hu: 'Marshall-szigetek',
      id: 'Kepulauan Marshall',
      ru: 'Маршалловы острова',
      pl: 'Wyspy Marshalla',
      uk: 'Маршаллові острови',
      cz: 'Marshallovy ostrovy',
      by: 'Маршалавы выспы',
      pt: 'Ilhas Marshall',
      es: 'Islas Marshall',
      ro: 'Insulele Marshall',
      bg: 'Маршалови острови',
      de: 'Marshallinseln',
      fr: 'Iles Marshall',
      nl: 'Marshall eilanden',
      it: 'Isole Marshall',
      cn: '马绍尔群岛',
      ee: 'Marshalli saared',
      ja: 'マーシャル諸島',
      da: 'Marshalløerne',
      fa: 'جزایر مارشال',
    },
    dial_code: '+692',
    code: 'MH',
    flag: '🇲🇭',
  },
  {
    name: {
      en: 'Martinique',
      hu: 'Martinique',
      id: 'Martinik',
      ru: 'Мартиника',
      pl: 'Martynika',
      uk: 'Мартініка',
      cz: 'Martinik',
      by: 'Марцініка',
      pt: 'Martinica',
      es: 'Martinica',
      ro: 'Martinica',
      bg: 'Мартиника',
      de: 'Martinique',
      fr: 'Martinique',
      nl: 'Martinique',
      it: 'Martinica',
      cn: '马提尼克岛',
      ee: 'Martinique',
      ja: 'マルティニーク',
      da: 'Martinique',
      fa: 'مارتینیک',
    },
    dial_code: '+596',
    code: 'MQ',
    flag: '🇲🇶',
  },
  {
    name: {
      en: 'Mauritania',
      hu: 'Mauritánia',
      id: 'Mauritania',
      ru: 'Мавритания',
      pl: 'Mauretania',
      uk: 'Мавританія',
      cz: 'Mauretánie',
      by: 'Маўрытанія',
      pt: 'Mauritânia',
      es: 'Mauritania',
      ro: 'Mauritania',
      bg: 'Мавритания',
      de: 'Mauretanien',
      fr: 'Mauritanie',
      nl: 'Mauritanië',
      it: 'Mauritania',
      cn: '毛里塔尼亚',
      ee: 'Mauritaania',
      ja: 'モーリタニア',
      da: 'Mauretanien',
      fa: 'موریتانی',
    },
    dial_code: '+222',
    code: 'MR',
    flag: '🇲🇷',
  },
  {
    name: {
      en: 'Mauritius',
      hu: 'Mauritius',
      id: 'Mauritius',
      ru: 'Маврикий',
      pl: 'Mauritius',
      uk: 'Маврикій',
      cz: 'Mauricius',
      by: 'Маўрыкій',
      pt: 'Maurícia',
      es: 'Mauricio',
      ro: 'Mauritius',
      bg: 'Мавриций',
      de: 'Mauritius',
      fr: 'Ile Maurice',
      nl: 'Mauritius',
      it: 'Maurizio',
      cn: '毛里求斯',
      ee: 'Mauritius',
      ja: 'モーリシャス',
      da: 'Mauritius',
      fa: 'موریس',
    },
    dial_code: '+230',
    code: 'MU',
    flag: '🇲🇺',
  },
  {
    name: {
      en: 'Mayotte',
      hu: 'Mayotte',
      id: 'Mayotte',
      ru: 'Майотта',
      pl: 'Majotta',
      uk: 'Майотта',
      cz: 'Mayotte',
      by: 'Маёта',
      pt: 'Mayotte',
      es: 'Mayotte',
      ro: 'Mayotte',
      bg: 'Майот',
      de: 'Mayotte',
      fr: 'Mayotte',
      nl: 'Mayotte',
      it: 'Mayotte',
      cn: '马约特岛',
      ee: 'Mayotte',
      ja: 'マヨット',
      da: 'Mayotte',
      fa: 'مایوت',
    },
    dial_code: '+262',
    code: 'YT',
    flag: '🇾🇹',
  },
  {
    name: {
      en: 'Mexico',
      hu: 'Mexikó',
      id: 'Meksiko',
      ru: 'Мексика',
      pl: 'Meksyk',
      uk: 'Мексика',
      cz: 'Mexiko',
      by: 'Мексіка',
      pt: 'México',
      es: 'México',
      ro: 'Mexic',
      bg: 'Мексико',
      de: 'Mexiko',
      fr: 'Mexique',
      nl: 'Mexico',
      it: 'Messico',
      cn: '墨西哥',
      ee: 'Mehhiko',
      ja: 'メキシコ',
      da: 'Mexico',
      fa: 'مکزیک',
    },
    dial_code: '+52',
    code: 'MX',
    flag: '🇲🇽',
  },
  {
    name: {
      en: 'Micronesia, Federated States of',
      hu: 'Mikronézia',
      id: 'Mikronesia, Negara Federasi',
      ru: 'Микронезия, Федеративные Штаты',
      pl: 'Mikronezja, Sfederowane Stany',
      uk: 'Мікронезія, Федеративні Штати',
      cz: 'Mikronésie, federativní státy',
      by: 'Мікранезія, Федэратыўныя Штаты',
      pt: 'Micronésia',
      es: 'Micronesia, Estados Federados de',
      ro: 'Micronezia, Statele Federate ale',
      bg: 'Микронезия, Федеративни щати',
      de: 'Mikronesien, Föderierte Staaten von',
      fr: 'Micronésie, États fédérés de',
      nl: 'Micronesië, Federale Staten van',
      it: 'Micronesia, Stati federati di',
      cn: '密克罗尼西亚联邦',
      ee: 'Mikroneesia, Makedoonia Liiduriigid',
      ja: 'ミクロネシア連邦',
      da: 'Mikronesien, Forbundsstaterne',
      fa: 'ایالات فدرال میکرونزی',
    },
    dial_code: '+691',
    code: 'FM',
    flag: '🇫🇲',
  },
  {
    name: {
      en: 'Moldova, Republic of',
      hu: 'Moldova',
      id: 'Moldova, Republik',
      ru: 'Молдова, Республика',
      pl: 'Mołdawia, Republika',
      uk: 'Молдова, республіка',
      cz: 'Moldavsko, republika',
      by: 'Малдова, Рэспубліка',
      pt: 'Moldávia',
      es: 'Moldavia, República de',
      ro: 'Moldova, Republica',
      bg: 'Молдова, Република',
      de: 'Moldawien, Republik',
      fr: 'Moldova, République de',
      nl: 'Moldavië, Republiek',
      it: 'Moldova, Repubblica di',
      cn: '摩尔多瓦共和国',
      ee: 'Moldova Vabariik',
      ja: 'モルドバ共和国',
      da: 'Moldova, Republik',
      fa: 'مولداوی، جمهوری',
    },
    dial_code: '+373',
    code: 'MD',
    flag: '🇲🇩',
  },
  {
    name: {
      en: 'Monaco',
      hu: 'Monaco',
      id: 'Monako',
      ru: 'Монако',
      pl: 'Monako',
      uk: 'Монако',
      cz: 'Monako',
      by: 'Манака',
      pt: 'Mónaco',
      es: 'Mónaco',
      ro: 'Monaco',
      bg: 'Монако',
      de: 'Monaco',
      fr: 'Monaco',
      nl: 'Monaco',
      it: 'Monaco',
      cn: '摩纳哥',
      ee: 'Monaco',
      ja: 'モナコ',
      da: 'Monaco',
      fa: 'موناکو',
    },
    dial_code: '+377',
    code: 'MC',
    flag: '🇲🇨',
  },
  {
    name: {
      en: 'Mongolia',
      hu: 'Mongólia',
      id: 'Mongolia',
      ru: 'Монголия',
      pl: 'Mongolia',
      uk: 'Монголія',
      cz: 'Mongolsko',
      by: 'Манголія',
      pt: 'Mongólia',
      es: 'Mongolia',
      ro: 'Mongolia',
      bg: 'Монголия',
      de: 'Mongolei',
      fr: 'Mongolie',
      nl: 'Mongolië',
      it: 'Mongolia',
      cn: '蒙古',
      ee: 'Mongoolia',
      ja: 'モンゴル',
      da: 'Mongoliet',
      fa: 'مغولستان',
    },
    dial_code: '+976',
    code: 'MN',
    flag: '🇲🇳',
  },
  {
    name: {
      en: 'Montenegro',
      hu: 'Montenegró',
      id: 'Montenegro',
      ru: 'Черногория',
      pl: 'Czarnogóra',
      uk: 'Чорногорія',
      cz: 'Černá Hora',
      by: 'Чарнагорыя',
      pt: 'Montenegro',
      es: 'Montenegro',
      ro: 'Muntenegru',
      bg: 'Черна гора',
      de: 'Montenegro',
      fr: 'Monténégro',
      nl: 'Montenegro',
      it: 'Montenegro',
      cn: '黑山共和国',
      ee: 'Montenegro',
      ja: 'モンテネグロ',
      da: 'Montenegro',
      fa: 'مونته‌نگرو',
    },
    dial_code: '+382',
    code: 'ME',
    flag: '🇲🇪',
  },
  {
    name: {
      en: 'Montserrat',
      hu: 'Montserrat',
      id: 'Montserrat',
      ru: 'Монтсеррат',
      pl: 'Montserrat',
      uk: 'Монтсеррат',
      cz: 'Montserrat',
      by: 'Мансерат',
      pt: 'Monserrate',
      es: 'Montserrat',
      ro: 'Montserrat',
      bg: 'Монсерат',
      de: 'Montserrat',
      fr: 'Montserrat',
      nl: 'Montserrat',
      it: 'Montserrat',
      cn: '蒙特塞拉特',
      ee: 'Montserrat',
      ja: 'モントセラト',
      da: 'Montserrat',
      fa: 'مونتسرات',
    },
    dial_code: '+1664',
    code: 'MS',
    flag: '🇲🇸',
  },
  {
    name: {
      en: 'Morocco',
      hu: 'Marokkó',
      id: 'Maroko',
      ru: 'Марокко',
      pl: 'Maroko',
      uk: 'Марокко',
      cz: 'Maroko',
      by: 'Марока',
      pt: 'Marrocos',
      es: 'Marruecos',
      ro: 'Maroc',
      bg: 'Мароко',
      de: 'Marokko',
      fr: 'Maroc',
      nl: 'Marokko',
      it: 'Marocco',
      cn: '摩洛哥',
      ee: 'Maroko',
      ja: 'モロッコ',
      da: 'Marokko',
      fa: 'مراکش',
    },
    dial_code: '+212',
    code: 'MA',
    flag: '🇲🇦',
  },
  {
    name: {
      en: 'Mozambique',
      hu: 'Mozambik',
      id: 'Mozambik',
      ru: 'Мозамбик',
      pl: 'Mozambik',
      uk: 'Мозамбік',
      cz: 'Mosambik',
      by: 'Мазамбік',
      pt: 'Moçambique',
      es: 'Mozambique',
      ro: 'Mozambic',
      bg: 'Мозамбик',
      de: 'Mosambik',
      fr: 'Mozambique',
      nl: 'Mozambique',
      it: 'Mozambico',
      cn: '莫桑比克',
      ee: 'Mosambiik',
      ja: 'モザンビーク',
      da: 'Mozambique',
      fa: 'موزامبیک',
    },
    dial_code: '+258',
    code: 'MZ',
    flag: '🇲🇿',
  },
  {
    name: {
      en: 'Myanmar',
      hu: 'Mianmar',
      id: 'Myanmar',
      ru: 'Мьянма',
      pl: 'Myanmar',
      uk: "М'янма",
      cz: 'Myanmar',
      by: "М'янма",
      pt: 'Birmânia',
      es: 'Myanmar',
      ro: 'Myanmar',
      bg: 'Мианмар',
      de: 'Myanmar',
      fr: 'Myanmar',
      nl: 'Myanmar',
      it: 'Myanmar',
      cn: '缅甸',
      ee: 'Myanmar',
      ja: 'ミャンマー',
      da: 'Myanmar',
      fa: 'میانمار',
    },
    dial_code: '+95',
    code: 'MM',
    flag: '🇲🇲',
  },
  {
    name: {
      en: 'Namibia',
      hu: 'Namíbia',
      id: 'Namibia',
      ru: 'Намибия',
      pl: 'Namibia',
      uk: 'Намібія',
      cz: 'Namibie',
      by: 'Намібія',
      pt: 'Namíbia',
      es: 'Namibia',
      ro: 'Namibia',
      bg: 'Намибия',
      de: 'Namibia',
      fr: 'Namibie',
      nl: 'Namibië',
      it: 'Namibia',
      cn: '纳米比亚',
      ee: 'Namiibia',
      ja: 'ナミビア',
      da: 'Namibia',
      fa: 'نامیبیا',
    },
    dial_code: '+264',
    code: 'NA',
    flag: '🇳🇦',
  },
  {
    name: {
      en: 'Nauru',
      hu: 'Nauru',
      id: 'Nauru',
      ru: 'Науру',
      pl: 'Nauru',
      uk: 'Науру',
      cz: 'Nauru',
      by: 'Науру',
      pt: 'Nauru',
      es: 'Nauru',
      ro: 'Nauru',
      bg: 'Науру',
      de: 'Nauru',
      fr: 'Nauru',
      nl: 'Nauru',
      it: 'Nauru',
      cn: '瑙鲁',
      ee: 'Nauru',
      ja: 'ナウル',
      da: 'Nauru',
      fa: 'نائورو',
    },
    dial_code: '+674',
    code: 'NR',
    flag: '🇳🇷',
  },
  {
    name: {
      en: 'Nepal',
      hu: 'Nepál',
      id: 'Nepal',
      ru: 'Непал',
      pl: 'Nepal',
      uk: 'Непал',
      cz: 'Nepál',
      by: 'Непал',
      pt: 'Nepal',
      es: 'Nepal',
      ro: 'Nepal',
      bg: 'Непал',
      de: 'Nepal',
      fr: 'Népal',
      nl: 'Nepal',
      it: 'Nepal',
      cn: '尼尼泊尔',
      ee: 'Nepal',
      ja: 'ネパール',
      da: 'Nepal',
      fa: 'نپال',
    },
    dial_code: '+977',
    code: 'NP',
    flag: '🇳🇵',
  },
  {
    name: {
      en: 'Netherlands',
      hu: 'Hollandia',
      id: 'Belanda',
      ru: 'Нидерланды',
      pl: 'Holandia',
      uk: 'Нідерланди',
      cz: 'Holandsko',
      by: 'Нідэрланды',
      pt: 'Países Baixos',
      es: 'Países Bajos',
      ro: 'Olanda',
      bg: 'Холандия',
      de: 'Niederlande',
      fr: 'Pays-Bas',
      nl: 'Nederland',
      it: 'Olanda',
      cn: '荷兰',
      ee: 'Holland',
      ja: 'オランダ',
      da: 'Holland',
      fa: 'هلند',
    },
    dial_code: '+31',
    code: 'NL',
    flag: '🇳🇱',
  },
  {
    name: {
      en: 'New Caledonia',
      hu: 'Új-Kaledónia',
      id: 'Kaledonia Baru',
      ru: 'Новая Каледония',
      pl: 'Nowa Kaledonia',
      uk: 'Нова Каледонія',
      cz: 'Nová Kaledonie',
      by: 'Новая Каледонія',
      pt: 'Nova Caledónia',
      es: 'Nueva Caledonia',
      ro: 'Noua Caledonie',
      bg: 'Нова Каледония',
      de: 'Neu-Kaledonien',
      fr: 'Nouvelle Calédonie',
      nl: 'Nieuw-Caledonië',
      it: 'Nuova Caledonia',
      cn: '新喀里多尼亚',
      ee: 'Uus-Kaledoonia',
      ja: 'ニューカレドニア',
      da: 'Ny Kaledonien',
      fa: 'کالدونیای جدید',
    },
    dial_code: '+687',
    code: 'NC',
    flag: '🇳🇨',
  },
  {
    name: {
      en: 'New Zealand',
      hu: 'Új-Zéland',
      id: 'Selandia Baru',
      ru: 'Новая Зеландия',
      pl: 'Nowa Zelandia',
      uk: 'Нова Зеландія',
      cz: 'Nový Zéland',
      by: 'Новая Зеландыя',
      pt: 'Nova Zelândia',
      es: 'Nueva Zelanda',
      ro: 'Noua Zeelanda',
      bg: 'Нова Зеландия',
      de: 'Neuseeland',
      fr: 'Nouvelle-Zélande',
      nl: 'Nieuw-Zeeland',
      it: 'Nuova Zelanda',
      cn: '新西兰',
      ee: 'Uus-Meremaa',
      ja: 'ニュージーランド',
      da: 'New Zealand',
      fa: 'نیوزیلند',
    },
    dial_code: '+64',
    code: 'NZ',
    flag: '🇳🇿',
  },
  {
    name: {
      en: 'Nicaragua',
      hu: 'Nicaragua',
      id: 'Nikaragua',
      ru: 'Никарагуа',
      pl: 'Nikaragua',
      uk: 'Нікарагуа',
      cz: 'Nikaragua',
      by: 'Нікарагуа',
      pt: 'Nicarágua',
      es: 'Nicaragua',
      ro: 'Nicaragua',
      bg: 'Никарагуа',
      de: 'Nicaragua',
      fr: 'Nicaragua',
      nl: 'Nicaragua',
      it: 'Nicaragua',
      cn: '尼加拉瓜',
      ee: 'Nicaragua',
      ja: 'ニカラグア',
      da: 'Nicaragua',
      fa: 'نیکاراگوئه',
    },
    dial_code: '+505',
    code: 'NI',
    flag: '🇳🇮',
  },
  {
    name: {
      en: 'Niger',
      hu: 'Niger',
      id: 'Niger',
      ru: 'Нигер',
      pl: 'Niger',
      uk: 'Нігер',
      cz: 'Niger',
      by: 'Нігер',
      pt: 'Níger',
      es: 'Níger',
      ro: 'Niger',
      bg: 'Нигер',
      de: 'Niger',
      fr: 'Niger',
      nl: 'Niger',
      it: 'Niger',
      cn: '尼日尔',
      ee: 'Niger',
      ja: 'ニジェール',
      da: 'Niger',
      fa: 'نیجر',
    },
    dial_code: '+227',
    code: 'NE',
    flag: '🇳🇪',
  },
  {
    name: {
      en: 'Nigeria',
      hu: 'Nigéria',
      ru: 'Нигерия',
      pl: 'Nigeria',
      uk: 'Нігерія',
      cz: 'Nigérie',
      by: 'Нігерыя',
      pt: 'Nigéria',
      es: 'Nigeria',
      ro: 'Nigeria',
      bg: 'Нигерия',
      de: 'Nigeria',
      fr: 'Nigeria',
      nl: 'Nigeria',
      it: 'Nigeria',
      cn: '奈及利亚',
      ee: 'Nigeeria',
      ja: 'ナイジェリア',
      da: 'Nigeria',
      fa: 'نیجریه',
    },
    dial_code: '+234',
    code: 'NG',
    flag: '🇳🇬',
  },
  {
    name: {
      en: 'Niue',
      hu: 'Niue',
      id: 'Niue',
      ru: 'Ниуэ',
      pl: 'Niue',
      uk: 'Ніуе',
      cz: 'Niue',
      by: 'Ніуе',
      pt: 'Niue',
      es: 'Niue',
      ro: 'Niue',
      bg: 'Ниуе',
      de: 'Niue',
      fr: 'Niue',
      nl: 'Niue',
      it: 'Niue',
      cn: '纽埃',
      ee: 'Niue',
      ja: 'ニウエ',
      da: 'Niue',
      fa: 'نیوئه',
    },
    dial_code: '+683',
    code: 'NU',
    flag: '🇳🇺',
  },
  {
    name: {
      en: 'Norfolk Island',
      hu: 'Norfolk-sziget',
      id: 'Pulau Norfolk',
      ru: 'Остров Норфолк',
      pl: 'Wyspa Norfolk',
      uk: 'Острів Норфолк',
      cz: 'Ostrov Norfolk',
      by: 'Востраў Норфолк',
      pt: 'Ilha Norfolk',
      es: 'Isla de Norfolk',
      ro: 'Insula Norfolk',
      bg: 'Остров Норфолк',
      de: 'Norfolkinsel',
      fr: "l'ile de Norfolk",
      nl: 'Norfolkeiland',
      it: 'Isola Norfolk',
      cn: '诺福克岛',
      ee: 'Norfolki saar',
      ja: 'ノーフォーク島',
      da: 'Norfolk Island',
      fa: 'جزیره نورفولک',
    },
    dial_code: '+672',
    code: 'NF',
    flag: '🇳🇫',
  },
  {
    name: {
      en: 'Northern Mariana Islands',
      hu: 'Északi-Mariana-szigetek',
      id: 'Kepulauan Mariana Utara',
      ru: 'Северные Марианские острова',
      pl: 'Mariany Północne',
      uk: 'Північні Маріанські острови',
      cz: 'Severní Mariany',
      by: 'Паўночныя Марыянскія астравы',
      pt: 'Marianas do Norte',
      es: 'Islas Marianas del Norte',
      ro: 'Insulele Marianelor de Nord',
      bg: 'Северни Мариански острови',
      de: 'Nördliche Marianneninseln',
      fr: 'Îles Mariannes du Nord',
      nl: 'noordelijke Mariana eilanden',
      it: 'Isole Marianne settentrionali',
      cn: '北马里亚纳群岛',
      ee: 'Põhja-Mariaanid',
      ja: '北マリアナ諸島',
      da: 'Nordmarianerne',
      fa: 'جزایر ماریانای شمالی',
    },
    dial_code: '+1670',
    code: 'MP',
    flag: '🇲🇵',
  },
  {
    name: {
      en: 'Norway',
      hu: 'Norvégia',
      id: 'Norwegia',
      ru: 'Норвегия',
      pl: 'Norwegia',
      uk: 'Норвегія',
      cz: 'Norsko',
      by: 'Нарвегія',
      pt: 'Noruega',
      es: 'Noruega',
      ro: 'Norvegia',
      bg: 'Норвегия',
      de: 'Norwegen',
      fr: 'Norvège',
      nl: 'Noorwegen',
      it: 'Norvegia',
      cn: '挪威',
      ee: 'Norra',
      ja: 'ノルウェー',
      da: 'Norge',
      fa: 'نروژ',
    },
    dial_code: '+47',
    code: 'NO',
    flag: '🇳🇴',
  },
  {
    name: {
      en: 'Oman',
      hu: 'Omán',
      id: 'Oman',
      ru: 'Оман',
      pl: 'Oman',
      uk: 'Оман',
      cz: 'Omán',
      by: 'Аман',
      pt: 'Omã',
      es: 'Omán',
      ro: 'Oman',
      bg: 'Оман',
      de: 'Oman',
      fr: 'Oman',
      nl: 'Oman',
      it: 'Oman',
      cn: '阿曼',
      ee: 'Omaan',
      ja: 'オマーン',
      da: 'Oman',
      fa: 'عمان',
    },
    dial_code: '+968',
    code: 'OM',
    flag: '🇴🇲',
  },
  {
    name: {
      en: 'Pakistan',
      hu: 'Pakisztán',
      id: 'Pakistan',
      ru: 'Пакистан',
      pl: 'Pakistan',
      uk: 'Пакистан',
      cz: 'Pákistán',
      by: 'Пакістан',
      pt: 'Paquistão',
      es: 'Pakistán',
      ro: 'Pakistan',
      bg: 'Пакистан',
      de: 'Pakistan',
      fr: 'Pakistan',
      nl: 'Pakistan',
      it: 'Pakistan',
      cn: '巴基斯坦',
      ee: 'Pakistan',
      ja: 'パキスタン',
      da: 'Pakistan',
      fa: 'پاکستان',
    },
    dial_code: '+92',
    code: 'PK',
    flag: '🇵🇰',
  },
  {
    name: {
      en: 'Palau',
      hu: 'Palau',
      id: 'Palau',
      ru: 'Палау',
      pl: 'Palau',
      uk: 'Палау',
      cz: 'Palau',
      by: 'Палаў',
      pt: 'Palau',
      es: 'Palau',
      ro: 'Palau',
      bg: 'Палау',
      de: 'Palau',
      fr: 'Palau',
      nl: 'Palau',
      it: 'Palau',
      cn: 'u琉',
      ee: 'Palau',
      ja: 'パラオ',
      da: 'Palau',
      fa: 'پالائو',
    },
    dial_code: '+680',
    code: 'PW',
    flag: '🇵🇼',
  },
  {
    name: {
      en: 'Palestine',
      hu: 'Palesztina',
      id: 'Palestina',
      ru: 'Государство Палестина',
      pl: 'Państwo Palestyna',
      uk: 'Держава Палестина',
      cz: 'Stát Palestina',
      by: 'Дзяржава Палестына',
      pt: 'Estado da Palestina',
      es: 'Estado de Palestina',
      ro: 'Statul Palestina',
      bg: 'Държава Палестина',
      de: 'Palästina',
      fr: 'Palestine',
      nl: 'Palestina',
      it: 'Palestina',
      cn: '巴勒斯坦国',
      ee: 'Palestiina',
      ja: 'パレスチナ',
      da: 'Palæstina',
      fa: 'فلسطین',
    },
    dial_code: '+970',
    code: 'PS',
    flag: '🇵🇸',
  },
  {
    name: {
      en: 'Panama',
      hu: 'Panama',
      id: 'Panama',
      ru: 'Панама',
      pl: 'Panama',
      uk: 'Панама',
      cz: 'Panama',
      by: 'Панама',
      pt: 'Panamá',
      es: 'Panamá',
      ro: 'Panama',
      bg: 'Панама',
      de: 'Panama',
      fr: 'Panama',
      nl: 'Panama',
      it: 'Panama',
      cn: '巴拿马',
      ee: 'Panama',
      ja: 'パナマ',
      da: 'Panama',
      fa: 'پاناما',
    },
    dial_code: '+507',
    code: 'PA',
    flag: '🇵🇦',
  },
  {
    name: {
      en: 'Papua New Guinea',
      hu: 'Pápua Új-Guinea',
      id: 'Papua Nugini',
      ru: 'Папуа - Новая Гвинея',
      pl: 'Papua Nowa Gwinea',
      uk: 'Папуа-Нова Гвінея',
      cz: 'Papua-Nová Guinea',
      by: 'Папуа-Новая Гвінея',
      pt: 'Papua-Nova Guiné',
      es: 'Papúa Nueva Guinea',
      ro: 'Papua Noua Guinee',
      bg: 'Папуа-Нова Гвинея',
      de: 'Papua Neu-Guinea',
      fr: 'Papouasie Nouvelle Guinée',
      nl: 'Papoea-Nieuw-Guinea',
      it: 'Papua Nuova Guinea',
      cn: '巴布亚新几内亚',
      ee: 'Paapua Uus-Guinea',
      ja: 'パプアニューギニア',
      da: 'Papua Ny Guinea',
      fa: 'پاپوآ گینه نو',
    },
    dial_code: '+675',
    code: 'PG',
    flag: '🇵🇬',
  },
  {
    name: {
      en: 'Paraguay',
      hu: 'Paraguay',
      id: 'Paraguay',
      ru: 'Парагвай',
      pl: 'Paragwaj',
      uk: 'Парагвай',
      cz: 'Paraguay',
      by: 'Парагвай',
      pt: 'Paraguai',
      es: 'Paraguay',
      ro: 'Paraguay',
      bg: 'Парагвай',
      de: 'Paraguay',
      fr: 'Paraguay',
      nl: 'Paraguay',
      it: 'Paraguay',
      cn: '巴拉圭',
      ee: 'Paraguay',
      ja: 'パラグアイ',
      da: 'Paraguay',
      fa: 'پاراگوئه',
    },
    dial_code: '+595',
    code: 'PY',
    flag: '🇵🇾',
  },
  {
    name: {
      en: 'Peru',
      hu: 'Peru',
      id: 'Peru',
      ru: 'Перу',
      pl: 'Peru',
      uk: 'Перу',
      cz: 'Peru',
      by: 'Перу',
      pt: 'Peru',
      es: 'Perú',
      ro: 'Peru',
      bg: 'Перу',
      de: 'Peru',
      fr: 'Pérou',
      nl: 'Peru',
      it: 'Perù',
      cn: '秘鲁',
      ee: 'Peruu',
      ja: 'ペルー',
      da: 'Peru',
      fa: 'پرو',
    },
    dial_code: '+51',
    code: 'PE',
    flag: '🇵🇪',
  },
  {
    name: {
      en: 'Philippines',
      hu: 'Fülöp-szigetek',
      id: 'Filipina',
      ru: 'Филиппины',
      pl: 'Filipiny',
      uk: 'Філіппіни',
      cz: 'Filipíny',
      by: 'Філіпіны',
      pt: 'Filipinas',
      es: 'Filipinas',
      ro: 'Filipine',
      bg: 'Филипините',
      de: 'Philippinen',
      fr: 'Philippines',
      nl: 'Filippijnen',
      it: 'Filippine',
      cn: '菲律宾',
      ee: 'Filipiinid',
      ja: 'フィリピン',
      da: 'Filippinerne',
      fa: 'فیلیپین',
    },
    dial_code: '+63',
    code: 'PH',
    flag: '🇵🇭',
  },
  {
    name: {
      en: 'Pitcairn',
      hu: 'Pitcairn-szigetek',
      id: 'Pitcairn',
      ru: 'Питкэрн',
      pl: 'Pitcairn',
      uk: 'Піткерн',
      cz: 'Pitcairn',
      by: 'Піткэрн',
      pt: 'Pitcairn',
      es: 'Pitcairn',
      ro: 'Pitcairn',
      bg: 'Питкерн',
      de: 'Pitcairn',
      fr: 'Pitcairn',
      nl: 'Pitcairn',
      it: 'Pitcairn',
      cn: '皮特凯恩',
      ee: 'Pitcairn',
      ja: 'ピトケアン',
      da: 'Pitcairn',
      fa: 'پیتکرن',
    },
    dial_code: '+872',
    code: 'PN',
    flag: '🇵🇳',
  },
  {
    name: {
      en: 'Poland',
      hu: 'Lengyelország',
      id: 'Polandia',
      ru: 'Польша',
      pl: 'Polska',
      uk: 'Польща',
      cz: 'Polsko',
      by: 'Польшча',
      pt: 'Polónia',
      es: 'Polonia',
      ro: 'Polonia',
      bg: 'Полша',
      de: 'Polen',
      fr: 'Pologne',
      nl: 'Polen',
      it: 'Polonia',
      cn: '波兰',
      ee: 'Poola',
      ja: 'ポーランド',
      da: 'Polen',
      fa: 'لهستان',
    },
    dial_code: '+48',
    code: 'PL',
    flag: '🇵🇱',
  },
  {
    name: {
      en: 'Portugal',
      hu: 'Portugália',
      id: 'Portugal',
      ru: 'Португалия',
      pl: 'Portugalia',
      uk: 'Португалія',
      cz: 'Portugalsko',
      by: 'Партугалія',
      pt: 'Portugal',
      es: 'Portugal',
      ro: 'Portugalia',
      bg: 'Португалия',
      de: 'Portugal',
      fr: 'Le Portugal',
      nl: 'Portugal',
      it: 'Portogallo',
      cn: '葡萄牙',
      ee: 'Portugal',
      ja: 'ポルトガル',
      da: 'Portugal',
      fa: 'پرتغال',
    },
    dial_code: '+351',
    code: 'PT',
    flag: '🇵🇹',
  },
  {
    name: {
      en: 'Puerto Rico',
      hu: 'Puerto Rico',
      id: 'Puerto Riko',
      ru: 'Пуэрто-Рико',
      pl: 'Portoryko',
      uk: 'Пуерто Ріко',
      cz: 'Portoriko',
      by: 'Пуэрта-Рыка',
      pt: 'Porto Rico',
      es: 'Puerto Rico',
      ro: 'Puerto Rico',
      bg: 'Пуерто Рико',
      de: 'Puerto Rico',
      fr: 'Porto Rico',
      nl: 'Puerto Rico',
      it: 'Porto Rico',
      cn: '波多黎各',
      ee: 'Puerto Rico',
      ja: 'プエルトリコ',
      da: 'Puerto Rico',
      fa: 'پورتوریکو',
    },
    dial_code: '+1939',
    code: 'PR',
    flag: '🇵🇷',
  },
  {
    name: {
      en: 'Qatar',
      hu: 'Katar',
      id: 'Qatar',
      ru: 'Катар',
      pl: 'Katar',
      uk: 'Катар',
      cz: 'Katar',
      by: 'Катар',
      pt: 'Catar',
      es: 'Qatar',
      ro: 'Qatar',
      bg: 'Катар',
      de: 'Katar',
      fr: 'Qatar',
      nl: 'Qatar',
      it: 'Qatar',
      cn: '卡塔尔',
      ee: 'Katar',
      ja: 'カタール',
      da: 'Qatar',
      fa: 'قطر',
    },
    dial_code: '+974',
    code: 'QA',
    flag: '🇶🇦',
  },
  {
    name: {
      en: 'Romania',
      hu: 'Románia',
      id: 'Rumania',
      ru: 'Румыния',
      pl: 'Rumunia',
      uk: 'Румунія',
      cz: 'Rumunsko',
      by: 'Румынія',
      pt: 'Roménia',
      es: 'Rumanía',
      ro: 'România',
      bg: 'Румъния',
      de: 'Rumänien',
      fr: 'Roumanie',
      nl: 'Roemenië',
      it: 'Romania',
      cn: '罗马尼亚',
      ee: 'Rumeenia',
      ja: 'ルーマニア',
      da: 'Rumænien',
      fa: 'رومانی',
    },
    dial_code: '+40',
    code: 'RO',
    flag: '🇷🇴',
  },
  {
    name: {
      en: 'Russia',
      hu: 'Oroszország',
      id: 'Rusia',
      ru: 'Россия',
      pl: 'Rosja',
      uk: 'Росія',
      cz: 'Rusko',
      by: 'Расійская Федэрацыя',
      pt: 'Rússia',
      es: 'Rusia',
      ro: 'Federația Rusă',
      bg: 'Руска федерация',
      de: 'Russische Föderation',
      fr: 'Fédération Russe',
      nl: 'Russische Federatie',
      it: 'Federazione Russa',
      cn: '俄罗斯联邦',
      ee: 'Venemaa Föderatsioon',
      ja: 'ロシア連邦',
      da: 'Rusland',
      fa: 'روسیه',
    },
    dial_code: '+7',
    code: 'RU',
    flag: '🇷🇺',
  },
  {
    name: {
      en: 'Rwanda',
      hu: 'Ruanda',
      id: 'Rwanda',
      ru: 'Руанда',
      pl: 'Rwanda',
      uk: 'Руанда',
      cz: 'Rwanda',
      by: 'Руанда',
      pt: 'Ruanda',
      es: 'Ruanda',
      ro: 'Rwanda',
      bg: 'Руанда',
      de: 'Ruanda',
      fr: 'Rwanda',
      nl: 'Rwanda',
      it: 'Ruanda',
      cn: '卢旺达',
      ee: 'Rwanda',
      ja: 'ルワンダ',
      da: 'Rwanda',
      fa: 'رواندا',
    },
    dial_code: '+250',
    code: 'RW',
    flag: '🇷🇼',
  },
  {
    name: {
      en: 'Réunion',
      hu: 'Réunion',
      id: 'Réunion',
      ru: 'Реюньон',
      pl: 'Réunion',
      uk: 'Реюньйон',
      cz: 'Réunion',
      by: "Уз'яднанне",
      pt: 'Reunião',
      es: 'Reunión',
      ro: 'Reuniune',
      bg: 'Съединение',
      de: 'Réunion',
      fr: 'Réunion',
      nl: 'Réunion',
      it: 'Riunione',
      cn: '留尼汪',
      ee: 'Taaskohtumine',
      ja: 'レユニオン',
      da: 'Réunion',
      fa: 'رئونیون',
    },
    dial_code: '+262',
    code: 'RE',
    flag: '🇷🇪',
  },
  {
    name: {
      en: 'Saint Barthélemy',
      hu: 'Saint-Barthélemy',
      id: 'Saint Barthélemy',
      ru: 'Святой Варфоломей',
      pl: 'Saint Barthelemy',
      uk: 'Сен-Бартелемі',
      cz: 'Svatý Bartoloměj',
      by: 'Святы Бартэлемі',
      pt: 'São Bartolomeu',
      es: 'San Bartolomé',
      ro: 'Sfântul Barthelemy',
      bg: 'Свети Бартелеми',
      de: 'Heiliger Barthelemy',
      fr: 'Saint Barthélemy',
      nl: 'Sint-Bartholomeus',
      it: 'Saint Barthelemy',
      cn: '圣巴托洛缪岛',
      ee: 'Püha Barthelemy',
      ja: 'サンバルテルミー',
      da: 'Saint Barthélemy',
      fa: 'سن بارتلمی',
    },
    dial_code: '+590',
    code: 'BL',
    flag: '🇧🇱',
  },
  {
    name: {
      en: 'Saint Helena, Ascension and Tristan Da Cunha',
      hu: 'Szent Ilona, Ascension és Tristan da Cunha',
      id: 'Saint Helena, Ascension dan Tristan Da Cunha',
      ru: 'Святой Елены, Вознесения и Тристан-да-Кунья',
      pl: 'Święta Helena, Wniebowstąpienie i Tristan Da Cunha',
      uk: 'Свята Олена, Вознесіння і Трістан Да Кунья',
      cz: 'Svatá Helena, Nanebevstoupení a Tristan Da Cunha',
      by: 'Святая Алена',
      pt: 'Santa Helena',
      es: 'Santa Elena, Ascensión y Tristán Da Cunha',
      ro: 'Sfânta Elena',
      bg: 'Света Елена',
      de: 'Heilige Helena',
      fr: 'Sainte-Hélène',
      nl: 'Sint-Helena',
      it: "Sant'Elena",
      cn: '圣海伦娜',
      ee: 'Püha Helena',
      ja: 'セントヘレナ',
      da: 'Saint Helena',
      fa: 'سنت هلنا، اسنشن و تریستان دا کونا',
    },
    dial_code: '+290',
    code: 'SH',
    flag: '🇸🇭',
  },
  {
    name: {
      en: 'Saint Kitts and Nevis',
      hu: 'Saint Kitts és Nevis',
      id: 'Saint Kitts dan Nevis',
      ru: 'Сент-Китс и Невис',
      pl: 'Saint Kitts i Nevis',
      uk: 'Сент-Кітс і Невіс',
      cz: 'Svatý Kryštof a Nevis',
      by: 'Сэнт-Кітс і Нэвіс',
      pt: 'São Cristóvão e Neves',
      es: 'San Cristóbal y Nieves',
      ro: 'Sfântul Kitts și Nevis',
      bg: 'Сейнт Китс и Невис',
      de: 'St. Kitts und Nevis',
      fr: 'Saint-Christophe-et-Niévès',
      nl: 'Saint Kitts en Nevis',
      it: 'Saint Kitts e Nevis',
      cn: '圣基茨和尼维斯',
      ee: 'Saint Kitts ja Nevis',
      ja: 'セントクリストファーネイビス',
      da: 'Saint Kitts og Nevis',
      fa: 'سنت کیتس و نویس',
    },
    dial_code: '+1869',
    code: 'KN',
    flag: '🇰🇳',
  },
  {
    name: {
      en: 'Saint Lucia',
      hu: 'Saint Lucia',
      id: 'Santa Lusia',
      ru: 'Санкт-Люсия',
      pl: 'święta Lucia',
      uk: 'Сент-Люсія',
      cz: 'Svatá Lucie',
      by: 'Святая Люсія',
      pt: 'Santa Lúcia',
      es: 'Santa Lucía',
      ro: 'Sfânta Lucia',
      bg: 'Сейнт Лусия',
      de: 'St. Lucia',
      fr: 'Sainte-Lucie',
      nl: 'Saint Lucia',
      it: 'Santa Lucia',
      cn: '圣卢西亚',
      ee: 'Saint Lucia',
      ja: 'セントルシア',
      da: 'Saint Lucia',
      fa: 'سنت لوسیا',
    },
    dial_code: '+1758',
    code: 'LC',
    flag: '🇱🇨',
  },
  {
    name: {
      en: 'Saint Martin',
      hu: 'Saint Martin',
      id: 'Saint Martin',
      ru: 'Сен-Мартен',
      pl: 'święty Marcin',
      uk: 'Святий Мартін',
      cz: 'Svatý Martin',
      by: 'Святы Марцін',
      pt: 'São Martinho',
      es: 'San Martín',
      ro: 'Sfântul Martin',
      bg: 'Свети Мартин',
      de: 'Sankt Martin',
      fr: 'Saint Martin',
      nl: 'Sint-Maarten',
      it: 'Saint Martin',
      cn: '圣马丁',
      ee: 'Püha Martin',
      ja: 'サンマルタン',
      da: 'Saint Martin',
      fa: 'سنت مارتین',
    },
    dial_code: '+590',
    code: 'MF',
    flag: '🇲🇫',
  },
  {
    name: {
      en: 'Saint Pierre and Miquelon',
      hu: 'Saint-Pierre és Miquelon',
      id: 'Saint Pierre dan Miquelon',
      ru: 'Сен-Пьер и Микелон',
      pl: 'Saint-Pierre i Miquelon',
      uk: "Сен-П'єр і Мікелон",
      cz: 'Svatý Pierre a Miquelon',
      by: "Сен-П'ер і Мікелон",
      pt: 'São Pedro e Miquelon',
      es: 'San Pedro y Miquelón',
      ro: 'Saint Pierre și Miquelon',
      bg: 'Сен Пиер и Микелон',
      de: 'Saint Pierre und Miquelon',
      fr: 'Saint-Pierre-et-Miquelon',
      nl: 'Saint Pierre en Miquelon',
      it: 'Saint Pierre e Miquelon',
      cn: '圣皮埃尔和密克隆群岛',
      ee: 'Saint Pierre ja Miquelon',
      ja: 'サンピエール島とミクロン島',
      da: 'Saint Pierre og Miquelon',
      fa: 'سن پیر و میکلون',
    },
    dial_code: '+508',
    code: 'PM',
    flag: '🇵🇲',
  },
  {
    name: {
      en: 'Saint Vincent and the Grenadines',
      hu: 'Saint Vincent és a Grenadine-szigetek',
      id: 'Saint Vincent dan Grenadines',
      ru: 'Святой Винсент и Гренадины',
      pl: 'Saint Vincent i Grenadyny',
      uk: 'Сент-Вінсент і Гренадини',
      cz: 'Svatý Vincenc a Grenadiny',
      by: 'Сэнт-Вінсэнт і Грэнадыны',
      pt: 'São Vicente e Granadinas',
      es: 'San Vicente y las Granadinas',
      ro: 'Sfântul Vincent și Grenadine',
      bg: 'Сейнт Винсент и Гренадини',
      de: 'St. Vincent und die Grenadinen',
      fr: 'Saint-Vincent-et-les-Grenadines',
      nl: 'Saint Vincent en de Grenadines',
      it: 'Saint Vincent e Grenadine',
      cn: '圣文森特和格林纳丁斯',
      ee: 'Saint Vincent ja Grenadiinid',
      ja: 'セントビンセントおよびグレナディーン諸島',
      da: 'Saint Vincent og Grenadinerne',
      fa: 'سنت وینسنت و گرنادین ها',
    },
    dial_code: '+1784',
    code: 'VC',
    flag: '🇻🇨',
  },
  {
    name: {
      en: 'Samoa',
      hu: 'Szamoa',
      id: 'Samoa',
      ru: 'Самоа',
      pl: 'Samoa',
      uk: 'Самоа',
      cz: 'Samoa',
      by: 'Самоа',
      pt: 'Samoa',
      es: 'Samoa',
      ro: 'Samoa',
      bg: 'Самоа',
      de: 'Samoa',
      fr: 'Samoa',
      nl: 'Samoa',
      it: 'Samoa',
      cn: '萨摩亚',
      ee: 'Samoa',
      ja: 'サモア',
      da: 'Samoa',
      fa: 'ساموآ',
    },
    dial_code: '+685',
    code: 'WS',
    flag: '🇼🇸',
  },
  {
    name: {
      en: 'San Marino',
      hu: 'San Marino',
      id: 'San Marino',
      ru: 'Сан-Марино',
      pl: 'San Marino',
      uk: 'Сан-Марино',
      cz: 'San Marino',
      by: 'Сан-Марына',
      pt: 'São Marinho',
      es: 'San Marino',
      ro: 'San Marino',
      bg: 'Сан Марино',
      de: 'San Marino',
      fr: 'Saint Marin',
      nl: 'San Marino',
      it: 'San Marino',
      cn: '圣马力诺',
      ee: 'San Marino',
      ja: 'サンマリノ',
      da: 'San Marino',
      fa: 'سان مارینو',
    },
    dial_code: '+378',
    code: 'SM',
    flag: '🇸🇲',
  },
  {
    name: {
      en: 'Sao Tome and Principe',
      hu: 'Sao Tomé és Príncipe',
      id: 'Sao Tome dan Principe',
      ru: 'Сан-Томе и Принсипи',
      pl: 'Wyspy Świętego Tomasza i Książęca',
      uk: 'Сан-Томе і Принсіпі',
      cz: 'Svatý Tomáš a Princův ostrov',
      by: 'Сан-Томе і Прынсіпі',
      pt: 'São Tomé e Príncipe',
      es: 'Santo Tomé y Príncipe',
      ro: 'Sao Tome și Principe',
      bg: 'Сао Томе и Принсипи',
      de: 'Sao Tome und Principe',
      fr: 'Sao Tomé et Principe',
      nl: 'Sao Tomé en Principe',
      it: 'Sao Tome e Principe',
      cn: '圣多美和普林西比',
      ee: 'Sao Tome ja Principe',
      ja: 'サントメ・プリンシペ',
      da: 'Sao Tome og Principe',
      fa: 'سائوتومه و پرنسیپ',
    },
    dial_code: '+239',
    code: 'ST',
    flag: '🇸🇹',
  },
  {
    name: {
      en: 'Saudi Arabia',
      hu: 'Szaúd-Arábia',
      id: 'Arab Saudi',
      ru: 'Саудовская Аравия',
      pl: 'Arabia Saudyjska',
      uk: 'Саудівська Аравія',
      cz: 'Saudská arábie',
      by: 'Саўдаўская Аравія',
      pt: 'Arábia Saudita',
      es: 'Arabia Saudí',
      ro: 'Arabia Saudită',
      bg: 'Саудитска Арабия',
      de: 'Saudi-Arabien',
      fr: 'Arabie Saoudite',
      nl: 'Saoedi-Arabië',
      it: 'Arabia Saudita',
      cn: '沙特阿拉伯',
      ee: 'Saudi Araabia',
      ja: 'サウジアラビア',
      da: 'Saudi-Arabien',
      fa: 'عربستان سعودی',
    },
    dial_code: '+966',
    code: 'SA',
    flag: '🇸🇦',
  },
  {
    name: {
      en: 'Senegal',
      hu: 'Szenegál',
      id: 'Senegal',
      ru: 'Сенегал',
      pl: 'Senegal',
      uk: 'Сенегал',
      cz: 'Senegal',
      by: 'Сенегал',
      pt: 'Senegal',
      es: 'Senegal',
      ro: 'Senegal',
      bg: 'Сенегал',
      de: 'Senegal',
      fr: 'Sénégal',
      nl: 'Senegal',
      it: 'Senegal',
      cn: '塞内加尔',
      ee: 'Senegal',
      ja: 'セネガル',
      da: 'Senegal',
      fa: 'سنگال',
    },
    dial_code: '+221',
    code: 'SN',
    flag: '🇸🇳',
  },
  {
    name: {
      en: 'Serbia',
      hu: 'Szerbia',
      id: 'Serbia',
      ru: 'Сербия',
      pl: 'Serbia',
      uk: 'Сербія',
      cz: 'Srbsko',
      by: 'Сербія',
      pt: 'Sérvia',
      es: 'Serbia',
      ro: 'Serbia',
      bg: 'Сърбия',
      de: 'Serbien',
      fr: 'Serbie',
      nl: 'Servië',
      it: 'Serbia',
      cn: '塞尔维亚',
      ee: 'Serbia',
      ja: 'セルビア',
      da: 'Serbien',
      fa: 'صربستان',
    },
    dial_code: '+381',
    code: 'RS',
    flag: '🇷🇸',
  },
  {
    name: {
      en: 'Seychelles',
      hu: 'Seychelle-szigetek',
      id: 'Seychelles',
      ru: 'Сейшельские острова',
      pl: 'Seszele',
      uk: 'Сейшельські острови',
      cz: 'Seychely',
      by: 'Сейшэльскія астравы',
      pt: 'Seicheles',
      es: 'Seychelles',
      ro: 'Seychelles',
      bg: 'Сейшелски острови',
      de: 'Seychellen',
      fr: 'les Seychelles',
      nl: 'Seychellen',
      it: 'Seychelles',
      cn: '塞舌尔',
      ee: 'Seišellid',
      ja: 'セイシェル',
      da: 'Seychellerne',
      fa: 'سیشل',
    },
    dial_code: '+248',
    code: 'SC',
    flag: '🇸🇨',
  },
  {
    name: {
      en: 'Sierra Leone',
      hu: 'Sierra Leone',
      id: 'Sierra Leone',
      ru: 'Сьерра-Леоне',
      pl: 'Sierra Leone',
      uk: 'Сьєрра-Леоне',
      cz: 'Sierra Leone',
      by: 'Сьера-Леонэ',
      pt: 'Serra Leoa',
      es: 'Sierra Leona',
      ro: 'Sierra Leone',
      bg: 'Сиера Леоне',
      de: 'Sierra Leone',
      fr: 'Sierra Leone',
      nl: 'Sierra Leone',
      it: 'Sierra Leone',
      cn: '塞拉利昂',
      ee: 'Sierra Leone',
      ja: 'シエラレオネ',
      da: 'Sierra Leone',
      fa: 'سیرالئون',
    },
    dial_code: '+232',
    code: 'SL',
    flag: '🇸🇱',
  },
  {
    name: {
      en: 'Singapore',
      hu: 'Szingapúr',
      id: 'Singapura',
      ru: 'Сингапур',
      pl: 'Singapur',
      uk: 'Сінгапур',
      cz: 'Singapur',
      by: 'Сінгапур',
      pt: 'Singapura',
      es: 'Singapur',
      ro: 'Singapore',
      bg: 'Сингапур',
      de: 'Singapur',
      fr: 'Singapour',
      nl: 'Singapore',
      it: 'Singapore',
      cn: '新加坡',
      ee: 'Singapur',
      ja: 'シンガポール',
      da: 'Singapore',
      fa: 'سنگاپور',
    },
    dial_code: '+65',
    code: 'SG',
    flag: '🇸🇬',
  },
  {
    name: {
      en: 'Slovakia',
      hu: 'Szlovákia',
      id: 'Slovakia',
      ru: 'Словакия',
      pl: 'Słowacja',
      uk: 'Словаччина',
      cz: 'Slovensko',
      by: 'Славакія',
      pt: 'Eslováquia',
      es: 'Eslovaquia',
      ro: 'Slovacia',
      bg: 'Словакия',
      de: 'Slowakei',
      fr: 'Slovaquie',
      nl: 'Slowakije',
      it: 'Slovacchia',
      cn: '斯洛伐克',
      ee: 'Slovakkia',
      ja: 'スロバキア',
      da: 'Slovakiet',
      fa: 'اسلواکیه',
    },
    dial_code: '+421',
    code: 'SK',
    flag: '🇸🇰',
  },
  {
    name: {
      en: 'Slovenia',
      hu: 'Szlovénia',
      id: 'Slovenia',
      ru: 'Словения',
      pl: 'Słowenia',
      uk: 'Словенія',
      cz: 'Slovinsko',
      by: 'Славенія',
      pt: 'Eslovénia',
      es: 'Eslovenia',
      ro: 'Slovenia',
      bg: 'Словения',
      de: 'Slowenien',
      fr: 'Slovénie',
      nl: 'Slovenië',
      it: 'Slovenia',
      cn: '斯洛文尼亚',
      ee: 'Sloveenia',
      ja: 'スロベニア',
      da: 'Slovenien',
      fa: 'اسلوونی',
    },
    dial_code: '+386',
    code: 'SI',
    flag: '🇸🇮',
  },
  {
    name: {
      en: 'Solomon Islands',
      hu: 'Salamon-szigetek',
      id: 'Kepulauan Solomon',
      ru: 'Соломоновы острова',
      pl: 'Wyspy Salomona',
      uk: 'Соломонові острови',
      cz: 'Solomonovy ostrovy',
      by: 'Саламонавы астравы',
      pt: 'Ilhas Salomão',
      es: 'Islas Salomón',
      ro: 'Insulele Solomon',
      bg: 'Соломонови острови',
      de: 'Salomon-Inseln',
      fr: 'Les îles Salomon',
      nl: 'Solomon eilanden',
      it: 'Isole Salomone',
      cn: '所罗门群岛',
      ee: 'Saalomoni Saared',
      ja: 'ソロモン諸島',
      da: 'Salomonøerne',
      fa: 'جزایر سلیمان',
    },
    dial_code: '+677',
    code: 'SB',
    flag: '🇸🇧',
  },
  {
    name: {
      en: 'Somalia',
      hu: 'Szomália',
      id: 'Somalia',
      ru: 'Сомали',
      pl: 'Somali',
      uk: 'Сомалі',
      cz: 'Somálsko',
      by: 'Самалі',
      pt: 'Somália',
      es: 'Somalia',
      ro: 'Somalia',
      bg: 'Сомалия',
      de: 'Somalia',
      fr: 'Somalie',
      nl: 'Somalië',
      it: 'Somalia',
      cn: '索马里',
      ee: 'Somaalia',
      ja: 'ソマリア',
      da: 'Somalia',
      fa: 'سومالی',
    },
    dial_code: '+252',
    code: 'SO',
    flag: '🇸🇴',
  },
  {
    name: {
      en: 'South Africa',
      hu: 'Dél-Afrika',
      id: 'Afrika Selatan',
      ru: 'Южная Африка',
      pl: 'Afryka Południowa',
      uk: 'Південна Африка',
      cz: 'Jižní Afrika',
      by: 'Паўднёвая Афрыка',
      pt: 'África do Sul',
      es: 'Sudáfrica',
      ro: 'Africa de Sud',
      bg: 'Южна Африка',
      de: 'Südafrika',
      fr: 'Afrique du Sud',
      nl: 'Zuid-Afrika',
      it: 'Sud Africa',
      cn: '南非',
      ee: 'Lõuna-Aafrika',
      ja: '南アフリカ',
      da: 'Sydafrika',
      fa: 'آفریقای جنوبی',
    },
    dial_code: '+27',
    code: 'ZA',
    flag: '🇿🇦',
  },
  {
    name: {
      en: 'South Georgia and the South Sandwich Islands',
      hu: 'Déli-Georgia és Déli-Sandwich-szigetek',
      id: 'Georgia Selatan dan Kepulauan Sandwich Selatan',
      ru: 'Южная Георгия и Южные Сандвичевы острова',
      pl: 'Georgia Południowa i Sandwich Południowy',
      uk: 'Південна Джорджія та Південні Сандвічеві острови',
      cz: 'Jižní Georgie a Jižní Sandwichovy ostrovy',
      by: 'Паўднёвая Джорджыя і Паўднёвыя Сандвічавы астравы',
      pt: 'Geórgia do Sul e Sandwich do Sul',
      es: 'Georgia del Sur y las Islas Sandwich del Sur',
      ro: 'Georgia de Sud și Insulele Sandwich de Sud',
      bg: 'Южна Джорджия и Южните сандвичеви острови',
      de: 'Süd-Georgien und die südlichen Sandwich-Inseln',
      fr: 'Géorgie du Sud et îles Sandwich du Sud',
      nl: 'Zuid-Georgië en de Zuidelijke Sandwicheilanden',
      it: 'Georgia del Sud e isole Sandwich meridionali',
      cn: '南乔治亚岛和南桑威奇群岛',
      ee: 'Lõuna-Georgia ja Lõuna-Sandwichi saared',
      ja: 'サウスジョージア島とサウスサンドイッチ諸島',
      da: 'South Georgia og South Sandwich Islands',
      fa: 'جزایر جورجیای جنوبی و ساندویچ جنوبی',
    },
    dial_code: '+500',
    code: 'GS',
    flag: '🇬🇸',
  },
  {
    name: {
      en: 'Spain',
      hu: 'Spanyolország',
      id: 'Spanyol',
      ru: 'Испания',
      pl: 'Hiszpania',
      uk: 'Іспанія',
      cz: 'Španělsko',
      by: 'Іспанія',
      pt: 'Espanha',
      es: 'España',
      ro: 'Spania',
      bg: 'Испания',
      de: 'Spanien',
      fr: 'Espagne',
      nl: 'Spanje',
      it: 'Spagna',
      cn: '西班牙',
      ee: 'Hispaania',
      ja: 'スペイン',
      da: 'Spanien',
      fa: 'اسپانیا',
    },
    dial_code: '+34',
    code: 'ES',
    flag: '🇪🇸',
  },
  {
    name: {
      en: 'Sri Lanka',
      hu: 'Srí Lanka',
      id: 'Sri Lanka',
      ru: 'Шри-Ланка',
      pl: 'Sri Lanka',
      uk: 'Шрі Ланка',
      cz: 'Srí Lanka',
      by: 'Шры Ланка',
      pt: 'Sri Lanca',
      es: 'Sri Lanka',
      ro: 'Sri Lanka',
      bg: 'Шри Ланка',
      de: 'Sri Lanka',
      fr: 'Sri Lanka',
      nl: 'Sri Lanka',
      it: 'Sri Lanka',
      cn: '斯里兰卡',
      ee: 'Sri Lanka',
      ja: 'スリランカ',
      da: 'Sri Lanka',
      fa: 'سری‌لانکا',
    },
    dial_code: '+94',
    code: 'LK',
    flag: '🇱🇰',
  },
  {
    name: {
      en: 'Sudan',
      hu: 'Szudán',
      id: 'Sudan',
      ru: 'Судан',
      pl: 'Sudan',
      uk: 'Судан',
      cz: 'Súdán',
      by: 'Судан',
      pt: 'Sudão',
      es: 'Sudán',
      ro: 'Sudan',
      bg: 'Судан',
      de: 'Sudan',
      fr: 'Soudan',
      nl: 'Soedan',
      it: 'Sudan',
      cn: '苏丹',
      ee: 'Sudaan',
      ja: 'スーダン',
      da: 'Sudan',
      fa: 'سودان',
    },
    dial_code: '+249',
    code: 'SD',
    flag: '🇸🇩',
  },
  {
    name: {
      en: 'Suriname',
      hu: 'Suriname',
      id: 'Suriname',
      ru: 'Суринам',
      pl: 'Surinam',
      uk: 'Суринам',
      cz: 'Surinam',
      by: 'Сурынам',
      pt: 'Suriname',
      es: 'Surinam',
      ro: 'Surinam',
      bg: 'Суринам',
      de: 'Suriname',
      fr: 'Suriname',
      nl: 'Suriname',
      it: 'Suriname',
      cn: '苏里南',
      ee: 'Suriname',
      ja: 'スリナム',
      da: 'Surinam',
      fa: 'سورینام',
    },
    dial_code: '+597',
    code: 'SR',
    flag: '🇸🇷',
  },
  {
    name: {
      en: 'Svalbard and Jan Mayen',
      hu: 'Spitzbergák és Jan Mayen',
      id: 'Svalbard dan Jan Mayen',
      ru: 'Шпицберген и Ян Майен',
      pl: 'Svalbard i Jan Mayen',
      uk: 'Шпіцберген та Ян Маєн',
      cz: 'Špicberky a Jan Mayen',
      by: 'Шпіцберген і Ян Маен',
      pt: 'Svalbard e Jan Mayen',
      es: 'Svalbard y Jan Mayen',
      ro: 'Svalbard și Jan Mayen',
      bg: 'Шпицберген и Ян Майен',
      de: 'Spitzbergen und Jan Mayen',
      fr: 'Svalbard et Jan Mayen',
      nl: 'Svalbard en Jan Mayen',
      it: 'Svalbard e Jan Mayen',
      cn: '斯瓦尔巴和扬·马延',
      ee: 'Svalbard ja Jan Mayen',
      ja: 'スバールバル諸島およびヤンマイエン',
      da: 'Svalbard og Jan Mayen',
      fa: 'سوالبارد و یان ماین',
    },
    dial_code: '+47',
    code: 'SJ',
    flag: '🇸🇯',
  },
  {
    name: {
      en: 'Swaziland',
      hu: 'Szváziföld',
      id: 'Swaziland',
      ru: 'Свазиленд',
      pl: 'Suazi',
      uk: 'Свазіленд',
      cz: 'Svazijsko',
      by: 'Свазіленд',
      pt: 'Suazilândia',
      es: 'Suazilandia',
      ro: 'Swaziland',
      bg: 'Свазиленд',
      de: 'Swasiland',
      fr: 'Swaziland',
      nl: 'Swaziland',
      it: 'Swaziland',
      cn: '斯威士兰',
      ee: 'Svaasimaa',
      ja: 'スワジランド',
      da: 'Swaziland',
      fa: 'سوازیلند',
    },
    dial_code: '+268',
    code: 'SZ',
    flag: '🇸🇿',
  },
  {
    name: {
      en: 'Sweden',
      hu: 'Svédország',
      id: 'Swedia',
      ru: 'Швеция',
      pl: 'Szwecja',
      uk: 'Швеція',
      cz: 'Švédsko',
      by: 'Швецыя',
      pt: 'Suécia',
      es: 'Suecia',
      ro: 'Suedia',
      bg: 'Швеция',
      de: 'Schweden',
      fr: 'Suède',
      nl: 'Zweden',
      it: 'Svezia',
      cn: '瑞典',
      ee: 'Rootsi',
      ja: 'スウェーデン',
      da: 'Sverige',
      fa: 'سوئد',
    },
    dial_code: '+46',
    code: 'SE',
    flag: '🇸🇪',
  },
  {
    name: {
      en: 'Switzerland',
      hu: 'Svájc',
      id: 'Swiss',
      ru: 'Швейцария',
      pl: 'Szwajcaria',
      uk: 'Швейцарія',
      cz: 'Švýcarsko',
      by: 'Швейцарыя',
      pt: 'Suíça',
      es: 'Suiza',
      ro: 'Elveţia',
      bg: 'Швейцария',
      de: 'Schweiz',
      fr: 'la Suisse',
      nl: 'Zwitserland',
      it: 'Svizzera',
      cn: '瑞士',
      ee: 'Šveits',
      ja: 'スイス',
      da: 'Schweiz',
      fa: 'سوئیس',
    },
    dial_code: '+41',
    code: 'CH',
    flag: '🇨🇭',
  },
  {
    name: {
      en: 'Syria',
      hu: 'Szíria',
      id: 'Suriah',
      ru: 'Сирия',
      pl: 'Republika Syryjsko-Arabska',
      uk: 'Сирійська Арабська Республіка',
      cz: 'Syrská Arabská republika',
      by: 'Сірыйская Арабская Рэспубліка',
      pt: 'Síria',
      es: 'República Árabe Siria',
      ro: 'Republica Araba Siriana',
      bg: 'Сирийска Арабска Република',
      de: 'Syrische Arabische Republik',
      fr: 'République arabe syrienne',
      nl: 'Syrische Arabische Republiek',
      it: 'Repubblica Araba Siriana',
      cn: '阿拉伯叙利亚共和国',
      ee: 'Süüria Araabia Vabariik',
      ja: 'シリア',
      da: 'Syrien',
      fa: 'سوریه',
    },
    dial_code: '+963',
    code: 'SY',
    flag: '🇸🇾',
  },
  {
    name: {
      en: 'Taiwan',
      hu: 'Tajvan',
      id: 'Taiwan',
      ru: 'Тайвань',
      pl: 'Tajwan',
      uk: 'Тайвань',
      cz: 'Tchaj-wan',
      by: 'Тайвань',
      pt: 'Taiwan',
      es: 'Taiwán',
      ro: 'Taiwan',
      bg: 'Тайван',
      de: 'Taiwan',
      fr: 'Taiwan',
      nl: 'Taiwan',
      it: 'Taiwan',
      cn: '台湾',
      ee: 'Taiwan',
      ja: '台湾',
      da: 'Taiwan',
      fa: 'تایوان',
    },
    dial_code: '+886',
    code: 'TW',
    flag: '🇹🇼',
  },
  {
    name: {
      en: 'Tajikistan',
      hu: 'Tádzsikisztán',
      id: 'Tajikistan',
      ru: 'Таджикистан',
      pl: 'Tadżykistan',
      uk: 'Таджикистан',
      cz: 'Tádžikistán',
      by: 'Таджыкістан',
      pt: 'Tajiquistão',
      es: 'Tayikistán',
      ro: 'Tadjikistan',
      bg: 'Таджикистан',
      de: 'Tadschikistan',
      fr: 'Tadjikistan',
      nl: 'Tadzjikistan',
      it: 'Tagikistan',
      cn: '塔吉克斯坦',
      ee: 'Tadžikistan',
      ja: 'タジキスタン',
      da: 'Tadsjikistan',
      fa: 'تاجیکستان',
    },
    dial_code: '+992',
    code: 'TJ',
    flag: '🇹🇯',
  },
  {
    name: {
      en: 'Tanzania, United Republic of',
      hu: 'Tanzánia',
      id: 'Tanzania, Republik Bersatu',
      ru: 'Танзания, Объединенная Республика',
      pl: 'Tanzania, Zjednoczona Republika',
      uk: "Танзанія, Об'єднана республіка",
      cz: 'Tanzanie, Velká republika',
      by: "Танзанія, Аб'яднаная Рэспубліка",
      pt: 'Tanzânia',
      es: 'Tanzania, República Unida de',
      ro: 'Tanzania, Republica Unită a',
      bg: 'Танзания, Обединена република',
      de: 'Tansania, Vereinigte Republik',
      fr: 'Tanzanie, République-Unie de',
      nl: 'Tanzania, Verenigde Republiek',
      it: 'Tanzania, Repubblica Unita di',
      cn: '坦桑尼亚联合共和国',
      ee: 'Tansaania, Tšehhi Vabariik',
      ja: 'タンザニア、連合共和国',
      da: 'Tanzania',
      fa: 'تانزانیا، جمهوری متحد',
    },
    dial_code: '+255',
    code: 'TZ',
    flag: '🇹🇿',
  },
  {
    name: {
      en: 'Thailand',
      hu: 'Thaiföld',
      id: 'Thailand',
      ru: 'Таиланд',
      pl: 'Tajlandia',
      uk: 'Таїланд',
      cz: 'Thajsko',
      by: 'Тайланд',
      pt: 'Tailândia',
      es: 'Tailandia',
      ro: 'Tailanda',
      bg: 'Тайланд',
      de: 'Thailand',
      fr: 'Thaïlande',
      nl: 'Thailand',
      it: 'Tailandia',
      cn: '泰国',
      ee: 'Tai',
      ja: 'タイ',
      da: 'Thailand',
      fa: 'تایلند',
    },
    dial_code: '+66',
    code: 'TH',
    flag: '🇹🇭',
  },
  {
    name: {
      en: 'Timor-Leste',
      hu: 'Kelet-Timor',
      id: 'Timor-Leste',
      ru: 'Восточный Тимор',
      pl: 'Timor Wschodni',
      uk: 'Восточний Тимор',
      cz: 'Východní Timor',
      by: 'Востраў Тымор',
      pt: 'Timor Leste',
      es: 'Timor-Leste',
      ro: 'Timor-Leste',
      bg: 'Тимор-Лешти',
      de: 'Timor-Leste',
      fr: 'Timor-Leste',
      nl: 'Oost-Timor',
      it: 'Timor-Leste',
      cn: '东帝汶',
      ee: 'Ida-Timor',
      ja: '東ティモール',
      da: 'Timor-Leste',
      fa: 'تیمور شرقی',
    },
    dial_code: '+670',
    code: 'TL',
    flag: '🇹🇱',
  },
  {
    name: {
      en: 'Togo',
      hu: 'Togo',
      id: 'Togo',
      ru: 'Того',
      pl: 'Togo',
      uk: 'Того',
      cz: 'Togo',
      by: 'Ісці',
      pt: 'Togo',
      es: 'Togo',
      ro: 'A merge',
      bg: 'Да отида',
      de: 'Gehen',
      fr: 'Aller',
      nl: 'Gaan',
      it: 'Andare',
      cn: '多哥',
      ee: 'Minema',
      ja: 'トーゴ',
      da: 'Togo',
      fa: 'رفتن',
    },
    dial_code: '+228',
    code: 'TG',
    flag: '🇹🇬',
  },
  {
    name: {
      en: 'Tokelau',
      hu: 'Tokelau-szigetek',
      id: 'Tokelau',
      ru: 'Токелау',
      pl: 'Tokelau',
      uk: 'Токелау',
      cz: 'Tokelau',
      by: 'Такелаў',
      pt: 'Tokelau',
      es: 'Tokelau',
      ro: 'Tokelau',
      bg: 'Токелау',
      de: 'Tokelau',
      fr: 'Tokelau',
      nl: 'Tokelau',
      it: 'Tokelau',
      cn: '托克劳',
      ee: 'Tokelau',
      ja: 'トケラウ',
      da: 'Tokelau',
      fa: 'توکلائو',
    },
    dial_code: '+690',
    code: 'TK',
    flag: '🇹🇰',
  },
  {
    name: {
      en: 'Tonga',
      hu: 'Tonga',
      id: 'Tonga',
      ru: 'Тонга',
      pl: 'Tonga',
      uk: 'Тонга',
      cz: 'Tonga',
      by: 'Тонга',
      pt: 'Tonga',
      es: 'Tonga',
      ro: 'Tonga',
      bg: 'Тонга',
      de: 'Tonga',
      fr: 'Tonga',
      nl: 'Tonga',
      it: 'Tonga',
      cn: '汤加',
      ee: 'Tonga',
      ja: 'トンガ',
      da: 'Tonga',
      fa: 'تونگا',
    },
    dial_code: '+676',
    code: 'TO',
    flag: '🇹🇴',
  },
  {
    name: {
      en: 'Trinidad and Tobago',
      hu: 'Trinidad és Tobago',
      id: 'Trinidad dan Tobago',
      ru: 'Тринидад и Тобаго',
      pl: 'Trynidad i Tobago',
      uk: 'Тринідад і Тобаго',
      cz: 'Trinidad a Tobago',
      by: 'Трынідад і Табага',
      pt: 'Trindade e Tobago',
      es: 'Trinidad y Tobago',
      ro: 'Trinidad și Tobago',
      bg: 'Тринидад и Тобаго',
      de: 'Trinidad und Tobago',
      fr: 'Trinité-et-Tobago',
      nl: 'Trinidad en Tobago',
      it: 'Trinidad e Tobago',
      cn: '特立尼达和多巴哥',
      ee: 'Trinidad ja Tobago',
      ja: 'トリニダード・トバゴ',
      da: 'Trinidad og Tobago',
      fa: 'ترینیداد و توباگو',
    },
    dial_code: '+1868',
    code: 'TT',
    flag: '🇹🇹',
  },
  {
    name: {
      en: 'Tunisia',
      hu: 'Tunézia',
      id: 'Tunisia',
      ru: 'Тунис',
      pl: 'Tunezja',
      uk: 'Туніс',
      cz: 'Tunisko',
      by: 'Туніс',
      pt: 'Tunísia',
      es: 'Túnez',
      ro: 'Tunisia',
      bg: 'Тунис',
      de: 'Tunesien',
      fr: 'Tunisie',
      nl: 'Tunesië',
      it: 'Tunisia',
      cn: '突尼斯',
      ee: 'Tuneesia',
      ja: 'チュニジア',
      da: 'Tunesien',
      fa: 'تونس',
    },
    dial_code: '+216',
    code: 'TN',
    flag: '🇹🇳',
  },
  {
    name: {
      en: 'Turkey',
      hu: 'Törökország',
      id: 'Turki',
      ru: 'Турция',
      pl: 'Turcja',
      uk: 'Туреччина',
      cz: 'Turecko',
      by: 'Турцыя',
      pt: 'Turquia',
      es: 'Turquía',
      ro: 'Turcia',
      bg: 'Турция',
      de: 'Türkei',
      fr: 'Turquie',
      nl: 'Turkije',
      it: 'Turchia',
      cn: '火鸡',
      ee: 'Türgi',
      ja: 'トルコ',
      da: 'Tyrkiet',
      fa: 'ترکیه',
    },
    dial_code: '+90',
    code: 'TR',
    flag: '🇹🇷',
  },
  {
    name: {
      en: 'Turkmenistan',
      hu: 'Türkmenisztán',
      id: 'Turkmenistan',
      ru: 'Туркменистан',
      pl: 'Turkmenia',
      uk: 'Туркменістан',
      cz: 'Turkmenistán',
      by: 'Туркменістан',
      pt: 'Turquemenistão',
      es: 'Turkmenistán',
      ro: 'Turkmenistan',
      bg: 'Туркменистан',
      de: 'Turkmenistan',
      fr: 'Turkménistan',
      nl: 'Turkmenistan',
      it: 'Turkmenistan',
      cn: '土库曼斯坦',
      ee: 'Türkmenistan',
      ja: 'トルクメニスタン',
      da: 'Turkmenistan',
      fa: 'ترکمنستان',
    },
    dial_code: '+993',
    code: 'TM',
    flag: '🇹🇲',
  },
  {
    name: {
      en: 'Turks and Caicos Islands',
      hu: 'Turks- és Caicos-szigetek',
      id: 'Kepulauan Turks dan Caicos',
      ru: 'Острова Теркс и Кайкос',
      pl: 'Wyspy Turks i Caicos',
      uk: 'Острови Теркс і Кайкос',
      cz: 'ostrovy Turks a Cacois',
      by: 'Астравы Тэркс і Кайкас',
      pt: 'Ilhas Turcas e Caicos',
      es: 'Islas Turcas y Caicos',
      ro: 'Insulele Turks și Caicos',
      bg: 'Острови Търкс и Кайкос',
      de: 'Turks- und Caicosinseln',
      fr: 'îles Turques-et-Caïques',
      nl: 'Turks- en Caicoseilanden',
      it: 'Isole Turks e Caicos',
      cn: '特克斯和凯科斯群岛',
      ee: 'Turksi ja Caicose saared',
      ja: 'タークス・カイコス諸島',
      da: 'Turks- og Caicosøerne',
      fa: 'جزایر ترکس و کایکوس',
    },
    dial_code: '+1649',
    code: 'TC',
    flag: '🇹🇨',
  },
  {
    name: {
      en: 'Tuvalu',
      hu: 'Tuvalu',
      id: 'Tuvalu',
      ru: 'Тувалу',
      pl: 'Tuvalu',
      uk: 'Тувалу',
      cz: 'Tuvalu',
      by: 'Тувалу',
      pt: 'Tuvalu',
      es: 'Tuvalu',
      ro: 'Tuvalu',
      bg: 'Тувалу',
      de: 'Tuvalu',
      fr: 'Tuvalu',
      nl: 'Tuvalu',
      it: 'Tuvalu',
      cn: '图瓦卢',
      ee: 'Tuvalu',
      ja: 'ツバル',
      da: 'Tuvalu',
      fa: 'تووالو',
    },
    dial_code: '+688',
    code: 'TV',
    flag: '🇹🇻',
  },
  {
    name: {
      en: 'Uganda',
      hu: 'Uganda',
      id: 'Uganda',
      ru: 'Уганда',
      pl: 'Uganda',
      uk: 'Уганда',
      cz: 'Uganda',
      by: 'Уганда',
      pt: 'Uganda',
      es: 'Uganda',
      ro: 'Uganda',
      bg: 'Уганда',
      de: 'Uganda',
      fr: 'Ouganda',
      nl: 'Oeganda',
      it: 'Uganda',
      cn: '乌干达',
      ee: 'Uganda',
      ja: 'ウガンダ',
      da: 'Uganda',
      fa: 'اوگاندا',
    },
    dial_code: '+256',
    code: 'UG',
    flag: '🇺🇬',
  },
  {
    name: {
      en: 'Ukraine',
      hu: 'Ukrajna',
      id: 'Ukraina',
      ru: 'Украина',
      pl: 'Ukraina',
      uk: 'Україна',
      cz: 'Ukrajina',
      by: 'Украіна',
      pt: 'Ucrânia',
      es: 'Ucrania',
      ro: 'Ucraina',
      bg: 'Украйна',
      de: 'Ukraine',
      fr: 'Ukraine',
      nl: 'Oekraïne',
      it: 'Ucraina',
      cn: '乌克兰',
      ee: 'Ukraina',
      ja: 'ウクライナ',
      da: 'Ukraine',
      fa: 'اوکراین',
    },
    dial_code: '+380',
    code: 'UA',
    flag: '🇺🇦',
  },
  {
    name: {
      en: 'United Arab Emirates',
      hu: 'Egyesült Arab Emírségek',
      id: 'Uni Emirat Arab',
      ru: 'Объединенные Арабские Эмираты',
      pl: 'Zjednoczone Emiraty Arabskie',
      uk: "Об'єднані Арабські Емірати",
      cz: 'Spojené arabské emiráty',
      by: 'Абяднаныя Арабскія Эміраты',
      pt: 'Emiratos Árabes Unidos',
      es: 'Emiratos Árabes Unidos',
      ro: 'Emiratele Arabe Unite',
      bg: 'Обединени арабски емирства',
      de: 'Vereinigte Arabische Emirate',
      fr: 'Emirats Arabes Unis',
      nl: 'Verenigde Arabische Emiraten',
      it: 'Emirati Arabi Uniti',
      cn: '阿拉伯联合酋长国',
      ee: 'Araabia Ühendemiraadid',
      ja: 'アラブ首長国連邦',
      da: 'De Forenede Arabiske Emirater',
      fa: 'امارات متحده عربی',
    },
    dial_code: '+971',
    code: 'AE',
    flag: '🇦🇪',
  },
  {
    name: {
      en: 'United Kingdom',
      hu: 'Egyesült Királyság',
      id: 'Inggris Raya',
      ru: 'Объединенное Королевство',
      pl: 'Zjednoczone Królestwo',
      uk: "Об'єднане Королівство",
      cz: 'Spojené království',
      by: 'Злучанае Каралеўства',
      pt: 'Reino Unido',
      es: 'Reino Unido',
      ro: 'Regatul Unit',
      bg: 'Великобритания',
      de: 'Vereinigtes Königreich',
      fr: 'Royaume-Uni',
      nl: 'Verenigd Koningkrijk',
      it: 'Regno Unito',
      cn: '英国',
      ee: 'Ühendkuningriik',
      ja: 'イギリス',
      da: 'Storbritannien',
      fa: 'بریتانیا',
    },
    dial_code: '+44',
    code: 'GB',
    flag: '🇬🇧',
  },
  {
    name: {
      en: 'United States',
      hu: 'Amerikai Egyesült Államok',
      id: 'Amerika Serikat',
      ru: 'Соединенные Штаты',
      pl: 'Stany Zjednoczone',
      uk: 'Сполучені Штати',
      cz: 'Spojené státy',
      by: 'Злучаныя Штаты',
      pt: 'Estados Unidos',
      es: 'Estados Unidos',
      ro: 'Statele Unite',
      bg: 'Съединени щати',
      de: 'Vereinigte Staaten',
      fr: 'États-Unis',
      nl: 'Verenigde Staten',
      it: 'stati Uniti',
      cn: '美国',
      ee: 'Ühendriigid',
      ja: 'アメリカ',
      da: 'Amerikas Forenede Stater',
      fa: 'ایالات متحده',
    },
    dial_code: '+1',
    code: 'US',
    flag: '🇺🇸',
  },
  {
    name: {
      en: 'Uruguay',
      hu: 'Uruguay',
      id: 'Uruguay',
      ru: 'Уругвай',
      pl: 'Urugwaj',
      uk: 'Уругвай',
      cz: 'Uruguay',
      by: 'Уругвай',
      pt: 'Uruguai',
      es: 'Uruguay',
      ro: 'Uruguay',
      bg: 'Уругвай',
      de: 'Uruguay',
      fr: 'Uruguay',
      nl: 'Uruguay',
      it: 'Uruguay',
      cn: '乌拉圭',
      ee: 'Uruguay',
      ja: 'ウルグアイ',
      da: 'Uruguay',
      fa: 'اروگوئه',
    },
    dial_code: '+598',
    code: 'UY',
    flag: '🇺🇾',
  },
  {
    name: {
      en: 'Uzbekistan',
      hu: 'Üzbegisztán',
      id: 'Uzbekistan',
      ru: 'Узбекистан',
      pl: 'Uzbekistan',
      uk: 'Узбекистан',
      cz: 'Uzbekistán',
      by: 'Узбекістан',
      pt: 'Usbequistão',
      es: 'Uzbekistán',
      ro: 'Uzbekistan',
      bg: 'Узбекистан',
      de: 'Usbekistan',
      fr: 'Ouzbékistan',
      nl: 'Oezbekistan',
      it: 'Uzbekistan',
      cn: '乌兹别克斯坦',
      ee: 'Usbekistan',
      ja: 'ウズベキスタン',
      da: 'Usbekistan',
      fa: 'ازبکستان',
    },
    dial_code: '+998',
    code: 'UZ',
    flag: '🇺🇿',
  },
  {
    name: {
      en: 'Vanuatu',
      hu: 'Vanuatu',
      id: 'Vanuatu',
      ru: 'Вануату',
      pl: 'Vanuatu',
      uk: 'Вануату',
      cz: 'Vanuatu',
      by: 'Вануату',
      pt: 'Vanuatu',
      es: 'Vanuatu',
      ro: 'Vanuatu',
      bg: 'Вануату',
      de: 'Vanuatu',
      fr: 'Vanuatu',
      nl: 'Vanuatu',
      it: 'Vanuatu',
      cn: '瓦努阿图',
      ee: 'Vanuatu',
      ja: 'バヌアツ',
      da: 'Vanuatu',
      fa: 'وانواتو',
    },
    dial_code: '+678',
    code: 'VU',
    flag: '🇻🇺',
  },
  {
    name: {
      en: 'Venezuela',
      hu: 'Venezuela',
      id: 'Venezuela',
      ru: 'Венесуэла',
      pl: 'Wenezuela',
      uk: 'Венесуела',
      cz: 'Venezuela',
      by: 'Венесуэла',
      pt: 'Venezuela',
      es: 'Venezuela',
      ro: 'Venezuela',
      bg: 'Венецуела',
      de: 'Venezuela',
      fr: 'Venezuela',
      nl: 'Venezuela',
      it: 'Venezuela',
      cn: '委内瑞拉',
      ee: 'Venezuela',
      ja: 'ベネズエラ',
      da: 'Venezuela',
      fa: 'ونزوئلا',
    },
    dial_code: '+58',
    code: 'VE',
    flag: '🇻🇪',
  },
  {
    name: {
      en: 'Vietnam',
      hu: 'Vietnám',
      id: 'Vietnam',
      ru: 'Вьетнам',
      pl: 'Wietnam',
      uk: "В'єтнам",
      cz: 'Vietnam',
      by: "В'етнам",
      pt: 'Vietname',
      es: 'Vietnam',
      ro: 'Vietnam',
      bg: 'Виетнам',
      de: 'Vietnam',
      fr: 'Viet Nam',
      nl: 'Vietnam',
      it: 'Vietnam',
      cn: '越南',
      ee: 'Vietnam',
      ja: 'ベトナム',
      da: 'Vietnam',
      fa: 'ویتنام',
    },
    dial_code: '+84',
    code: 'VN',
    flag: '🇻🇳',
  },
  {
    name: {
      en: 'Virgin Islands, British',
      hu: 'Brit Virgin-szigetek',
      id: 'Kepulauan Virgin Inggris',
      ru: 'Виргинские острова, Британские',
      pl: 'Wyspy Dziewicze, Brytyjskie',
      uk: 'Віргінські острови, Британські',
      cz: 'Panenské ostrovy, Britové',
      by: 'Віргінскія астравы, Брытанія',
      pt: 'Ilhas Virgens Britânicas',
      es: 'Islas Vírgenes Británicas',
      ro: 'Insulele Virgine, britanice',
      bg: 'Вирджински острови, британски',
      de: 'Virgin Inseln, Britisch',
      fr: 'Îles Vierges britanniques',
      nl: 'Britse Maagdeneilanden',
      it: 'Isole Vergini britanniche',
      cn: '英属维尔京群岛',
      ee: 'Neitsisaared, Suurbritannia',
      ja: '英領バージン諸島',
      da: 'Britiske Jomfruøer',
      fa: 'جزایر ویرجین بریتانیا',
    },
    dial_code: '+1284',
    code: 'VG',
    flag: '🇻🇬',
  },
  {
    name: {
      en: 'Virgin Islands, U.S.',
      hu: 'Amerikai Virgin-szigetek',
      id: 'Kepulauan Virgin Amerika Serikat',
      ru: 'Американские Виргинские острова',
      pl: 'Wyspy Dziewicze Stanów Zjednoczonych',
      uk: 'Віргінські острови, США',
      cz: 'Panenské ostrovy, USA',
      by: 'Віргінскія астравы, ЗША',
      pt: 'Ilhas Virgens Americanas',
      es: 'Islas Vírgenes, EE.UU.',
      ro: 'Insulele Virgine, S.U.A.',
      bg: 'Вирджински острови, САЩ',
      de: 'Jungferninseln, USA',
      fr: 'Îles Vierges américaines, États-Unis',
      nl: 'Maagdeneilanden, U.s.',
      it: 'Isole Vergini, Stati Uniti',
      cn: '美国维尔京群岛',
      ee: 'Neitsisaared, USA',
      ja: 'アメリカ領バージン諸島',
      da: 'Amerikanske Jomfruøer',
      fa: 'جزایر ویرجین آمریکا',
    },
    dial_code: '+1340',
    code: 'VI',
    flag: '🇻🇮',
  },
  {
    name: {
      en: 'Wallis and Futuna',
      hu: 'Wallis és Futuna',
      id: 'Wallis dan Futuna',
      ru: 'Уоллис и Футуна',
      pl: 'Wallis i Futuna',
      uk: 'Уолліс і Футуна',
      cz: 'Wallis a Futuna',
      by: 'Уоліс і Футуна',
      pt: 'Wallis e Futuna',
      es: 'Wallis y Futuna',
      ro: 'Wallis și Futuna',
      bg: 'Уолис и Футуна',
      de: 'Wallis und Futuna',
      fr: 'Wallis et Futuna',
      nl: 'Wallis en Futuna',
      it: 'Wallis e Futuna',
      cn: '瓦利斯和富图纳群岛',
      ee: 'Wallis ja Futuna',
      ja: 'ウォリス・フツナ',
      da: 'Wallis og Futuna',
      fa: 'والیس و فوتونا',
    },
    dial_code: '+681',
    code: 'WF',
    flag: '🇼🇫',
  },
  {
    name: {
      en: 'Yemen',
      hu: 'Jemen',
      id: 'Yaman',
      ru: 'Йемен',
      pl: 'Jemen',
      uk: 'Ємен',
      cz: 'Jemen',
      by: 'Емен',
      pt: 'Iémen',
      es: 'Yemen',
      ro: 'Yemen',
      bg: 'Йемен',
      de: 'Jemen',
      fr: 'Yémen',
      nl: 'Jemen',
      it: 'Yemen',
      cn: '也门',
      ee: 'Jeemen',
      ja: 'イエメン',
      da: 'Yemen',
      fa: 'یمن',
    },
    dial_code: '+967',
    code: 'YE',
    flag: '🇾🇪',
  },
  {
    name: {
      en: 'Zambia',
      hu: 'Zambia',
      id: 'Zambia',
      ru: 'Замбия',
      pl: 'Zambia',
      uk: 'Замбія',
      cz: 'Zambie',
      by: 'Замбія',
      pt: 'Zâmbia',
      es: 'Zambia',
      ro: 'Zambia',
      bg: 'Замбия',
      de: 'Sambia',
      fr: 'Zambie',
      nl: 'Zambia',
      it: 'Zambia',
      cn: '赞比亚',
      ee: 'Sambia',
      ja: 'ザンビア',
      da: 'Zambia',
      fa: 'زامبیا',
    },
    dial_code: '+260',
    code: 'ZM',
    flag: '🇿🇲',
  },
  {
    name: {
      en: 'Zimbabwe',
      hu: 'Zimbabwe',
      id: 'Zimbabwe',
      ru: 'Зимбабве',
      pl: 'Zimbabwe',
      uk: 'Зімбабве',
      cz: 'Zimbabwe',
      by: 'Зімбабвэ',
      pt: 'Zimbabué',
      es: 'Zimbabue',
      ro: 'Zimbabwe',
      bg: 'Зимбабве',
      de: 'Zimbabwe',
      fr: 'Zimbabwe',
      nl: 'Zimbabwe',
      it: 'Zimbabwe',
      cn: '津巴布韦',
      ee: 'Zimbabwe',
      ja: 'ジンバブエ',
      da: 'Zimbabwe',
      fa: 'زیمبابوه',
    },
    dial_code: '+263',
    code: 'ZW',
    flag: '🇿🇼',
  },
  {
    name: {
      en: 'Åland Islands',
      hu: 'Åland-szigetek',
      id: 'Kepulauan Aland',
      ru: 'Аландские острова',
      pl: 'Wyspy Alandzkie',
      uk: 'Аландські острови',
      cz: 'Alandy',
      by: 'Аландскія астравы',
      pt: 'Ilhas Aland',
      es: 'Islas Åland',
      ro: 'Insulele Aland',
      bg: 'Аландски острови',
      de: 'Aland Islands',
      fr: 'Iles Aland',
      nl: 'Aland-eilanden',
      it: 'Isole Aland',
      cn: '奥兰群岛',
      ee: 'Alandi saared',
      ja: 'オーランド諸島',
      da: 'Åland',
      fa: 'جزایر آلند',
    },
    dial_code: '+358',
    code: 'AX',
    flag: '🇦🇽',
  },
]
