import { FormDetails, FormFieldDetails } from '../components/common/form/form'
import { WHITE } from '../constants/ui-constants'

const fields: { [key: string]: FormFieldDetails } = {
  images: {
    label: 'dreamscan.fieldTitle',
    description: 'dreamscan.fieldDescription',
    name: 'images',
    type: 'multi-image-picker-with-camera',
    imagePickerProps: {
      mediaType: 'photo',
      includeBase64: false,
      compressImageMaxWidth: 1500,
      compressImageMaxHeight: 1500,
      compressImageQuality: 0.6,
      cropping: false,
      multiple: true,
      cropperChooseColor: WHITE,
      cropperCancelColor: WHITE,
      type: 'dream-scan',
      alt: 'Scanned dream',
      sources: ['local', 'url'],
    },
  },
}

export const dreamscanForm: FormDetails = {
  pages: [
    {
      fields: [fields.images],
    },
  ],
}
