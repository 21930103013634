import React from 'react'
import { InputField } from './input-field'
import { ButtonIcon } from '../buttons/button-icon'
import { InterfaceInputProps } from 'native-base/lib/typescript/components/primitives/Input/types'
import { Row } from '../row/row'

type InputAddTextProps = InterfaceInputProps & {
  placeholder: string
  onSubmit: (text: string) => void
  loading?: boolean
  enterKeyHint?: 'search' | 'done' | 'enter' | 'next' | 'send'
}

export const InputAddText = ({
  placeholder,
  onSubmit,
  mb,
  loading,
  enterKeyHint,
  ...rest
}: InputAddTextProps) => {
  const [text, setText] = React.useState('')

  return (
    <Row width={'100%'} mb={mb}>
      <InputField
        placeholder={placeholder}
        value={text}
        onChangeText={setText}
        onSubmitEditing={() => {
          onSubmit(text)
          setText('')
        }}
        enterKeyHint={enterKeyHint}
        mr={4}
        pl={3}
        {...rest}
      />
      <ButtonIcon
        iconKey={'plus'}
        variant={'outlined'}
        onPress={() => {
          onSubmit(text)
          setText('')
        }}
        size={'sm'}
        loading={loading}
      />
    </Row>
  )
}
