import React from 'react'
import { useTranslation } from 'react-i18next'
import { SelectWithActionSheet } from '../../common/inputs/select/select-with-action-sheet'
import { DATE_RANGES_TO_SHOW } from '../../../config/frontend-config'
import { DatePicker } from '../../common/inputs/date-picker/date-picker'
import { useColorModeValue } from 'native-base'
import { dateRangeFromKey } from '../../../screens/Charts/chart-helpers'
import { dateFromString } from '../../../modules/strings/string-helpers'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { useSelector } from '../../../ducks/root-reducer'
import { selectEarliestDreamDate } from '../../../ducks/dream-tag/dream-tag'
import { ChartsNavigationProp } from '../../../screens/Charts'
import { DataExportNavigationProp } from '../../../screens/Settings/DataExport'
import { TagViewNavigationProp } from '../../../screens/TagView'
import { FormFieldNavigationProp } from '../../common/form/form-field'
import { Row } from '../../common/row/row'
import { Center } from '../../common/center/center'
import { Column } from '../../common/column/column'
import { SansText } from '../../common/copy/text-sans'
import {
  DateRangeKey,
  DateRangeWithKey,
} from '../../../../../api/frontend-types'

export type DateRangeSelectNavigationProp =
  | ChartsNavigationProp
  | TagViewNavigationProp
  | FormFieldNavigationProp
  | DataExportNavigationProp

type DateRangeSelectorProps = {
  onChange: (value: DateRangeWithKey) => void
  value: DateRangeWithKey
  dateRangeOptions?: DateRangeKey[]
  includeCustom?: boolean
}

export const DateRangeSelect = ({
  onChange,
  value,
  dateRangeOptions = DATE_RANGES_TO_SHOW,
  includeCustom = false,
}: DateRangeSelectorProps) => {
  // HOOKS
  const { t } = useTranslation()

  const DateRangeOptions: {
    order?: number
    label: string
    value: DateRangeKey
  }[] = [
    {
      order: 1,
      label: t('chartsPage.options.pastTwoWeeks'),
      value: 'LAST_TWO_WEEKS',
    },
    {
      order: 2,
      label: t('chartsPage.options.pastThreeMonths'),
      value: 'LAST_THREE_MONTHS',
    },
    { order: 3, label: t('chartsPage.options.pastYear'), value: 'LAST_YEAR' },
    { order: 4, label: t('chartsPage.options.allTime'), value: 'ALL_TIME' },
  ]

  const filteredDateRangeOptions = React.useMemo(() => {
    return DateRangeOptions.filter((option) =>
      dateRangeOptions.includes(option.value),
    )
      .sort((a, b) => {
        const limit = DateRangeOptions.length
        return (a.order || limit) - (b.order || limit)
      })
      .concat(
        ...(includeCustom
          ? [
              {
                label: t('chartsPage.options.custom'),
                value: 'CUSTOM' as DateRangeKey,
              },
            ]
          : []),
      )
  }, [dateRangeOptions, includeCustom, t])

  const color = useColorModeValue(BLACK, WHITE)
  const firstDate = useSelector(selectEarliestDreamDate)

  return (
    <>
      <SelectWithActionSheet
        options={filteredDateRangeOptions}
        maintainSortOrder={true}
        onChange={(option: any) => {
          onChange({
            range: dateRangeFromKey(option.value as DateRangeKey),
            key: option.value as DateRangeKey,
          })
        }}
        value={value.key}
        searchable={false}
      />
      {includeCustom ? (
        <Center w="100%" mt={4}>
          <Row w="100%">
            <Column w="40%">
              <DatePicker
                isDisabled={value.key !== 'CUSTOM'}
                date={
                  value.range.start?.toISOString() || firstDate?.toISOString()
                }
                onChangeDate={(date: string) => {
                  onChange({
                    range: {
                      start: dateFromString(date) || firstDate || null,
                      end: value.range.end,
                    },
                    key: value.key,
                  })
                }}
                minDate={firstDate}
                maxDate={new Date()}
              />
            </Column>
            <Column w="20%" alignItems={'center'} justifyContent="center">
              <SansText color={value.key !== 'CUSTOM' ? 'gray.400' : color}>
                {' '}
                -{' '}
              </SansText>
            </Column>
            <Column w="40%">
              <DatePicker
                isDisabled={value.key !== 'CUSTOM'}
                date={value.range.end?.toISOString()}
                onChangeDate={(date: string) => {
                  onChange({
                    range: {
                      start: value.range.start,
                      end: dateFromString(date) || new Date(),
                    },
                    key: value.key,
                  })
                }}
                minDate={firstDate}
                maxDate={new Date()}
              />
            </Column>
          </Row>
        </Center>
      ) : null}
    </>
  )
}
