import React from 'react'
import { ButtonPill } from '../../common/buttons/button-pill'
import { useTranslation } from 'react-i18next'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { useColorModeValue } from 'native-base'
import { LINE_WIDTH } from '../../../constants/constants'
import { useNavigation } from '@react-navigation/core'
import { RootStackParamList } from '../../../navigation/types'
import { ButtonIcon } from '../../common/buttons/button-icon'
import { Row } from '../../common/row/row'
import { Column } from '../../common/column/column'

type DreamCommentReactionRowProps = {
  inGroup: boolean
  reactionCount?: number
  userReactionCount?: number
  commentCount?: number
  showCommentCount?: boolean
  showReactionCount?: boolean
  onReactionPress?: () => void
  linkObject?: {
    screen: keyof RootStackParamList
    params: {
      username: string
    }
  }
}

export function ReactionRow({
  inGroup,
  reactionCount,
  commentCount,
  userReactionCount,
  showCommentCount = true,
  showReactionCount = true,
  onReactionPress,
  linkObject,
}: DreamCommentReactionRowProps) {
  // HOOKS
  const { t } = useTranslation()
  const navigation = useNavigation<any>()
  const color = useColorModeValue(BLACK, WHITE)

  return (
    <Row justifyContent={'flex-end'} mb={4} w="100%" space={2}>
      {showReactionCount && (
        <Column>
          {inGroup ? (
            <ButtonPill
              buttonSize={'xs'}
              onPress={onReactionPress}
              variant={userReactionCount === 1 ? 'solid' : 'outline'}
              borderWidth={LINE_WIDTH}
              borderColor={color}
              mx={1}
            >
              <>{reactionCount} ♥</>
            </ButtonPill>
          ) : null}
          {inGroup ? null : (
            <ButtonIcon
              iconKey={'theme'}
              size="xs"
              variant={userReactionCount === 1 ? 'filled' : 'outlined'}
              outlineWidthOverride={LINE_WIDTH}
              onPress={onReactionPress}
            />
          )}
        </Column>
      )}
      {showCommentCount && linkObject && (
        <Column>
          <ButtonPill
            buttonSize={'xs'}
            variant={'outline'}
            textTransform={'capitalize'}
            mx={1}
            onPress={() => {
              navigation.navigate(linkObject.screen, linkObject.params)
            }}
          >
            {t('common.countedComment', { count: commentCount || 0 })}
          </ButtonPill>
        </Column>
      )}
    </Row>
  )
}
