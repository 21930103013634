import React, { useState } from 'react'
import { G, Rect, Svg, Text, Circle } from 'react-native-svg'
import { scaleLinear } from 'd3-scale'
import {
  LINE_WIDTH,
  MEDIUM_FONT_SIZE,
  SANS_LIGHT_FONT_WEIGHT,
  SMALL_FONT_SIZE,
  WEB_MAX_WIDTH,
} from '../../../constants/constants'
import { Platform } from 'react-native'
import { useColorModeValue } from 'native-base'
import {
  BLACK,
  CREAM,
  WHITE,
  LIGHT_BLACK,
} from '../../../constants/ui-constants'
import { PressableFixed } from '../../../utilities/PressableFixed'
import i18n from '../../../i18n/i18nnext'
import { FastTag } from '../../../../../api/_openapi'
import {
  getLanguageForTag,
  getSansFont,
} from '../../../modules/language-helpers/language-helpers'

// SVG-based horizontal bar chart
// DATA FORMAT
//
// This is similar to the format that VictoryCharts expects
// So if we want to use VictoryCharts again, it's easy
//
// const data = [
//   { x: 'John', y: 2 },
//   { x: 'Mary', y: 3 },
//   { x: 'Sheldon Juncker', y: 5 },
//   { x: 'Gez', y: 4 },
//   { x: 'Donald Trump', y: 7 },
// ]

export type HorizontalBarChartDatum = {
  x: string
  y: number | undefined
  tag: FastTag
}

type HorizontalBarChartProps = {
  data: HorizontalBarChartDatum[]
  width?: number
  maxWidth?: number
  barHeight?: number // The height of the chart if all bars have data
}

export const HorizontalBarChart = ({
  data,
  width = 300,
  barHeight = 10,
  maxWidth = WEB_MAX_WIDTH,
}: HorizontalBarChartProps) => {
  // STATE
  const [hoveredBar, setHoveredBar] = useState<number | null>(null)

  // VARS
  const TOOLTIP_BAR_GAP = 4 // Gap between tooltip and bar
  const EDGE_OFFSET = 2
  const chartFontSize = MEDIUM_FONT_SIZE
  const tooltipFontSize = SMALL_FONT_SIZE
  const chartFontWeight = SANS_LIGHT_FONT_WEIGHT
  const tooltipDiameter = barHeight * 2

  const chartWidth = Math.min(width, maxWidth - tooltipDiameter)
  const numBars = data.length
  const effectiveWidth = chartWidth - EDGE_OFFSET * 2
  const maxValue = Math.max(...data.map((bar) => bar.y || 0))

  // I18N
  const userLanguage = i18n.resolvedLanguage || 'en'
  const dir = i18n.dir(userLanguage)

  // The label is on top of each bar
  // Labels need a little more space than bars
  const labelHeight = Math.floor(barHeight * 2)
  const labelBarHeight = labelHeight + barHeight
  const chartHeight =
    labelBarHeight * numBars + // Bars
    EDGE_OFFSET * 2 + // Top and bottom edge offset
    barHeight // For tooltip

  // SCALES
  const barScale = scaleLinear<number>()
    .domain([0, maxValue])
    .range([
      0,
      effectiveWidth - tooltipDiameter - TOOLTIP_BAR_GAP - EDGE_OFFSET,
    ])

  // HANDLERS
  const onMouseOver = (x: number) => {
    setHoveredBar(x)
  }

  const onMouseOut = () => {
    setHoveredBar(null)
  }

  const color = useColorModeValue(BLACK, WHITE)
  const fill = useColorModeValue(CREAM, LIGHT_BLACK)

  return (
    <PressableFixed onPress={onMouseOut}>
      <Svg width={chartWidth} height={chartHeight}>
        <G>
          {data.map((bar, index) => {
            const barW = barScale(bar.y || 0)
            const barNumber = index + 1

            const labelY =
              barNumber * labelBarHeight - labelHeight + chartFontSize / 2
            const barY = barNumber * labelBarHeight - barHeight

            // I18N
            // For RTL, the bar should be at the right edge
            const xOffset = 2 // For stroke width
            const barX = dir === 'rtl' ? chartWidth - barW - xOffset : xOffset
            const textX = dir === 'rtl' ? chartWidth - xOffset : xOffset
            const barEnd = dir === 'rtl' ? chartWidth - barW : barW
            const barTipGap = dir === 'rtl' ? -TOOLTIP_BAR_GAP : TOOLTIP_BAR_GAP
            const toolTipWOffset =
              dir === 'rtl' ? -(tooltipDiameter / 2) : tooltipDiameter / 2
            const edgeOffset = dir === 'rtl' ? -EDGE_OFFSET : EDGE_OFFSET
            const circleX = barEnd + barTipGap + toolTipWOffset + edgeOffset
            const textAnchor = dir === 'rtl' ? 'end' : 'start'
            const tagLanguage = getLanguageForTag(bar.tag, userLanguage)
            const barFont = getSansFont(tagLanguage)

            return (
              <G
                key={`${bar.x}${index}`}
                onMouseOver={() => onMouseOver(index)}
                onMouseOut={onMouseOut}
              >
                <Rect
                  x={barX}
                  y={barY}
                  width={barW}
                  height={barHeight}
                  fill={fill}
                  stroke={color}
                  strokeWidth={LINE_WIDTH}
                  {...Platform.select({
                    native: {
                      onPress: () => onMouseOver(index),
                    },
                  })}
                />
                <Rect
                  fill="transparent"
                  width={barW}
                  height={chartFontSize + 4}
                  x={0}
                  y={labelY - chartFontSize}
                  {...Platform.select({
                    native: {
                      onPress: () => onMouseOver(index),
                    },
                  })}
                />
                <Text
                  x={textX}
                  y={labelY}
                  fontSize={chartFontSize}
                  fontWeight={chartFontWeight}
                  fill={color}
                  fontFamily={barFont}
                  textAnchor={textAnchor}
                >
                  {bar.x}
                </Text>
                {/* On mouseover, show a circular tooltip with the y value at the end of the bar */}
                {hoveredBar === index && (
                  <G>
                    <Circle
                      cx={circleX}
                      cy={barY + barHeight / 2}
                      r={tooltipDiameter / 2}
                      fill={fill}
                      stroke={color}
                      strokeWidth={LINE_WIDTH}
                    />
                    {/* Text should be centered in the circle, horizontally and vertically */}
                    <Text
                      x={circleX}
                      y={barY + barHeight / 2 + tooltipFontSize / 3}
                      textAnchor="middle"
                      fontSize={chartFontSize}
                      fontWeight={chartFontWeight}
                      fill={color}
                      fontFamily={'ABCFavorit-Book'}
                    >
                      {bar.y}
                    </Text>
                  </G>
                )}
              </G>
            )
          })}
        </G>
      </Svg>
    </PressableFixed>
  )
}
