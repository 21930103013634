import * as React from 'react'
import { Size } from '../../../core/theme'
import { InterfaceIconProps } from 'native-base/lib/typescript/components/primitives/Icon/types'
import { ResponsiveValue } from 'native-base/lib/typescript/components/types'
import { IColors } from 'native-base/lib/typescript/theme/base/colors'
import { ColorValue } from 'react-native'
import {
  IconAction,
  IconActionOutlined,
  IconAnimal,
  IconAnimalOutlined,
  IconCharacter,
  IconCharacterOutlined,
  IconEmotion,
  IconEmotionOutlined,
  IconOther,
  IconOtherOutlined,
  IconSetting,
  IconSettingOutlined,
  IconTheme,
  IconThemeOutlined,
  IconDreamType,
  IconDreamTypeOutlined,
  IconColor,
  IconColorOutlined,
} from '../../../assets/react-native-svg/icons/icons-tag'
import { TagType } from '../../../../../api/frontend-types'
import { Friend } from '../../../../../api/_openapi'
import { Img } from '../../common/image/image'
import { AvatarCircle } from '../../common/avatar/avatar-circle'

type TagIconMap = {
  [key in TagType]: (props: InterfaceIconProps) => JSX.Element
}

// Non-outlined icons (non-editable state)
const iconMap: TagIconMap = {
  CHARACTER: IconCharacter,
  SETTING: IconSetting,
  EMOTION: IconEmotion,
  ACTION: IconAction,
  ANIMAL: IconAnimal,
  OTHER: IconOther,
  // TODO Lili vvv
  THEME: IconTheme,
  DREAM_TYPE: IconDreamType,
  COLOR: IconColor,
  // TODO, won't be used?
  SYMBOL: IconOther,
  // TODO, won't be used unless offline
  NONE: IconOther,
}

// Outlined icons (editable state)
const outlinedIconMap: TagIconMap = {
  CHARACTER: IconCharacterOutlined,
  SETTING: IconSettingOutlined,
  EMOTION: IconEmotionOutlined,
  ACTION: IconActionOutlined,
  ANIMAL: IconAnimalOutlined,
  OTHER: IconOtherOutlined,
  // TODO Lili vvv
  THEME: IconThemeOutlined,
  DREAM_TYPE: IconDreamTypeOutlined,
  COLOR: IconColorOutlined,
  // TODO, won't be used?
  SYMBOL: IconOtherOutlined,
  // TODO, won't be used unless offline
  NONE: IconOtherOutlined,
}

export type TagIconProps = InterfaceIconProps & {
  iconKey: TagType
  friend: Friend | undefined
  size?: Size
  iconFill?: ColorValue
  iconStroke?: ResponsiveValue<IColors | (string & {})>
  outlined?: boolean
}

export const TagIcon = ({
  iconKey,
  friend,
  outlined,
  iconFill,
  iconStroke,
  size,
  ...rest
}: TagIconProps) => {
  // Get the correct icon
  // And if it's outlined, get the outlined version
  const IconForTag = outlined ? outlinedIconMap[iconKey] : iconMap[iconKey]

  const iconSize: Size = size || 'md'

  if (friend) {
    return (
      <AvatarCircle
        size={'2xs'}
        source={{ uri: friend.imageUrl || undefined }}
        name={'whatever'}
      />
    )
  }

  // Fallback, in case we add a new tag type on the backend
  // And can't get everyone to update their app
  if (!IconForTag) {
    return <IconOther size={iconSize} {...rest} />
  }

  return (
    <IconForTag color={iconStroke} fill={iconFill} size={iconSize} {...rest} />
  )
}
