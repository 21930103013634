import React, { useState } from 'react'
import i18n from 'i18next'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSelector } from '../../../ducks/root-reducer'
import { selectCurrentUserProfile, selectUser } from '../../../ducks/user/user'
import { undecorateId } from '../../../../../api/decorate-ids'
import {
  updateUser,
  upsertDreamerProfile,
} from '../../../ducks/user/thunks/user-thunks'
import {
  selectAllInterpretationStyleOptionsForForm,
  selectAllPossibleArtStyleOptions,
  selectInterpretationOptions,
  selectInterpretationStyles,
} from '../../../ducks/ui/ui'
import { DreamerProfileEditType } from '../../../../../api/frontend-types'
import {
  Form,
  FormDetails,
  FormFieldDetails,
} from '../../../components/common/form/form'
import { RootStackScreenProps } from '../../../navigation/types'

export default function DreamSettingsEdit({}: RootStackScreenProps<'PrivateDreamSettingsEdit'>) {
  const [loading, setLoading] = useState(false)

  // SELECTORS
  const user = useSelector(selectUser)
  const currentUserProfile = useSelector(selectCurrentUserProfile)
  const interpretationOptions = useSelector(selectInterpretationOptions)
  const interpretationStyles = useSelector(selectInterpretationStyles)
  const [selectedInterpretationStyle, setSelectedInterpretationStyle] =
    useState<string | null>(undecorateId(user?.interpretationStyleId || ''))
  const userInterpretationOptions: { [key: string]: any } =
    user?.interpretationOptions || {}

  // HOOKS
  const dispatch = useDispatch<any>()
  const { t } = useTranslation()

  // VARS
  const { languageCode, artStyleId, interpretationStyleId } = user || {}
  const { dreamDateOffset } = currentUserProfile || {}

  // Change language locally in AsyncStorage before updating server
  const handleChangeLanguage = async (lang: string) => {
    i18n.changeLanguage(lang)
  }

  const onSubmitForm = async (data: any) => {
    setLoading(true)

    // If the user changed their language, update it
    if (data.language !== languageCode) {
      await handleChangeLanguage(data.language)
    }

    const userPatch = {
      artStyleId: data.artStyle,
      interpretationOptions: data.interpretationOptions,
      interpretationStyleId: data.interpretationStyle,
    }
    const dreamerProfilePatch = {
      dreamDateOffset: Number(data.dreamDateOffset || 0),
    } as DreamerProfileEditType

    const performUpdate = async () => {
      await dispatch(
        upsertDreamerProfile(
          currentUserProfile?.id || '',
          dreamerProfilePatch,
          user?.id || '',
          false,
        ),
      )
      await dispatch(updateUser(user?.id || '', userPatch))
    }

    performUpdate().finally(() => setLoading(false))
  }

  const fields: FormFieldDetails[] = [
    {
      label: 'userForm.defaultArtStyle',
      name: 'artStyle',
      type: 'select',
      optionsSelector: selectAllPossibleArtStyleOptions(),
      maintainSortOrder: true,
    },
    {
      label: 'userForm.defaultInterpretationStyle',
      description: 'userForm.defaultInterpretationStyle.description',
      name: 'interpretationStyle',
      type: 'select',
      optionsSelector: selectAllInterpretationStyleOptionsForForm,
      onChange: (value: any) => {
        setSelectedInterpretationStyle(value)
      },
      maintainSortOrder: true,
    },
  ]

  const dreamDateField: FormFieldDetails = {
    label: 'userForm.defaultDreamDate',
    description: 'userForm.defaultDreamDate.description',
    name: 'dreamDateOffset',
    type: 'select',
    options: [
      { value: '0', label: t('common.today') },
      { value: '-1', label: t('common.yesterday') },
    ],
  }

  const optionDefaults: { [key: string]: string } = {}

  //add interpretation options
  interpretationOptions.forEach((option) => {
    const styleKey = option.style || ''
    const styleId = interpretationStyles.find(
      (style) => style.key === styleKey,
    )?.id
    const options: any = option.options || []
    const optionNames = Object.keys(options)
    optionNames.forEach((optionName) => {
      fields.push({
        label: 'userForm.interpretationOptions.' + styleKey + '.' + optionName,
        name: 'interpretationOptions.' + styleKey + '.' + optionName,
        type: 'select',
        hidden: styleId !== selectedInterpretationStyle,
        options: options[optionName].options.map((option: any) => {
          return {
            value: option,
            label: t(option),
          }
        }),
        maintainSortOrder: true,
      })

      if (
        userInterpretationOptions[styleKey] &&
        userInterpretationOptions[styleKey][optionName]
      ) {
        optionDefaults['interpretationOptions.' + styleKey + '.' + optionName] =
          userInterpretationOptions[styleKey][optionName]
      } else {
        optionDefaults['interpretationOptions.' + styleKey + '.' + optionName] =
          options[optionName].default
      }
    })
  })

  //add all option fields, then add the dream date field
  fields.push(dreamDateField)

  const userSettingsForm: FormDetails = {
    pages: [
      {
        fields: fields,
      },
    ],
  }

  return (
    <Form
      name={'dreamSettingsForm'}
      form={userSettingsForm}
      onSubmit={onSubmitForm}
      loading={loading}
      prepopulateValues={{
        artStyle: undecorateId(artStyleId),
        interpretationStyle: undecorateId(interpretationStyleId),
        dreamDateOffset: String(dreamDateOffset),
        ...optionDefaults,
      }}
      headerTitle={t('settings.dreamSettings')}
    />
  )
}
