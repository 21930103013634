import React, { useState, useRef } from 'react'
import { TextArea } from '../../components/common/inputs/input-textarea'
import { Pressable } from 'react-native'
import { ButtonPill } from '../../components/common/buttons/button-pill'
import { useSelector } from '../../ducks/root-reducer'
import { selectUser } from '../../ducks/user/user'
import { useColorModeValue } from 'native-base'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { BodyText } from '../../components/common/copy/text-body'
import { IconEdit } from '../../assets/react-native-svg/icons/icons-ui'
import { BLACK, WHITE } from '../../constants/ui-constants'
import { useTranslation } from 'react-i18next'
import { Loading } from '../../components/layout/loading'
import { HeadingSmall } from '../../components/common/copy/heading-small'
import { ContentDivider } from '../../components/layout/content-divider'
import { TagPageHeaderNavigationProp } from './header'
import { FastTagDescription } from '../../../../api/_openapi'
import { Row } from '../../components/common/row/row'
import { Column } from '../../components/common/column/column'
import {
  fetchUserTagAssociation,
  saveUserTagAssociation,
  updateUserTagAssociation,
} from '../../ducks/dream-tag/functions/tag-functions'
import {
  INSIGHT_TYPES,
  LARGE_FONT_SIZE,
  LG_FONTSIZE_PIXELS,
} from '../../constants/constants'
import { View } from '../../components/common/view/view'

export type UserTagDescriptionNavigationProp = TagPageHeaderNavigationProp

export const UserTagDescription = ({ tag, tagName }: any) => {
  // STATE
  const [editorOpen, setEditorOpen] = React.useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [description, setDescription] = React.useState<string>('')
  const [tagDescription, setTagDescription] =
    useState<FastTagDescription | null>(null)

  // SELECTORS
  const user = useSelector(selectUser)

  // HOOKS
  const { t } = useTranslation()

  // REFS
  const initialValue = useRef('')

  // EFFECTS
  React.useEffect(() => {
    if (tag.id && user?.id) {
      fetchUserTagAssociation(tag.id, user.id)
        .then((association) => {
          setTagDescription(association)
          initialValue.current = association?.description as string
          setDescription(association?.description as string)
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [tag.id])

  const onSubmit = async () => {
    if (!user?.id) return

    setSaving(true)
    const UserTagDescription = {
      tagId: tag.id,
      type: INSIGHT_TYPES.USER_TAG_ASSOCIATION,
      description,
    }
    if (!tagDescription) {
      await saveUserTagAssociation(user.id, UserTagDescription)
        .then((response) => {
          if (response) {
            setTagDescription(response)
            initialValue.current = response?.description as string
            setEditorOpen(false)
          }
        })
        .catch((err) => {
          console.error(err)
        })
      setSaving(false)
    } else {
      await updateUserTagAssociation(user.id, tagDescription.id, {
        description,
      })
        .then((response) => {
          if (response) {
            setTagDescription(response)
          }
          initialValue.current = response?.description as string
          setEditorOpen(false)
        })
        .catch((err) => {
          console.error(err)
        })
      setSaving(false)
    }
  }

  const color = useColorModeValue(BLACK, WHITE)

  return (
    <PaddedContentArea>
      <ContentDivider mt={0} />
      {loading ? (
        <PaddedContentArea>
          <Loading />
        </PaddedContentArea>
      ) : (
        <>
          <HeadingSmall
            style={{
              textTransform: 'uppercase',
              textAlign: 'center',
            }}
            mt={6}
            mb={0}
          >
            {t('tagPage.wakingLifeAssociations')}
          </HeadingSmall>
          <View my={4} mb={10}>
            {!editorOpen ? (
              <Pressable
                style={{ width: '100%' }}
                onPress={() => setEditorOpen(true)}
              >
                <BodyText
                  type={!tagDescription?.description ? 'gray' : 'primary'}
                  fontSize={LARGE_FONT_SIZE}
                >
                  {!tagDescription?.description
                    ? t('tagPage.wakingLifeAssociations.placeholder', {
                        tagName,
                      })
                    : tagDescription?.description}
                  <IconEdit
                    size={'16px'}
                    color={!tagDescription?.description ? 'gray.400' : color}
                    style={{
                      transform: [
                        {
                          translateX: 6,
                        },
                      ],
                    }}
                  />
                </BodyText>
              </Pressable>
            ) : (
              <>
                <TextArea
                  fieldName={'user-tag-description'}
                  autoFocus={true}
                  id={tag.id}
                  placeholder={''}
                  value={description}
                  onChangeText={setDescription}
                  minRows={2}
                  minHeight={LG_FONTSIZE_PIXELS * 2}
                />
                <Row space={3} alignItems="center" flexGrow={1} mt={6}>
                  <Column flex={1}>
                    <ButtonPill
                      isDisabled={saving}
                      onPress={() => {
                        setDescription(initialValue.current)
                        setEditorOpen(false)
                      }}
                      buttonSize={'sm'}
                      variant="outline"
                      width={'100%'}
                    >
                      {t('common.cancel')}
                    </ButtonPill>
                  </Column>
                  <Column flex={1}>
                    <ButtonPill
                      isDisabled={!description && !tagDescription}
                      isLoading={saving}
                      buttonSize={'sm'}
                      onPress={() => {
                        onSubmit()
                      }}
                      width={'100%'}
                    >
                      {t('common.save')}
                    </ButtonPill>
                  </Column>
                </Row>
              </>
            )}
          </View>
        </>
      )}
    </PaddedContentArea>
  )
}
