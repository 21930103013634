import React from 'react'
import { useColorModeValue } from 'native-base'
import { AvatarCircle } from '../../../components/common/avatar/avatar-circle'
import { PaddedContentArea } from '../../../components/layout/content-area-padded'
import { ButtonPill } from '../../../components/common/buttons/button-pill'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useTranslation } from 'react-i18next'
import { Dimensions, ImageSourcePropType } from 'react-native'
import {
  PADDING_HORIZONTAL_PIXELS,
  WEB_MAX_WIDTH,
} from '../../../constants/constants'
import ConditionalLink from '../../../utilities/ConditionalLink'
import { FastDreamLocationService, UserRank } from '../../../../../api/_openapi'
import { RootStackParamList } from '../../../navigation/types'
import { GridDisplay } from '../../../components/layout/grid-display'
import { Factoid } from '../../../components/composite/stats/factoid'
import { CatIcon } from '../../../assets/react-native-svg/illustrations/Cat'
import { CloudIcon } from '../../../assets/react-native-svg/illustrations/Cloud'
import { MaskIcon } from '../../../assets/react-native-svg/illustrations/Mask'
import DreamMap from '../../Dreams/DreamMap/DreamMap'
import { DreamPoint } from '../../../../../api/frontend-types'
import { useSelector } from '../../../ducks/root-reducer'
import { selectIsElsewhereTeam, selectUser } from '../../../ducks/user/user'
import { PaddedContentAreaConditional } from '../../../components/layout/content-area-padded-conditional'
import { BodyText } from '../../../components/common/copy/text-body'
import { HeadingMain } from '../../../components/common/copy/heading-main'
import { DARK_MID_GRAY } from '../../../constants/ui-constants'
import { FEW } from '../../../i18n/config'
import { Box } from '../../../components/common/box/box'
import { Center } from '../../../components/common/center/center'

export const DreamerViewHeader = ({
  navigation,
  displayName = '',
  pronouns = '',
  isFamousUser,
  isLoggedInUser,
  isUsersPrivateGroup,
  bio,
  imageSrc,
  userRank,
}: {
  navigation: NativeStackNavigationProp<RootStackParamList>
  displayName: string | null | undefined
  pronouns: string
  isFamousUser: boolean
  isLoggedInUser: boolean
  isUsersPrivateGroup: boolean
  bio: string | null | undefined
  imageSrc: ImageSourcePropType
  userRank: UserRank | null
}) => {
  // HOOKS
  const { t } = useTranslation()
  const user = useSelector(selectUser)
  const isElsewhereTeam = useSelector(selectIsElsewhereTeam)
  const pronounsColor = useColorModeValue(DARK_MID_GRAY, DARK_MID_GRAY)

  // STATE
  const [dreamMapPoints, setDreamMapPoints] = React.useState<
    DreamPoint[] | null
  >(null)

  // VARS
  const nameStr = displayName || ''
  const bioStr = bio || ''
  let pageWidth = Dimensions.get('window').width
  pageWidth = Math.min(pageWidth, WEB_MAX_WIDTH)
  const nameText: string = nameStr
  const bioText: string = bioStr

  // EFFECTS
  React.useEffect(() => {
    if (isLoggedInUser && user) {
      FastDreamLocationService.userLocations(user.id)
        .then((dreamLocations) => {
          setDreamMapPoints(dreamLocations)
        })
        .catch((err) => {
          console.error('Error fetching dream locations', err)
        })
    }
  }, [user])

  return (
    <PaddedContentAreaConditional
      pointerEvents={isFamousUser ? 'none' : 'auto'}
      webPadding={0}
    >
      <Center mt={4}>
        <ConditionalLink
          to={{
            screen: 'PrivateDreamerEdit' as keyof RootStackParamList,
          }}
          condition={isLoggedInUser && isUsersPrivateGroup}
        >
          <AvatarCircle
            source={imageSrc}
            mb={2}
            size={'xl'}
            name={nameText}
            disablePress={!isLoggedInUser}
          />
        </ConditionalLink>
      </Center>
      {pronouns === '' && (
        <HeadingMain size={'2xl'} mb={4} mt={8} scriptType="latin">
          {nameText}
        </HeadingMain>
      )}
      {pronouns !== '' && (
        <Center>
          <HeadingMain size={'2xl'} mb={2} mt={8}>
            {nameText}
          </HeadingMain>
          <BodyText color={pronounsColor} mb={6}>
            {pronouns}
          </BodyText>
        </Center>
      )}
      {(bioText || !isUsersPrivateGroup) && (
        <PaddedContentAreaConditional>
          <BodyText textAlign={'center'}>{bioText}</BodyText>
        </PaddedContentAreaConditional>
      )}
      {!bio && isLoggedInUser && (
        <ButtonPill
          onPress={() => {
            navigation.navigate('PrivateDreamerEdit')
          }}
        >
          {t('userPage.addBioImageButton')}
        </ButtonPill>
      )}
      <Box>
        <PaddedContentArea alignItems={'center'}>
          {userRank && (
            <GridDisplay
              mt={4}
              mb={6}
              maxWidth={WEB_MAX_WIDTH}
              width={pageWidth - PADDING_HORIZONTAL_PIXELS * 2}
              items={[
                {
                  id: 'factoid-user-rank',
                  item: (
                    <Factoid
                      key={'factoid-user-rank'}
                      Icon={CatIcon}
                      factoidFact={userRank.rank?.toString() || 'N/A'}
                      textLineOne={t('dashboard.rank')}
                      textLineTwo={t('')}
                      layout="fact-text-text"
                    />
                  ),
                },
                {
                  id: 'factoid-num-dreams',
                  item: (
                    <Factoid
                      key={'factoid-num-dreams'}
                      Icon={CloudIcon}
                      factoidFact={userRank.dreamCount?.toString() || 'N/A'}
                      textLineOne={t('common.dream_plural', { count: FEW })}
                      textLineTwo={''}
                      layout="fact-text-text"
                    />
                  ),
                },
                {
                  id: 'factoid-num-tags',
                  item: (
                    <Factoid
                      key={'factoid-num-tags'}
                      Icon={MaskIcon}
                      factoidFact={userRank.tagCount?.toString() || 'N/A'}
                      textLineOne={t('common.tag_plural', { count: FEW })}
                      textLineTwo={''}
                      layout="fact-text-text"
                    />
                  ),
                },
              ]}
            />
          )}
        </PaddedContentArea>
      </Box>
      {user && isLoggedInUser && isUsersPrivateGroup && isElsewhereTeam && (
        <Box>
          <DreamMap
            dream={null}
            mode={'dream'}
            dreamPoints={dreamMapPoints || []}
            useCurrentLocation={true}
          />
        </Box>
      )}
    </PaddedContentAreaConditional>
  )
}
